import React from 'react';
import Product from './Product';
import {
  Box
} from '@mui/material';
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const ProductsIndex = (props) => {

  return (
    <>
    <Box className="">
        <ul className='breadcrumb'>
            <li>
                <span>A Message From Our Partner</span>
            </li>
            <li className='brdrBx_pernt'>
                <Box className="border_box" />
            </li>
        </ul>
    </Box>
    <div className="products">        
        <div className='grid-item p1'>
          <Product name="p1" />
        </div>
        <div className='grid-item p2'>
          <Product name="p2" />
        </div>
        <div className='grid-item p3'>
          <Product name="p3" />
        </div>
        <div className='grid-item p4'>
          <Product name="p4" />
        </div>
    </div>
    </>
  );
}


export default ProductsIndex;