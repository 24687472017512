import { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';

import store from './store';
import * as action from './store/actions';
import Routes from './routes';
import TagManager from 'react-gtm-module'

store.dispatch(action.authCheck());

const tagManagerArgs = {
    gtmId: 'G-HZKZVP0TVK'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="container">Loading...</div>}>
        <Router>  
          <Switch>
            <Routes />
          </Switch>
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
