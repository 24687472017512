import Skeleton from '@mui/material/Skeleton';
import { Box, Typography, Tab, Table, TableCell, TableRow, TableFooter, TableContainer, TableHead, TableBody, } from '@mui/material'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import React from 'react'
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
//import PieChartSkeleton from './PieChartSkeleton';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CompanyDetailsSkeleton(props) {


	const [value, setValue] = React.useState(props.type);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [expanded, setExpanded] = React.useState('panel1');
    const handleInChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

	return (	
	<Box className="dtilPagMain Skeleton">
		<Box className="whitBox">
			<Box className="whtBoxInnr">
				<Box>
					<Skeleton variant="rounded" width={90} height={90} />
				</Box>
				<Box className="tI_bxx">
					<Typography className='ubrTxt'>
						<Skeleton variant="text" sx={{ fontSize: '30px', width: '100%' }} />
					</Typography>
					<Box className="btnTImgFlx">
						<Box>
							<Skeleton variant="rounded" width={30} height={18} />
						</Box>
						<Box className="nAimg">
							<Skeleton variant="text" width={20} height={30} sx={{ marginRight: '10px' }} />
                        	<Skeleton variant="text" width={20} height={30} />
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className="newPrnt">
				<Box className="sosilIcon">
					<Box>
						<Skeleton variant="circular" width={27} height={27} sx={{ marginRight: '10px' }} />
					</Box>
					<Box>
						<Skeleton variant="circular" width={27} height={27} sx={{ marginRight: '10px' }} />
					</Box>
					<Box>
						<Skeleton variant="circular" width={27} height={27} sx={{ marginRight: '10px' }} />
					</Box>
					<Box>
						<Skeleton variant="circular" width={27} height={27} sx={{ marginRight: '10px' }} />
					</Box>
				</Box>
			</Box>
			<Box className="lasTxxt">
				<Typography>
					<Skeleton variant="rounded" width={200} height={80} />
				</Typography>
			</Box>
		</Box>
		<Box className="tabsContMain">
			<Box sx={{ width: '100%' }} className="CCCCCCC">
				<Box className="tebsHed" sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs className='tbHedInrr' value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Skeleton variant="text" width={120} height={50} sx={{ marginRight: '20px' }}/>
						<Skeleton variant="text" width={120} height={50} sx={{ marginRight: '20px' }}/>
						<Skeleton variant="text" width={120} height={50} />
					</Tabs>
				</Box>
				
				
				<TabPanel value={value} index={0} className="mainTabPnl whitBgWhite">
                    
                    <Box className="chart-top flex">
                        <Typography className="title">
                            <Skeleton variant="text" width={150} height={30} />
                        </Typography>
                        <Box className="Txt_imgBx">
                            <Skeleton variant="rounded" width={50} height={50} />
                        </Box>

                        <Box className="percentage" sx={{marginTop:"5px"}}>
                            <Typography className="last-price flxCntr">
                                <Skeleton variant="rounded" width={120} height={20} sx={{marginBottom:"10px"}}/>
                            </Typography>
                            <Typography className="float-right">
                                <Skeleton variant="rounded" width={120} height={20} />
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="graph">
                        <Skeleton variant="text" width="100%" height={500} />
                    </Box>

                    <Box className="moreCinfo">
                        <Box className="dateBox">
							<img src='/images/clarity_date_ic.svg' alt='' className='clarity_date_ic' />
							<Skeleton variant="text" width={80} height={25} sx={{background: '#e4e4e4 !important', borderRadius: '5px !important'}} />
						</Box>
                        <Box className="moreCinfoinn">
                            <Accordion expanded={expanded === 'panel1'} onChange={handleInChange('panel1')} className='asAccording'>
                                <Box className="accHeader">
                                    <Box>
                                        <Skeleton variant="rounded" width={500} height={40} />
                                    </Box>
                                </Box>
                                <AccordionDetails>
                                    <Box className="tablBox">
                                        <TableContainer component={Paper} className='tabl_contnr hoveredTable'>
                                            <Table sx={{ minWidth: 970 }} aria-label="customized table" className='tabl_pernt_main'>
                                                
                                                <TableBody className='tabl_body'>
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center" className=''>
                                                            <Skeleton variant="rounded" width="100%" height={23} />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center" className=''>
                                                            <Skeleton variant="rounded" width="100%" height={23} />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center" className=''>
                                                            <Skeleton variant="rounded" width="100%" height={23} />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                                <TableFooter className='tabl_fotr'>
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center" className=''>
                                                            <Skeleton variant="rounded" width="100%" height={23} />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </Box>
                    <Box className="moreCinfo">
                        <Box className="dateBox">
							<img src='/images/clarity_date_ic.svg' alt='' className='clarity_date_ic' />
							<Skeleton variant="text" width={80} height={25} sx={{background: '#e4e4e4 !important', borderRadius: '5px !important'}} />
						</Box>
						<Box className="moreCinfoinn">
                            <Accordion expanded={expanded === 'panel2'} onChange={handleInChange('panel2')} className='asAccording'>
                                <Box className="accHeader">
                                    <Box>
                                        <Skeleton variant="rounded" width={500} height={40} />
                                    </Box>
                                </Box>
                            </Accordion>
                        </Box>
                    </Box>
                    <Box className="moreCinfo">
                        <Box className="dateBox">
							<img src='/images/clarity_date_ic.svg' alt='' className='clarity_date_ic' />
							<Skeleton variant="text" width={80} height={25} sx={{background: '#e4e4e4 !important', borderRadius: '5px !important'}} />
						</Box>
						<Box className="moreCinfoinn aftrDnon">
                            <Accordion expanded={expanded === 'panel3'} onChange={handleInChange('panel3')} className='asAccording'>
                                <Box className="accHeader">
                                    <Box>
                                        <Skeleton variant="rounded" width={500} height={40} />
                                    </Box>
                                </Box>
                            </Accordion>
                        </Box>
                    </Box>
                </TabPanel>


                <TabPanel value={value} index={1} className="mainTabPnl whitBgWhite tabs_Cptble">
                    <Box className="bigImgSc">
                        <Box className="only_imgInBig skeleCart flxCntr ">
                            <Skeleton variant="circular" width={600} height={600} />
                        </Box>
                        <Box className="innrTblCont">
                            <Box className="tablBox">
                                <TableContainer component={Paper} className='tabl_contnr hoveredTable'>
                                    <Table sx={{ minWidth: 970 }} aria-label="customized table" className='tabl_pernt_main'>
                                        <TableBody className='tabl_body'>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
											<TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
											<TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
											<TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
											<TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
											<TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
											<TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={2} className="mainTabPnl whitBgWhite tabs_Cptble">
                    <Box className="bigImgSc">
                        <Box className="only_imgInBig skeleCart flxCntr ">
                            <Skeleton width={800} height={600} />
                        </Box>
                        <Box className="innrTblCont">
                            <Box className="tablBox">
                                <TableContainer component={Paper} className='tabl_contnr hoveredTable'>
                                    <Table sx={{ minWidth: 970 }} aria-label="customized table" className='tabl_pernt_main'>
                                        <TableBody className='tabl_body'>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} align="center" className=''>
                                                    <Skeleton variant="rounded" width="100%" height={30} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </TabPanel>
			</Box>
		</Box>
	</Box>
	)
}

