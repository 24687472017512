import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from '@mui/material'

const White = props => (
    <Grid item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
        <Box className="whiteBgBox">
            <Box className="innrCont_flx">
                <Box className="imgPent">
                    <Skeleton variant="rounded" width={90} height={90} />
                </Box>
                <Box className="rightContnt">
                    <Box className="NAimg">
                        <Skeleton variant="text" width={20} height={30} sx={{ marginRight: '10px' }} />
                        <Skeleton variant="text" width={20} height={30} />
                    </Box>
                    <Skeleton variant="text" sx={{ fontSize: '20px', width: '100%' }} />
                    <Skeleton variant="rounded" width="100%" height={40} />
                </Box>
            </Box>
        </Box>
    </Grid>
)


export default White