import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from '@mui/material'

const Green = props => (
    <Grid item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
        <Box className="grndtBox">
            <Skeleton variant="text" height={70} sx={{ width: '100%' }} />
            <Box className="blrBox">
                <Box>
                    <Skeleton variant="rounded" height={30} width={100} />
                </Box>
                <Box className="linkPrnt">
                    <Skeleton variant="text" width={50} />
                </Box>
            </Box>
        </Box>
    </Grid>
)


export default Green