import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
    Box,
    Tab,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Collapse,
    AvatarGroup,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableFooter,
    Paper
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import colors from 'assets/colors';
import { imageURL } from 'env';
import moment from 'moment';

import { Chart } from "react-google-charts";

export const options = {
    title: "",
    //pieHole: 0.3,
    is3D: false,
    colors: colors,
    legend: "none",
    chartArea: {
        top: 10,
        bottom: 10,
    }
};
export default function Captable(props) {

    const [totalShares, setTotalShares] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [pieData, setPieData] = useState([]);

    useEffect(() => {
        sumOfShares()
    }, []);


    const sumOfShares = () => {        
        let totalSharesCount = 0;
        for(const funding of props.fundingData){
            for(const investment of funding.investment_ids){
                //...Sum Total Shares
                totalSharesCount = totalSharesCount + Helper.checkIntValue(investment.shares);
            }
        }
        setTotalShares(totalSharesCount);


        //create unique investors with percentage
        let allDataArray = [];
        
        for(const funding of props.fundingData){
            for(const investment of funding.investment_ids){
                let investor = investment.investor_id;;
                if(investor){
                    //...Pie Chart Data
                    let sharesPercentage = investment.shares / totalSharesCount * 100;

                    if(sharesPercentage){
                        //..............
                        const found = allDataArray.findIndex(obj => {
                            return obj.investor._id === investor._id;
                        });
                  
                        //...
                        if(found>=0){
                            let getData = allDataArray[found];

                            let newPercentage = getData.percentage + sharesPercentage;
                            let newShares = Helper.checkIntValue(getData.shares) + Helper.checkIntValue(investment.shares);
                            allDataArray[found]['percentage'] = newPercentage;
                            allDataArray[found]['shares'] = newShares;
                        }
                        else {                        

                            let dataArray = [];
                                dataArray['investor'] = investor;
                                dataArray['percentage'] = sharesPercentage;
                                dataArray['shares'] = investment.shares;
                                allDataArray.push(dataArray);                            
                        }
                    }
                }
            }
        }

        //allDataArray.sort((a, b) => (a.shares > b.shares) ? 1 : -1)
        
        allDataArray = [...allDataArray].sort((a, b) => b.shares - a.shares);

        //adding color
        let i=0;
        for(const nData of allDataArray){
            if(i>=colors.length){
                i=0;
            }
            nData['color'] = colors[i];
            i++;
        }

        //set data array
        setTableData(allDataArray);

        //pie chart data
        let pieDataArray = [];
            let pieDataStatic = [];
            pieDataStatic[0] = "Name";
            pieDataStatic[1] = "Shares";
            pieDataArray.push(pieDataStatic);

        for(const investorData of allDataArray){
            let name = investorData.investor.first_name+' '+investorData.investor.last_name;
            let pieData = [];
                pieData[0] = name;
                pieData[1] = investorData.percentage;
                pieDataArray.push(pieData);
        }
        setPieData(pieDataArray);
    };
  
    const chartEvents = [
    {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
            const chart = chartWrapper.getChart();
            chart.container.addEventListener("click", (ev) => {
                //console.log('ev', ev)
            })

            google.visualization.events.addListener(chart, "click", e => {
              //console.log("MOUSE OVER ", e);
            });

           // console.log('button')
        }
    }
    ];

    return (
        <>
    	<Box className="bigImgSc">
        {/*<button type="button" onClick={()=>chartEvents('slice#1')}>Testing</button>
            */}
            {pieData.length > 0 &&
            <Box className="only_imgInBig chart">
                <Chart
                    chartType="PieChart"
                    width="100%"
                    height="100%"
                    data={pieData}
                    options={options}
                    //chartEvents={chartEvents}
                />


                {/*<GChart
                    chartType="PieChart"
                    chartData={pieData}
                    chartOptions={options}
                    chartWidth="100%"
                    chartHeight="100%"
                />*/}
                
            </Box>
            }

            {tableData.length>0 &&
            <Box className="innrTblCont">
                <Box className="tablBox">
                    <TableContainer component={Paper} className='tabl_contnr hoveredTable'>
                        <Table sx={{ minWidth: 400 }} aria-label="customized table" className='tabl_pernt_main'>
                            <TableBody className='tabl_body'>
                                {tableData.map((investment, i) => {

                                let investor = investment.investor;
                                let investorName = investor.first_name+' '+investor.last_name;
                                return (
                                <TableRow key={i}>
                                    <TableCell align="center" className='padng-17 brdr_tp'>
                                        <Box className="dotBgPrntt"><span style={{backgroundColor:investment.color}}></span></Box>
                                    </TableCell>
                                    <TableCell className='padng-17 brdr_tp'>
                                        <Box className="imgAndText">
                                            {investor.image && <img className="image-40" alt={investorName} src={`${imageURL}investor/${investor.image}`} />}
                                            {!investor.image && <span alt={investorName} className="image-text">{Helper.name2Letter(investorName)}</span>}
                                            <Box className="mnTxtDbl">
                                                <Typography className='grrenTxt'>
                                                    <Link to={Path.investorGetById(investor._id, investor.slug)}>
                                                    {investorName}
                                                    </Link>
                                                </Typography>
                                                <Typography className={`btnTypetxt ${Helper.investorType(investor.type)}`}>{investor.type}</Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" className=' padng-17 brdr_tp'><span className='sem_cssPernt'>{Helper.numberFormat(investment.percentage, 4)} %</span></TableCell>
                                    <TableCell align="center" className=' padng-17 brdr_tp'>
                                        <Box className="flxCntr"><span className='sem_cssPernt'>{Helper.toLocale(investment.shares)} Shares</span></Box>
                                    </TableCell>
                                </TableRow>
                                )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            }
        </Box>
        </>
    )
}