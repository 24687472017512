import React, { useState, useEffect } from 'react';
import { Box, Autocomplete, TextField, Button, Typography, Pagination, Dialog, DialogContent, FormControl } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Service from 'services';
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';
import { useForm } from "react-hook-form";
import moment from 'moment';
import  * as HelperO from '_helpers/CommonOptions';

const CompanyFunding = (props) => {

  const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const shareType = HelperO.shareType();

  const [shareTypeSelected, setShareTypeSelected] = useState({});

  useEffect(() => {
      if(props.fundingEdit?._id){
        let date = moment(props.fundingEdit.date).format('YYYY-MM-DD');
        reset({
          name: props.fundingEdit.name,
          face_value_per_share: props.fundingEdit.face_value_per_share,
          premium_per_share: props.fundingEdit.premium_per_share,
          date: date,
          description: props.fundingEdit.description,
          share_type: props.fundingEdit.share_type
        })

        const getIndex = checkShareType(props.fundingEdit);
        setShareTypeSelected(shareType[getIndex])

      }
  }, [props.fundingEdit]);

  const closeModal = () => {
    setShareTypeSelected({});
    props.handleCloseModal();
    reset({
        name: '',
        face_value_per_share: '',
        premium_per_share: '',
        date: '',
        description: '',
        share_type: '',
      })
  }

  const checkShareType = (data) => {
      const foundIndex = shareType.findIndex(row => {
          return row.value == data.share_type;
      });

      return foundIndex;
  }


  const onSubmit = async (values) => {
    setLoading(true);

    try{
    	values['company_id'] = props.companyData._id;
      
        if(props.fundingEdit._id){
          const apiPath = Path.fundingGetByIdApi(props.fundingEdit._id);
          await props.dispatch(Service.patchRequest(apiPath, values)).then((res) => {
              setLoading(false);
              props.alert('Funding Round Updated');
              props.getCompany();
              closeModal();
          });
        }
        else {
          await props.dispatch(Service.postRequest(Path.fundingCreateApi, values)).then((res) => {
              setLoading(false);
              props.alert('Funding Round Created');
              props.getCompany();
              closeModal();
          });
        }
        
      }catch(err) {
        console.log(err);
        setLoading(false);
      }
  };

  let textAddEdit = props.fundingEdit?._id ? 'Edit' : 'Add';

  return (
    <>
      <Dialog
        open={props.openModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='dialog_main_pernt'
      >
        <Box className='headin_hh'>
          <Typography component="h1">{textAddEdit} Funding Rounds</Typography>
        </Box>
        <DialogContent className='main_content'>
          <form onSubmit={handleSubmit(onSubmit)}>
	          <Box className='mb-10'>
              <label className='hed_labl mb-10'>Share Type</label>
	            <Autocomplete
                  required
                  value={shareTypeSelected?.value ? shareTypeSelected : null}
                  options={shareType}
                  getOptionLabel={(option) => {
                    return option.label
                  }}
                  onChange={(e, data) => {
                      if(data?.value){
                          setShareTypeSelected(data);
                          setValue('share_type', data.value, { shouldDirty: true })
                      }
                  }}
                  renderInput={(params) => <TextField 
                    {...params}
                    placeholder="Share Type" 
                    className='input_input' 
                    required/>
                  }
                />
	          </Box>

            <Box>
              <FormControl className='form_control'>
                <label className='hed_labl mb-10'>Round Name</label>
                <input type='text' placeholder='Round Name' className='input_input' 
                  {...register("name", { required: true })} />
                  {errors.name?.type === 'required' && <span className="alert">Round is required</span>}
              </FormControl>
            </Box>

            <Box>
                <FormControl className='form_control'>
                    <label className='hed_labl mb-10'>Face Value per Share</label>
                    <input type='text' placeholder='Face Value per Share' className='input_input' 
                    {...register("face_value_per_share", { required: true })}/>
                </FormControl>
                {errors.face_value_per_share?.type === 'required' && <span className="alert">Face Value per Share is required</span>}
            </Box>

            <Box>
                <FormControl className='form_control'>
                    <label className='hed_labl mb-10'>Premium per Share</label>
                    <input type='text' placeholder='Premium per Share' className='input_input' 
                     {...register("premium_per_share", { required: true })}/>
                </FormControl>
                {errors.premium_per_share?.type === 'required' && <span className="alert">Premium per Share</span>}
            </Box>

            <Box>
              <FormControl className='form_control'>
                <label className='hed_labl mb-10'>Date</label>
                <input type='date' className='input_input'
                  {...register("date", { required: true, valueAsDate: true })} />
                  {errors.date?.type === 'required' && <span className="alert">Date is required</span>}
              </FormControl>
            </Box>
            <Box className="last_content">
              <Box className="textarea_pernt">
                <label className='hed_labl mb-10'>Description</label>
                <textarea className='text_aria' rows="3" cols="50" placeholder='Description'
                  {...register("description", { required: true })}></textarea>
                    {errors.description?.type === 'required' && <span className="alert">Description is required</span>}
                <img src='/images/textarea_ic.svg' alt='' className='textarea_ic' />
              </Box>
              <Button type="submit" className='def_btn'>{textAddEdit} FUNDING ROUND</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CompanyFunding);