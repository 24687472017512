import { Box, Button, Grid, Typography, AvatarGroup, Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import CompanyCardSkeleton from '_loaders/CompanyCardSkeleton';

export default function GridView(props) {
    return (
        <>
        <Box className="gridViweMain whitBgWhite">
            <Grid container spacing={2}>
                {props.companiesData.docs && props.companiesData.docs.map((row, index) => {
                return (
                <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
                    <Box className="boxPrntMain">
                        <Link to={Path.companyGetById(row._id, row.slug)} className='compnyLinkAj'>
                            <Box className="imgSide">
                                {row.image && <Avatar className="image-90" alt={row.legal_name} src={`${imageURL}company/${row.image}`} />}
                                {!row.image && <span alt={row.legal_name} className="image-text large">{Helper.name2Letter(row.legal_name)}</span>}
                                <Box className="sosilIcon">
                                    <Box>
                                        <Box className="bg_img">
                                            <a href={row.instagram} target="_blank">
                                                <img src='/images/inst_ic.svg' alt='' />
                                            </a>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box className="bg_img">
                                            <a href={row.twitter} target="_blank">
                                                <img src='/images/twtr_ic.svg' alt='' />
                                            </a>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box className="bg_img">
                                            <a href={row.linkedin} target="_blank">
                                                <img src='/images/likdy_ic.svg' alt='' />
                                            </a>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="ritSide">
                                <Box className="btnTwoImg">
                                    <Box>
                                        <Typography className='btnTypetxt bgrn_yllow'>{row.business_type}</Typography>
                                    </Box>
                                    <Box className="nAimg">
                                        <img src='/images/n_img.svg' alt='' />
                                        <img src='/images/a_img.svg' alt='' />
                                    </Box>
                                </Box>
                                <Typography component="h2">
                                    {row.legal_name}
                                </Typography>
                                <Box className="btnTwoImg">
                                    <Box>
                                        <Box className="bg_img"><img src='/images/arth_ic.svg' alt='' /></Box>
                                    </Box>
                                    <Box>
                                        {row.investors.length > 0 &&
                                        <Box className="avtar_perntA2">
                                            <AvatarGroup total={row.investors.length} className='avtr_grup'>
                                                {row.investors.map((investor, i) => {
                                                let name = investor.first_name +' '+ investor.last_name;
                                                return (
                                                    <Avatar className='avtr_pp' key={i} alt={name} src={`${imageURL}investor/${investor.image}`} />
                                                )
                                                })}
                                            </AvatarGroup>
                                        </Box>
                                        }
                                    </Box>
                                </Box>
                            </Box>
                        </Link>
                    </Box>
                </Grid>
                )
                })}

                {props.loadMore &&
                <CompanyCardSkeleton card={true} />
                }
            </Grid>

            {!props.loadMore && props.companiesData.hasNextPage &&
            <Box className="lastBtn">
                <Button className='lodMorBtn' onClick={()=>props.handleLoadMore()}>
                    <span>Load More</span>
                    <img src='/images/load_more_ic.svg' alt='' className='load_more_ic' />
                    <img src='/images/load_more_ic_white.svg' alt='' className='load_more_ic_white' />
                </Button>
            </Box>
            }

        </Box>
        </>
    )
}


