import * as ActionTypes from 'store/action-types';
import Http from 'Http';

const initialState = {
  isAuthenticated: false,
  user: null,
};

//login 
const authLogin = (state, payload) => {
  console.log(payload)
  const body = payload.user;
  const authKey = payload.accessToken;
  localStorage.setItem('access_token', authKey);
  localStorage.setItem('user', JSON.stringify(body));
   Http.defaults.headers.common.auth_key = authKey;

  const stateObj = {
    ...state,
      isAuthenticated: true,
      user:body
    };
  return stateObj;
};


//check auth
const checkAuth = (state) => {
  const stateObj = {
    ...state,
    isAuthenticated: !!localStorage.getItem('access_token'),
    user: JSON.parse(localStorage.getItem('user')),
  };

  if (state.isAuthenticated) {
    Http.defaults.headers.common.auth_key = localStorage.getItem('access_token');
  }
  return stateObj;
};

//logout
const authLogout = (state) => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('user');
  const stateObj = {
    ...state,
    isAuthenticated: false,
    user: null,
  };
  return stateObj;
};



const Auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.AUTH_LOGIN:
      return authLogin(state, payload);
    case ActionTypes.AUTH_CHECK:
      return checkAuth(state);
    case ActionTypes.AUTH_LOGOUT:
      return authLogout(state);
    default:
      return state;
  }
};

export default Auth;
