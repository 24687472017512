import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
    Box,
    Tab,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Collapse,
    AvatarGroup,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableFooter,
    Paper
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import moment from 'moment';
import ChartData from './ChartData';

export default function FundingRound(props) {

    const [open, setOpen] = useState('tab0');
    const fundingData = props.companyData.funding_ids;
    const fundingDataDesc = fundingData.sort((a, b) => new Date(b.date).setHours(0, 0, 0, 0) - new Date(a.date).setHours(0, 0, 0, 0));
    const [accordianPanel, setAccordianPanel] = useState({});

    useEffect(() => {
      getAccordianPanel(fundingDataDesc);
    }, [fundingDataDesc]);

    const getAccordianPanel = (fundingDataDesc) => {
        
    	let panel = {};
    	fundingDataDesc.forEach((item, index) => {
    		let accordianPanel = 'panel' + index;
    		panel[accordianPanel] = accordianPanel;
    	});
    	setAccordianPanel(panel);

    	//console.log(panel)
    };

	const handleChange = (panel) => {
		setAccordianPanel(current => {
			const copy = {...current};

			if(copy[panel]){
				delete copy[panel];
			}
			else {
				copy[panel] = panel;
			}
			return copy;
		});
    };

    const handleChangeTab = (toogle) => {
        setOpen(open == toogle ? '' : toogle)
    };

    return (
        <>

        <ChartData
            fundingDataAsc={fundingData}
            companyData={props.companyData}
            />


		<div className="topMargin">
	        {fundingDataDesc.length>0 && fundingDataDesc.map((row, index) => {
	        let panel = 'panel' + index;

	        let noShares = 0;
	        let faceValuePerShare = 0;
	        let faceValue = 0;
	        let premiumPerShare = 0;
	        let premium = 0;
	        let amountReceived = 0;

	        //console.log(accordianPanel)
	        return (
	    	<Box className="moreCinfo" key={index}>
		        <Box className="dateBox"><img src='/images/clarity_date_ic.svg' alt='' className='clarity_date_ic' /> {moment(row.date).format("Do MMM YYYY")}</Box>
		        <Box className={`moreCinfoinn ${fundingDataDesc.length==index+1 ? 'aftrDnon' : ''}`}>
		            <Accordion expanded={panel===accordianPanel[panel]} onChange={()=>handleChange(panel)} className='asAccording'>
		                <Box className="accHeader">
		                    <Box>
		                        <AccordionSummary className='acrdn_sumry'>
		                            <img src='/images/accorn_arrow.svg' alt='' />
		                            <Typography aria-controls="panel1d-content" id="panel1d-header">{row.name}</Typography>
		                        </AccordionSummary>
		                    </Box>
		                </Box>

		                {row.investment_ids.length>0 &&
		                <AccordionDetails>
		                    <Box className="tablBox">
		                        <TableContainer component={Paper} className='tabl_contnr hoveredTable'>
		                            <Table sx={{ minWidth: 970 }} aria-label="customized table" className='tabl_pernt_main'>
		                                <TableHead>
		                                    <TableRow>
		                                        <TableCell align="center">Name</TableCell>
		                                        <TableCell align="center">No Of Shares</TableCell>
		                                        <TableCell align="center">Face Value per Share</TableCell>
	                                            <TableCell align="center">Premium per Share</TableCell>
		                                        <TableCell align="center">Total Amount Received</TableCell>
		                                    </TableRow>
		                                </TableHead>
		                                <TableBody className='tabl_body'>
		                                    {row.investment_ids.map((investment, i) => {
	                                        	const investor = investment.investor_id;

	                                            if(investor){

	                                            noShares = noShares + Helper.checkIntValue(investment.shares)
	                                            faceValuePerShare = faceValuePerShare + Helper.checkIntValue(row.face_value_per_share)
	                                            faceValue = faceValue + Helper.checkIntValue(investment.face_value)
	                                            premiumPerShare = premiumPerShare + Helper.checkIntValue(row.premium_per_share)
	                                            premium = premium + Helper.checkIntValue(investment.premium)
	                                            amountReceived = amountReceived + Helper.checkIntValue(investment.amount_received)

	                                            let investorName = investor.first_name+' '+investor.last_name;
	                                            
	                                          return (
	                                            <TableRow key={i}>
		                                        <TableCell className='padng-17'>
		                                            <Box className="imgAndText">
		                                                {investor.image && <img className="image-40" alt={investorName} src={`${imageURL}investor/${investor.image}`} />}
	                                                    {!investor.image && <span alt={investorName} className="image-text">{Helper.name2Letter(investorName)}</span>}
	                                                    <Box className="mnTxtDbl">
		                                                    <Typography className='grrenTxt'><Link to={Path.investorGetById(investor._id, investor.slug)}>{investorName}</Link></Typography>
		                                                    <Typography className={`btnTypetxt ${Helper.investorType(investor.type)}`}>{investor.type}</Typography>
		                                                </Box>
		                                            </Box>
		                                        </TableCell>
		                                        <TableCell align="center" className=' padng-17'><span className='sem_cssPernt'>{Helper.toLocale(investment.shares)}</span></TableCell>
		                                        <TableCell align="center" className=' padng-17'>
		                                            <Box className="flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt'>{Helper.toLocale(row.face_value_per_share)}</span></Box>
		                                        </TableCell>
		                                        <TableCell align="center" className='sem_cssPernt padng-17'>
		                                            <Box className="flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt'>{Helper.toLocale(row.premium_per_share)}</span></Box>
		                                        </TableCell>
		                                        <TableCell align="center" className='sem_cssPernt padng-17'>
		                                            <Box className="flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt'>{Helper.toLocale(investment.amount_received)}</span></Box>
		                                        </TableCell>
		                                    </TableRow>
		                                	)
	                                      	}
	                                        })}
		                                </TableBody>
		                                <TableFooter className='tabl_fotr'>
		                                    <TableRow>
		                                        <TableCell align="center" className=''><Box className="btnType">Total</Box></TableCell>
		                                        <TableCell align="center" className='sem_cssPernt'>{Helper.toLocale(noShares)}</TableCell>
		                                        <TableCell align="center" className='sem_cssPernt'></TableCell>
		                                        <TableCell align="center" className='sem_cssPernt'></TableCell>
		                                        <TableCell align="center" className=''>
		                                            <Box className="flxCntr"><img src='/images/grren_rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt fw-700'>{Helper.toLocale(amountReceived)}</span></Box>
		                                        </TableCell>
		                                    </TableRow>
		                                </TableFooter>
		                            </Table>
		                        </TableContainer>
		                    </Box>
		                </AccordionDetails>
		            	}
		            </Accordion>
		        </Box>
		    </Box>
			)
			})}
		</div>
        </>
    )
}