import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
    Avatar,
    Box,
    Typography,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    AvatarGroup,
    Collapse
} from '@mui/material'

import { Link, useParams } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Service from 'services';
import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import Paper from '@mui/material/Paper';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FundingView from 'components/_common/FundingView';

const InvestorView = (props) => {

    const parms = useParams();
    const [loading, setLoading] = useState(false);
    const [investor, setInvestor] = useState({});
    const [name, setName] = useState('');
    const [companyData, setCompanyData] = useState([]);

    const [openTab, setOpenTab] = useState();

    useEffect(() => {
      getInvestor();
    }, []);

    const getInvestor = async () => {
      setLoading(true);

      try{
          let apiPath = Path.investorGetById(parms.id);
          let companyIds = [];

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setInvestor(res)
            setName(res.first_name +' '+ res.last_name);
            setLoading(false);

            
            for(let comp of res.investment_ids){
              if(comp?.company_id){
                companyIds.push(comp.company_id)
              }
            }
          });

          //.......
          let companyIDArray = Helper.uniqueArray(companyIds)

          const dataArray = {companyID:companyIDArray, investorId:parms.id}
          getCompany(dataArray)


      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };


    const getCompany = async (dataArray) => {
      setLoading(true);

      try{
          await props.dispatch(Service.postRequest(Path.companyGetByIds, dataArray)).then((res) => {
            setCompanyData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };


    return (
      <>
        <Box className="Copny_dtl_page instrDtl_pernt">
          <Box className="mainPage pt_ziro">
              <ul className='breadcrumb'>
                  <li>
                      <Link to="/"><HomeIcon /></Link>
                  </li>
                  <li>
                      <ChevronRightIcon />
                  </li>
                  <li>
                      <Link to="/investors">Investors</Link>
                  </li>
                  <li>
                      <ChevronRightIcon />
                  </li>
                  <li>
                      <span>Investors Details</span>
                  </li>
              </ul>
              <Typography component="h3" className='defTitle'>Investor Profile Details</Typography>
              <Box className="fdig_runds_cont">
                  {/* <Typography component="h6" className='fd_hed'>List Of Funding Rounds</Typography> */}
                  <Box className="bordBox">
                      <Box className="up_content">
                          <Box className="btn_type">
                              <img src='/images/accorn_arrow.svg' alt='' />
                              <span>Investor </span>
                          </Box>
                          <Typography component="h1">Profile</Typography>
                      </Box>
                      <Box className="duwn_cont">
                          <Grid container spacing={2}>
                              <Grid item xs={12} md={8}>
                                  <Box className="flex_box">
                                      <Box className="logoPenrt">
                                          
                                          {investor.image && <img alt={name} src={`${imageURL}investor/${investor.image}`} height={100} width={100} />}
                                          {!investor.image && <span alt={name} className="image-text">{Helper.name2Letter(name)}</span>}

                                      </Box>
                                      <Box className="side_content">
                                          <Grid container spacing={2}>
                                              <Grid item xs={12} sm={4} lg={4} md={4}>
                                                  <Box className="first_grid_cont">
                                                      <Typography className='def_pera'>Legal Name</Typography>
                                                      <Typography className='def_pera fw_500'>{name}</Typography>
                                                  </Box>
                                              </Grid>
                                              <Grid item xs={12} sm={4} lg={4} md={4}>
                                                  <Box className="Web_site">
                                                      <Typography className='def_pera'>Type</Typography>
                                                      <Box className="angl_instr">
                                                          <img src='/images/angel_investor_ic.svg' alt='' />
                                                          <Typography>{investor.type}</Typography>
                                                      </Box>
                                                  </Box>
                                              </Grid>
                                              <Grid item xs={12} sm={12} lg={4} md={4}>
                                                  <Box className="Scil_Mdia">
                                                      <Typography className='def_pera'>Social Media</Typography>
                                                      <a href={investor.linkedin} target='_blank' className='sol_mdiy_link'>
                                                          <img src='/images/beaconinkedin_ic.svg' alt='' className='first_img' />
                                                          {investor.linkedin}
                                                          <img src='/images/Pubcomp_ic_green.svg' alt='' className='last_img' />
                                                      </a>
                                                  </Box>
                                              </Grid>
                                          </Grid>
                                      </Box>
                                  </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                  <Box className="Dscrpin_cont">
                                      <Typography className='def_pera'>Description</Typography>
                                      <Box className="border_box">
                                          <Typography>{investor.description}</Typography>
                                      </Box>
                                  </Box>
                              </Grid>
                          </Grid>
                      </Box>
                  </Box>
              </Box>
          </Box>
          
      </Box>


      <Box className="mainPage mb-40">
        <Typography component="h3" className='defTitle'>List Of Companies</Typography>
        <Box className="contentBox">
          <TableContainer component={Paper} className='tabl_contnr'>
            <Table aria-label="collapsible table" className='tabl_pernt_main tabl_pernt_mainV2'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">Legal Name</TableCell>
                  <TableCell align="center">Business Type</TableCell>
                  <TableCell align="center">Website</TableCell>
                  <TableCell align="center">Social Media</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='tabl_body'>                
                
                <React.Fragment>
                  {companyData.length>0 && companyData.map((company, index) => {
                    let toogle = 'tab'+index;
                    return (
                    <>
                    <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={index}>
                      <TableCell >
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenTab(openTab == toogle ? '' : toogle)}
                          className='accron_btn'
                        >
                        {openTab==toogle ? <Box className="pls_mns_ic_bg"><RemoveIcon className='remove_icon' /></Box> : <Box className=""><AddIcon className='add_icon' /></Box>}
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                          <Link to={Path.companyGetByIdAdmin(company._id)} className='rtr_link'>
                              {company.image && <Avatar alt={company.legal_name} src={`${imageURL}company/${company.image}`} />}
                              {!company.image && <span alt={company.legal_name} className="image-text">{Helper.name2Letter(company.legal_name)}</span>}
                              {company.legal_name}
                          </Link>
                      </TableCell>
                      <TableCell align="center" className='plk_compy'>{company.business_type}</TableCell>
                      <TableCell align="center">
                          <a target='_blank' href={company.website} className='Pubcomp__link_pernt'>
                              {company.website}
                              <img src='/images/Pubcomp_ic.svg' alt='' />
                          </a>
                      </TableCell>
                      <TableCell align="center">
                          <Box className="soil_mdia">
                              <a href={company.instagram} target="_blank" className="soil_mdia_bgBox"><img src='/images/tlegram_ic.svg' alt='' /></a>
                              <a href={company.twitter} target="_blank" className="soil_mdia_bgBox"><img src='/images/twtr_ic.svg' alt='' /></a>
                              <a href={company.linkedin} target="_blank" className="soil_mdia_bgBox"><img src='/images/linkdy.svg' alt='' /></a>
                          </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Box className="two_btn_pernt">
                          <Link to={Path.companyGetByIdAdmin(company._id)} className='view_btn'><img src='/images/eye_ic.svg' alt='' />View</Link>
                        </Box>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={openTab==toogle} timeout="auto" unmountOnExit>
                          <FundingView
                            fundingData={company.funding_ids}
                            actions={false}
                            />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                    </>
                    )
                  })}

                </React.Fragment>

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

    </>
    );
}



const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(InvestorView);