import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material'
import { Link } from "react-router-dom";

import WhiteCard from './cards_home/White';
import GreenCard from './cards_home/Green';

const ListSkeleton = props => (	
	<Box className="srolWidth whitBgWhite">
        <Box className="list_vwMain">
            <Box className="scrolPernt">
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>

                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>

                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
                <Box className="margnPernt">
                    <Skeleton variant="rectangular" width="100%" height={83} />
                </Box>
			</Box>
	    </Box>
	</Box>
)


export default ListSkeleton