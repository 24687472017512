import React from "react";
import { CardHeader, AvatarGroup, Avatar, Table, TableCell, TableRow, Box, MenuItem, Button, Dialog, Typography, DialogContent, FormControl, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Link } from "react-router-dom";
import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import Paginate from 'components/_common/Paginate';

const CompaniesList = (props) => {


  return (
    <>
    	<TableContainer component={Paper} className='tabl_contnr'>
            <Table sx={{ minWidth: 1400 }} aria-label="customized table" className='tabl_pernt_main'>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Legal Name</TableCell>
                        <TableCell align="center">Business Type</TableCell>
                        <TableCell align="center">Website</TableCell>
                        <TableCell align="center">Social Media</TableCell>
                        <TableCell align="center">Investors</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='tabl_body'>
                {props.companiesData.docs && props.companiesData.docs.map((row, index) => {
					return (
                    <TableRow key={index}>
                        <TableCell>
                            <Link to={Path.companyGetByIdAdmin(row._id)} className='rtr_link'>
                                {row.image && <Avatar alt={row.legal_name} src={`${imageURL}company/${row.image}`} />}
                        		{!row.image && <span alt={row.legal_name} className="image-text">{Helper.name2Letter(row.legal_name)}</span>}
                                {row.legal_name}
                            </Link>
                        </TableCell>
                        <TableCell align="center" className='plk_compy'>{row.business_type}</TableCell>
                        <TableCell align="center">
                            <a target='_blank' href={row.website} className='Pubcomp__link_pernt'>
                                {row.website}
                                <img src='/images/Pubcomp_ic.svg' alt='' />
                            </a>
                        </TableCell>
                        <TableCell align="center">
                            <Box className="soil_mdia">
                                <a href={row.instagram} target="_blank" className="soil_mdia_bgBox"><img src='/images/tlegram_ic.svg' alt='' /></a>
                                <a href={row.twitter} target="_blank" className="soil_mdia_bgBox"><img src='/images/twtr_ic.svg' alt='' /></a>
                                <a href={row.linkedin} target="_blank" className="soil_mdia_bgBox"><img src='/images/linkdy.svg' alt='' /></a>
                            </Box>
                        </TableCell>
                        <TableCell align="center">
                            <Box className="avtar_pernt">
                                <AvatarGroup total={row.investors.length} className='avtr_grup'>
                                    {row.investors.length > 0 && row.investors.map((investor, i) => {
                                    let name = investor.first_name +' '+ investor.last_name;
                                    return (
                                        <Avatar className='avtr_pp' key={i} alt={name} src={`${imageURL}investor/${investor.image}`} />
                                    )
                                    })}
                                </AvatarGroup>
                            </Box>
                        </TableCell>
                        <TableCell align="center" className=''>
                            <Box className="two_btn_pernt">
                                <Button className='edit_btn' onClick={()=>props.handleClickOpenModal(row._id)}>Edit</Button>
                                <Button className='delete_btn' onClick={()=>props.deleteCompany(row._id)}>Delete</Button>
                                <Link to={Path.companyGetByIdAdmin(row._id)} className='view_btn'><img src='/images/eye_ic.svg' alt='' />View</Link>
                            </Box>
                        </TableCell>
                    </TableRow>
                    )
				})}
                </TableBody>
            </Table>
        </TableContainer>

        <Paginate
                data={props.companiesData}
                handlePageChange={props.handlePageChange}
                path={Path.companyAdmin}
                />
    </>
  );
}

export default CompaniesList;