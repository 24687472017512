import moment from 'moment';

export const momentD = (date=null) => {
	if(date)
		return moment(date).format("Do MMM YYYY");

	return moment().format("Do MMM YYYY");
}


export const DateRange = () => {
	let fromYear = moment('1990').format("YYYY");
	fromYear = checkIntValue(fromYear);

	let toYear = moment().add(2, 'years').format("YYYY");
	toYear = checkIntValue(toYear);

	let yearsArray = Array(toYear - fromYear + 1).fill().map((_, idx) => {
		let year = fromYear + idx;
			

		let toShortYear = year + 1;
			toShortYear = toShortYear.toString().slice(-2);

		let dateArray = [];
			dateArray['value'] = year;
			dateArray['label'] = year +' - '+ toShortYear;
			
			return dateArray;
	});


	const numDescending = [...yearsArray].sort((a, b) => b.value - a.value);

	
	return numDescending;
}

function range(start, end) {
	return Array(end - start + 1).fill().map((_, idx) => start + idx)
}


export const isValidHttpUrl = (string) => {
	let url;
	try {
		url = new URL(string);
	} catch (_) {
		return false;
	}
	return url.protocol === "http:" || url.protocol === "https:";
}

export const name2Letter = (name) => {
	let newName = '';
	if(name){
		let nameArray = name.split(" ");
		
		if(nameArray.length>0){
			nameArray.map((string) => {
				newName += string.substr(0, 1)
			})
		}
		else {
			newName = nameArray.substr(0, 1)
		}
	}
	return newName;
}


export const checkIntValue = (value) => {
	if(!isNaN(value)){
		return parseInt(value);
	}
	return 0;
}

export const uniqueArray = (uniqueArray) => {
	return uniqueArray.filter(function(elem, pos) {
	    return uniqueArray.indexOf(elem) == pos;
	})
}

export const toFixed = (number) => {
	return number.toFixed(2);
}

export const toLocale = (number) => {
	if(number)
		return parseInt(number).toLocaleString('hi');

	return 0;
}

export const numberFormat = (num, n) => {
  if(num > 0){
    num = String(num);
    if(num.indexOf('.') !== -1) {
      var numarr = num.split(".");
      if (numarr.length > 1) {
        if(n > 0){
          var temp = numarr[0] + ".";
          for(var i = 0; i < n; i++){
            if(i < numarr[1].length){
              temp += numarr[1].charAt(i);
            }
          }
          num = Number(temp);
        }
      }
    }
  }
  return Number(num);
}


export const investorType = (type) => {	
	let color;
	if(type=='Angel')
		color = 'angel_bg';

	if(type=='Fund')
		color = 'fund_bg';

	if(type=='Founder')
		color = 'founder_bg';

	return color;
}

export const slugify = (string) => {	
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
};