import React from "react";
import { CardHeader, Avatar, Table, TableCell, TableRow, Box, MenuItem, Button, Dialog, Typography, DialogContent, FormControl, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Link } from "react-router-dom";
import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import Paginate from 'components/_common/Paginate';

const InvestorList = (props) => {


  return (
    <>
    	<TableContainer component={Paper} className='tabl_contnr'>
            <Table sx={{ minWidth: 1400 }} aria-label="customized table" className='tabl_pernt_main'>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Investor Type</TableCell>
                        <TableCell align="center">Lastest Investments</TableCell>
                        <TableCell align="center">Social Media</TableCell>
                        <TableCell align="center">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='tabl_body'>
                {props.investorsData.docs && props.investorsData.docs.map((row, index) => {
					let name = row.first_name +' '+ row.last_name;
					return (
                    <TableRow key={index}>
                        <TableCell className='padng-17'>
                        	
                            <Link to={Path.investorGetByIdAdmin(row._id)} className='rtr_link'>
                                {row.image && <Avatar alt={name} src={`${imageURL}investor/${row.image}`} />}
		                        {!row.image && <span alt={name} className="image-text">{Helper.name2Letter(name)}</span>}
                                {row.first_name} <br /> {row.last_name}
                            </Link>
                        </TableCell>
                        <TableCell align="center" className='padng-17'>
                            <Box className="angl_instr">
                                <img src='/images/angel_investor_ic.svg' alt='' />
                                <Typography>{row.type}</Typography>
                            </Box>
                        </TableCell>
                        <TableCell align="center" className='padng-17'>
                            {row.companies.length > 0 && row.companies.map((company, i) => {
                            if(i==0){
                            return (
                                <Link key={i} to={Path.companyGetByIdAdmin(company._id)} className='rtr_link rtr_linkA2'>
                                    {company.image && <Avatar alt={company.legal_name} src={`${imageURL}company/${company.image}`} />}
                                    {!company.image && <span alt={company.legal_name} className="image-text">{Helper.name2Letter(company.legal_name)}</span>}
                                    <span>
                                        <span className='fw-700'>{company.legal_name}</span> 
                                        <br />
                                        {row.companies.length>1 ? (row.companies.length-1)+'+ more investment' : ''}
                                    </span>
                                </Link>
                            )
                            }
                            })}
                        </TableCell>
                        <TableCell align="center" className='padng-17'>
                            <Box className="soil_mdia">
                                <a href={row.linkedin} target="_blank" className="soil_mdia_bgBox"><img src='/images/linkdy.svg' alt='' /></a>
                            </Box>
                        </TableCell>
                        <TableCell align="center" className='padng-17'>
                            <Box className="two_btn_pernt">
                                <Button className='edit_btn' onClick={()=>props.handleClickOpen(row._id)}>Edit</Button>
                                <Button className='delete_btn' onClick={()=>props.deleteInvestor(row._id)}>Delete</Button>
                                <Link to={Path.investorGetByIdAdmin(row._id)} className='view_btn'><img src='/images/eye_ic.svg' alt='' />View</Link>
                            </Box>
                        </TableCell>
                    </TableRow>
                    )
				})}
                </TableBody>
            </Table>
        </TableContainer>

        <Paginate
            data={props.investorsData}
            handlePageChange={props.handlePageChange}
            path={Path.investorAdmin}
            />
    </>
  );
}

export default InvestorList;