import React, { useState, useEffect } from 'react'
import GChart from 'components/_common/GChart';
import moment from 'moment';
import * as Helper from '_helpers/CommonFunctions';
import {
    Box,
    Typography,
    Grid,
} from '@mui/material'
import { imageURL } from 'env';



export const chartOptions = {
	//title: "Share Price",
	hAxis: { title: "Month and Year", titleTextStyle: {italic: false} },
	vAxis: { title: "Share Price", titleTextStyle: {italic: false} },
	chartArea: { width: "80%", height: "70%" },
	legend: "none",
	colors: ['#009037'],
	pointsVisible: true
};

const ChartData = (props) => {

	const [data, setData] = useState([]);
	const [dataAll, setDataAll] = useState([]);
	const company = props.companyData;
	const [lastSharePrice, setLastSharePrice] = useState(0);
	const [portfolioPercentage, setPortfolioPercentage] = useState(0);

	useEffect(() => {
        sharesData()
    }, []);


    const sharesData = () => { 
    	
    	let datesDataArray = [];
    	let cDataArray = [];

    	let startSharePoint = 0;
  		let endSharePoint = 0;

    	let ncsData = [];
    			ncsData[0] = 'Months';
    			ncsData[1] = 'Share Price (Rs)';
    			cDataArray.push(ncsData);


    	const fundingData = props.fundingDataAsc;
		const fundingDatas = fundingData.sort((a, b) => new Date(a.date).setHours(0, 0, 0, 0) - new Date(b.date).setHours(0, 0, 0, 0));

  		//fundings
  		let i = 0;
    	for(const funding of fundingData){    		
    		i++;
    		const date = Helper.momentD(funding.date);

    		let sharePrice = Helper.checkIntValue(funding.face_value_per_share) + Helper.checkIntValue(funding.premium_per_share);

    		//investment array
    		let nData = [];
    			nData['face_value_per_share'] = funding.face_value_per_share;
    			nData['premium_per_share'] = funding.premium_per_share;
    			nData['date'] = date;
    			nData['sharePrice'] = sharePrice;

	    	//..chart data
    		let ncData = [];
    			ncData[0] = date;
    			ncData[1] = sharePrice;
    			

    		//...push data
    		datesDataArray.push(nData);
    		cDataArray.push(ncData);


    		if(i==1){
	    		startSharePoint = sharePrice;
		    }
		    endSharePoint = sharePrice;
    	}


    	if(cDataArray.length>0)
    		setData(cDataArray);

    	if(datesDataArray.length>0)
    		setDataAll(datesDataArray);


    	//calculate percentage
    	let percentage = ((endSharePoint - startSharePoint) /  startSharePoint) * 100;
		


    	setLastSharePrice(Helper.toLocale(endSharePoint));
    	setPortfolioPercentage(Helper.toFixed(percentage));

    };

	return (
    <>
    	{data.length>1 &&
    	<>

    		{/*<Grid container spacing={2} className="back-white-shade">
			  <Grid item xs={2} className="item">
			    <Typography>Total Investment raised</Typography>
			    <Typography>$4.2M</Typography>
			    <Typography>INR 34 Cr</Typography>
			  </Grid>
			  <Grid item xs={2} className="item">
			    <Typography>Total Investment raised</Typography>
			    <Typography>$4.2M</Typography>
			    <Typography>INR 34 Cr</Typography>
			  </Grid>
			  <Grid item xs={2} className="item">
			    <Typography>Total Investment raised</Typography>
			    <Typography>$4.2M</Typography>
			    <Typography>INR 34 Cr</Typography>
			  </Grid>
			  <Grid item xs={6} className="item">
			    <Typography>Total Investment raised</Typography>
			    <Typography>$4.2M</Typography>
			    <Typography>INR 34 Cr</Typography>
			  </Grid>
			</Grid>*/}


	    	


			<Box className="chart-top flex">
    			<Typography className="title">Current Share Price</Typography>
    			<Box className="Txt_imgBx">
                    {company.image && <img alt={company.legal_name} className="image-45" src={`${imageURL}company/${company.image}`} />}
                    {!company.image && <span alt={company.legal_name} className="image-text large">{Helper.name2Letter(company.legal_name)}</span>}
                </Box>

				<Box className="percentage">
                	<Typography className="last-price flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /> {lastSharePrice}</Typography>
                	<Typography className={`float-right ${portfolioPercentage>=0 ? 'green' : 'red'}-text`}>{portfolioPercentage>=0 ? '+' : ''}{portfolioPercentage}% till date</Typography>
                </Box>
    		</Box>

    		<GChart
				chartType="AreaChart"
				chartData={data}
				chartOptions={chartOptions}
				chartWidth="100%"
				chartHeight="400px"
				/>
		</>
		}
    </>
    )
}

export default ChartData;