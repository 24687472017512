import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Pagination, Dialog, DialogContent, FormControl } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Select from 'react-select'

import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';
import Service from 'services';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import Swal from 'sweetalert2'

import CompaniesList from './CompaniesList';
import CompaniesCreateEdit from './CompaniesCreateEdit';

const CompanyIndex = (props) => {
  const history = useHistory();
  let parmSearch = window.location.search;
  let params = new URLSearchParams(parmSearch);
  const [page, setPage] = useState(params.get('page') ? parseInt("" + params.get('page')) : 1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filter, setFilter] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [editCompany, setEditCompany] = useState({});
  const [filteredValue, setFilteredValue] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = (id=null) => {
    if(id)
      getCompanybyID(id);

    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
      getCompanies();
  }, [page]);

  const handlePageChange = (p) => {
    setPage(p);
  };

  const handleKeyDownSearch = (event) => {
      if (event.key === 'Enter') {
          getCompanies();
      }
  };


  const getCompanies = async () => {
    setLoading(true);

    try{
        const apiPath = Path.companyIndex + `?search=${search}&limit=${limit}&page=${page}`;
        await props.dispatch(Service.getRequest(apiPath)).then((res) => {
          setCompaniesData(res)
          
          setLoading(false);
          setSuccess(true);
        });
    } catch(err) {
        console.log(err);
        setLoading(false);
        if(err && err.data && err.data.msg){
          //props.alert("Oops!", err.data.msg, "error");
        }
    }
  };


  const getCompanybyID = (id) => {
    const foundCompany = companiesData.docs.find(company => {
      return company._id === id;
    });
    if(foundCompany)
      setEditCompany(foundCompany);
  };


  const deleteCompany = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        try{
          const apiPath = Path.companyGetById(id);
          props.dispatch(Service.deleteRequest(apiPath)).then((res) => {
              getCompanies();
              Swal.fire(
                'Deleted!',
                'Company deleted.',
                'success'
              )
          });
        } catch(err) {
            console.log(err);
            setLoading(false);
            if(err && err.data && err.data.msg){
              props.swal("Oops!", err.data.msg, "error");
            }
        }
      }
    })
  };


  return (
    <>
      <Helmet title="List Companies" />
      <Box className="mainPage">
          <ul className='breadcrumb'>
            <li>
              <Link to={Path.dashboard}><HomeIcon /></Link>
            </li>
            <li>
              <ChevronRightIcon />
            </li>
            <li>
              <span>Companies</span>
            </li>
          </ul>
          <Typography component="h3" className='defTitle'>List Of Companies</Typography>
          <Box className="topSearch">
            <Box className="customSearch">
              <Box className="iSearch">
                <input type='text' 
                  placeholder='Search Companies'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onKeyDown={handleKeyDownSearch}
                  />
              </Box>
              <SearchIcon className='searchIcon' />
            </Box>
            <Button className='defBtn' onClick={handleClickOpenModal}>
              <AddIcon /> Add Company
            </Button>
          </Box>
          <Box className="contentBox">
            <CompaniesList
              companiesData={companiesData}
              handleClickOpenModal={handleClickOpenModal}
              deleteCompany={deleteCompany}
              handlePageChange={handlePageChange}
              />
          </Box>
          {/*<Box className="pgtion_box">
            <Pagination count={10} color="primary" />
          </Box>*/}
      </Box>


      <CompaniesCreateEdit
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        alert={props.alert}
        getCompanies={getCompanies}
        editCompany={editCompany}
        />

    </>
  );
}

const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CompanyIndex);