import React, { useState, useEffect } from 'react'
import GChart from 'components/_common/GChart';
import moment from 'moment';
import * as Helper from '_helpers/CommonFunctions';
import {
    Box,
    Typography,
} from '@mui/material'
import { imageURL } from 'env';



export const chartOptions = {
	//title: "Portfolio Value",
	hAxis: { title: "Month and Year", titleTextStyle: {italic: false} },
	vAxis: { title: "Portfolio Value", titleTextStyle: {italic: false} },
	chartArea: { width: "80%", height: "70%" },
	legend: "none",
	colors: ['#009037'],
	pointsVisible: true,
	chartLanguage: 'hi_IN',
	tooltip: {isHtml: true}
};

const ChartData = (props) => {

	const company = props.company;
	const [data, setData] = useState([]);
	const [dataAll, setDataAll] = useState([]);
	const [lastSharePrice, setLastSharePrice] = useState(0);
	const [portfolioPercentage, setPortfolioPercentage] = useState(0);

	useEffect(() => {
        sharesData()
    }, []);


    const sharesData = () => { 
    	
    	let datesDataArray = [];
    	let cDataArray = [];

    	let ncsData = [];
    			ncsData[0] = 'Months';
    			ncsData[1] = 'Share Price (Rs)';
    			ncsData[2] = { role: "tooltip", type: "string", p: {html: true}};
    			cDataArray.push(ncsData);


    	const fundingData = company.funding_ids;
		const fundingDataAsc = fundingData.sort((a, b) => new Date(a.date).setHours(0, 0, 0, 0) - new Date(b.date).setHours(0, 0, 0, 0));

  		//fundings

  		let shareLastPrice = 0;
  		let sharesMax = 0;

  		let i=0;

  		let startSharePoint = 0;
  		let endSharePoint = 0;


    	for(const funding of fundingDataAsc){

    		const date = Helper.momentD(funding.date);
		    let sharePrice = Helper.checkIntValue(funding.face_value_per_share) + Helper.checkIntValue(funding.premium_per_share);
    		
		    //investment array
    		let nData = [];
    			nData['face_value_per_share'] = funding.face_value_per_share;
    			nData['premium_per_share'] = funding.premium_per_share;
    			nData['date'] = date;
    			nData['sharePrice'] = sharePrice;

    		let shares = 0;
    		if(funding.investment_ids.length>0){    			
    			for(const investment of funding.investment_ids){
		    		shares = shares + Helper.checkIntValue(investment.shares);
	    		}
	    		i++;
	    	}
	    	
	    	
	    	sharesMax = sharesMax + shares;
	    	let shareMultiple = sharesMax * sharePrice;

	    	
	    	if(i==1){
	    		startSharePoint = shareMultiple;
		    }
		    endSharePoint = shareMultiple;


	    	if(i>0){
	    		//..chart data
	    		let ncData = [];
	    			ncData[0] = date;
	    			ncData[1] = shareMultiple;
	    			ncData[2] = `
	    				<div style="padding:10px">
	    					<b style="font-size:15px">${date}</b></br>
	    					<span>Shares: <b>${Helper.toLocale(sharesMax)}</b></span></br>
	    					<span>Share Price: <b>Rs ${Helper.toLocale(sharePrice)}</b></span></br>
	    					<span>Portfolio Value: <b>Rs ${Helper.toLocale(shareMultiple)}</b></span></br>
	    				</div>
	    			`;
	    		
	    		//...push data
	    		cDataArray.push(ncData);

		    	datesDataArray.push(nData);
		    }

		    //....
		    shareLastPrice = sharePrice;
    	}

    	//calculate percentage
    	const percentageCal = ((endSharePoint - startSharePoint) /  startSharePoint) * 100;
    	const percentage = Helper.checkIntValue(percentageCal);
	    

    	if(cDataArray.length>0){

    		//...last share data
    		let nDate = Helper.momentD();
    		let shareMultiple1 =shareLastPrice * sharesMax;
    		let ncData1 = [];
    		 	ncData1[0] = nDate;
    		  	ncData1[1] = shareMultiple1;
				ncData1[2] = `
	    				<div style="padding:10px">
	    					<b style="font-size:15px">${nDate}</b></br>
	    					<span>Shares: <b>${sharesMax}</b></span></br>
	    					<span>Share Price: <b>${shareLastPrice}</b></span></br>
	    					<span>Portfolio Value: <b>Rs ${Helper.toLocale(shareMultiple1)}</b></span></br>
	    				</div>
	    			`;
	    		
    		 	cDataArray.push(ncData1);

    		setData(cDataArray);
    	}



    	if(datesDataArray.length>0)
    		setDataAll(datesDataArray);

    	setLastSharePrice(Helper.toLocale(endSharePoint));
    	setPortfolioPercentage(Helper.toFixed(percentage));

    };

	return (
    <>
    	{data.length>1 &&
    	<>

    		<Box className="chart-top flex">
	    			<Typography className="title">Current Portfolio Value</Typography>
	    			<Box className="Txt_imgBx">
	                    {company.image && <img alt={company.legal_name} className="image-45" src={`${imageURL}company/${company.image}`} />}
	                    {!company.image && <span alt={company.legal_name} className="image-text large">{Helper.name2Letter(company.legal_name)}</span>}
	                </Box>

					<Box className="percentage">
	                	<Typography className="last-price flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /> {lastSharePrice}</Typography>
	                	<Typography className={`float-right ${portfolioPercentage>=0 ? 'green' : 'red'}-text`}>{portfolioPercentage>=0 ? '+' : ''}{portfolioPercentage}% till date</Typography>
	                </Box>
    		</Box>

    		<GChart
				chartType="AreaChart"
				chartData={data}
				chartOptions={chartOptions}
				chartWidth="100%"
				chartHeight="400px"
				/>
		</>
		}
    </>
    )
}

export default ChartData;