import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
    Box,
    Tab,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Collapse,
    AvatarGroup,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableFooter,
    Tabs,
    Paper
} from '@mui/material'
import { Link, useParams, useHistory, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Service from 'services';
import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';

import Portfolio from './Details/Portfolio';
import CapitalGains from './Details/CapitalGains';

import ProductsIndex from '../Products/ProductsIndex';
import InvestorDetailsSkeleton from '_loaders/InvestorDetailsSkeleton';

import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Helmet from 'react-helmet';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const InvestorView = (props) => {
    
    const parms = useParams();
    const history = useHistory();

    let tab = 0;
        if(parms.tab === 'capital-gains'){
            tab = 1;
        }


    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);    
    const [investor, setInvestor] = useState({});
    const [name, setName] = useState('');
    const [companyData, setCompanyData] = useState([]);
    const [companyGainData, setCompanyGainData] = useState([]);

    const [value, setValue] = useState(tab);

    const handleOneChange = (event, newValue) => {
        setValue(newValue);
        let route = '';
        if(newValue==0){
            route = 'portfolio';
        }
        if(newValue==1){
            route = 'capital-gains';
        }

        const routeUrl = Path.investorGetById(parms.id, parms.name, route);
        history.push(routeUrl);        
        Helper.scrollToTop();

        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
      getInvestor();
      props.scrollToTop();
    }, [parms.id]);

    const getInvestor = async () => {
      setLoading(true);

      try{
          let apiPath = Path.investorGetById(parms.id);
          let companyIds = [];

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setInvestor(res)
            setName(res.first_name +' '+ res.last_name);
            setLoading(false);

            
            for(let comp of res.investment_ids){
                if(comp?.company_id){
                    companyIds.push(comp.company_id)
                }
            }
          });

          //.......
          let companyIDArray = Helper.uniqueArray(companyIds)

          const dataArray = {companyID:companyIDArray, investorId:parms.id};
          getCompany(dataArray);
          getCompanyInvestor(dataArray);

      } catch(err) {
          console.log(err);
          setLoading(false);
          setIsError(true);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };

    const getCompany = async (dataArray) => {
      setLoading(true);

      try{
          await props.dispatch(Service.postRequest(Path.companyGetByIds, dataArray)).then((res) => {
            setCompanyData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };

    const getCompanyInvestor = async (dataArray) => {
      setLoading(true);

      try{
          await props.dispatch(Service.postRequest(Path.companyGetByInvestor, dataArray)).then((res) => {
            setCompanyGainData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };



    return (
    <>
    {isError &&
      <Redirect to={Path.investors} />
    }
    
    {investor &&
    <Helmet>
        <title>{name + ' | Portfolio | The Balance Sheet'}</title>
        <meta name="description" content={investor.description} />
    </Helmet>
    }
    
    <ul className='breadcrumb'>
        <li>
            <Link to={Path.home}><HomeIcon /></Link>
        </li>
        <li>
            <ChevronRightIcon />
        </li>
        <li>
            <Link to={Path.investors}>Investors</Link>
        </li>
        <li>
            <ChevronRightIcon />
        </li>
        <li>
            <span>Detail Of Investor</span>
        </li>
        <li className='brdrBx_pernt'>
            <Box className="border_box" />
        </li>
    </ul>


    {loading && <InvestorDetailsSkeleton type={value} />}

    {!loading && 
    <Box className="dtilPagMain InvstrdtilPagMain ">
        <Box className="whitBox">
            <Box className="whtBoxInnr">
                <Box>
                    {investor.image && <img className="image-90" alt={name} src={`${imageURL}investor/${investor.image}`} />}
                    {!investor.image && <span alt={name} className="image-text large">{Helper.name2Letter(name)}</span>}
                </Box>
                <Box className="tI_bxx">
                    <Typography className='ubrTxt'>
                        {name}
                    </Typography>
                    <Box className="btnTImgFlx">
                        <Box>
                            <Typography className='btnTypetxt angel_bg'>{investor.type}</Typography>
                        </Box>
                        <Box className="nAimg">
                            <img src='/images/n_img.svg' alt='' />
                            <img src='/images/a_img.svg' alt='' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="newPrnt">
                <Box className="sosilIcon">
                    <Box>
                        <a href={investor.linkedin} target='_blank'><Box className="bg_img"><img src='/images/likdy_ic.svg' alt='' /></Box></a>
                    </Box>
                </Box>
            </Box>
            <Box className="lasTxxt">
                <Typography>{investor.description}</Typography>
            </Box>
        </Box>        

        <Box className="tabsContMain">
            <Box sx={{ width: '100%' }} className="aaaaa">
                <Box className="tebsHed" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs className='tbHedInrr' value={value} onChange={handleOneChange} aria-label="basic tabs example">
                        <Tab className='tabsOnly' label="Portofolio" icon={<Box className="tabImgBg"><img src="/images/funding_rounds_ic.svg" alt='' /></Box>} {...a11yProps(0)} />
                        <Tab className='tabsOnly' label="Capital Gains" icon={<Box className="tabImgBg"><img src="/images/funding_rounds_ic.svg" alt='' /></Box>} {...a11yProps(0)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} className="mainTabPnl whitBgWhite">
                    <Portfolio 
                        companyData={companyData}
                        />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CapitalGains 
                        companyData={companyData}
                        companyGainData={companyGainData}
                        />
                </TabPanel>
            </Box>
        </Box>
    </Box>
    }




    <ProductsIndex />
    
    </>
    );
}



const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(InvestorView);