import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { Box, Typography, Grid, Button, Tabs, Tab } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PropTypes from 'prop-types';

import { useParams, useHistory, Link, Redirect } from "react-router-dom";
import Service from 'services';
import * as Path from 'routes/paths';

import * as Helper from '_helpers/CommonFunctions';
import { imageURL, APP_ENV } from 'env';

import FundingRound from './Details/FundingRound';
import Captable from './Details/Captable';
import SummaryFinancials from './Details/SummaryFinancials';

import CompanyDetailsSkeleton from '_loaders/CompanyDetailsSkeleton';

import ProductsIndex from '../Products/ProductsIndex';
import Helmet from 'react-helmet';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CompanyView = (props) => {
    const parms = useParams();
    const history = useHistory();

    let type = 0;
        if(parms.tab === 'captable'){
            type = 1;
        }
        if(parms.tab === 'summary'){
            type = 2;
        }

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [companyData, setCompanyData] = useState({});

    const [value, setValue] = useState(type);


    const handleOneChange = (event, newValue) => {
        setValue(newValue);
        let route = '';
        if(newValue==0){
            route = 'funding-rounds';
        }
        if(newValue==1){
            route = 'captable';
        }
        if(newValue==2){
            route = 'summary';
        }

        const routeUrl = Path.companyGetById(parms.id, parms.name, route);
        history.push(routeUrl);
        Helper.scrollToTop();

        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    useEffect(() => {
      getCompany();
    }, [parms.id]);

    const getCompany = async () => {
      setLoading(true);

      try{
          let req = Path.companyGetById(parms.id);
          await props.dispatch(Service.getRequest(req)).then((res) => {
            setCompanyData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          setIsError(true);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };


    return (
    <>
    {isError &&
      <Redirect to={Path.companies} />
    }

    {companyData &&
    <Helmet>
        <title>{companyData.legal_name + ' | Captable | The Balance Sheet'}</title>
        <meta name="description" content={companyData.description} />
    </Helmet>
    }

    <ul className='breadcrumb'>
        <li>
            <Link to={Path.home}><HomeIcon /></Link>
        </li>
        <li>
            <ChevronRightIcon />
        </li>
        <li>
            <Link to={Path.companies}>Companies</Link>
        </li>
        <li>
            <ChevronRightIcon />
        </li>
        <li>
            <span>Detail Of Company</span>
        </li>
        <li className='brdrBx_pernt'>
            <Box className="border_box" />
        </li>
    </ul>
    
    {loading && <CompanyDetailsSkeleton type={value} />}

    {!loading && 
    <Box className="dtilPagMain">
        <Box className="whitBox">
            <Box className="whtBoxInnr">
                <Box>
                    {companyData.image && <img className="image-90" alt={companyData.legal_name} src={`${imageURL}company/${companyData.image}`} />}
                    {!companyData.image && <span alt={companyData.legal_name} className="image-text large">{Helper.name2Letter(companyData.legal_name)}</span>}
                </Box>
                <Box className="tI_bxx">
                    <Typography className='ubrTxt'>
                        {companyData.legal_name}
                    </Typography>
                    <Box className="btnTImgFlx">
                        <Box>
                            <Typography className='btnTypetxt bgrn_blu'>{companyData.business_type}</Typography>
                        </Box>
                        <Box className="nAimg">
                            <img src='/images/n_img.svg' alt='' />
                            <img src='/images/a_img.svg' alt='' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="newPrnt">
                <Box className="sosilIcon">
                    <Box>
                        <Box className=" borde_rgt"><a href={companyData.website} target='_blank'><Box className="bg_img"><img src='/images/arth_ic.svg' alt='' /></Box></a></Box>
                    </Box>
                    <Box>
                        <a href={companyData.instagram} target='_blank'><Box className="bg_img"><img src='/images/inst_ic.svg' alt='' /></Box></a>
                    </Box>
                    <Box>
                        <a href={companyData.twitter} target='_blank'><Box className="bg_img"><img src='/images/twtr_ic.svg' alt='' /></Box></a>
                    </Box>
                    <Box>
                        <a href={companyData.linkedin} target='_blank'><Box className="bg_img"><img src='/images/likdy_ic.svg' alt='' /></Box></a>
                    </Box>
                </Box>
            </Box>
            <Box className="lasTxxt">
                <Typography>
                {companyData.description}
                </Typography>
            </Box>
        </Box>


        <Box className="tabsContMain">
            <Box sx={{ width: '100%' }} className="aaaaa">
                <Box className="tebsHed" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs className='tbHedInrr' value={value} onChange={handleOneChange} aria-label="basic tabs example">
                        <Tab className='tabsOnly' label="Funding Rounds" icon={<Box className="tabImgBg"><img src="/images/funding_rounds_ic.svg" alt='' /></Box>} {...a11yProps(0)} />
                        <Tab className='tabsOnly' label="Captable" icon={<Box className="tabImgBg"><img src="/images/captable_ic_tabs.svg" alt='' /></Box>} {...a11yProps(0)} />
                        <Tab className='tabsOnly' label="Summary Financials" icon={<Box className="tabImgBg"><img src="/images/rupys_ic.svg" alt='' /></Box>} {...a11yProps(0)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} className="mainTabPnl whitBgWhite">
                    {companyData.funding_ids &&
                    <>
                        <FundingRound
                            companyData={companyData}
                            />
                    </>
                    }
                </TabPanel>
                <TabPanel value={value} index={1} className="mainTabPnl whitBgWhite">
                    {companyData.funding_ids &&
                    <Captable
                        fundingData={companyData.funding_ids}
                        />
                    }
                </TabPanel>
                <TabPanel value={value} index={2} className="summary">
                    <SummaryFinancials
                        companyData={companyData}
                        type={value}
                        />
                </TabPanel>
            </Box>
        </Box>

    </Box>
    }

    <ProductsIndex />
    
    </>
    );
}



const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(CompanyView);