import React from 'react'

export default function Product(props) {
  return (
    <a target="_blank" href="https://bit.ly/43gpj4o" className='product'>
        <img src={`/img/${props.name}.JPEG`} alt='Product name here' />
        <div className='proInfo'>
            <div>
                <h3>Mulberry Silk T Shirt</h3>
                {/*<h4>Starts at ₹1,500</h4>*/}
            </div>
            <img src='/img/buyIcon.png' className='byic' alt='' />
        </div>
    </a>
  )
}
