import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom'

const Paginate = (props) => {

	return (
    <>
    	<Box className="pagntion">
            <ul className=''>
                <li>
                	{props.data.hasPrevPage &&
                    <Link 
                        to={`${props.path}?page=${props.data.prevPage}`} 
                        onClick={()=>props.handlePageChange(props.data.prevPage)}
                        > 
	                    <Button className='prwBtn twoBtnSemCss'>
                    		<img src='/images/last_link_arrow.svg' alt='' />Previous
                    	</Button>
                    </Link>
                	}
                </li>
                <Box className="btnBxFlx">
                    <li>
                        <Button>{props.data.page}</Button>
                    </li>
                    <li><span>of</span></li>
                    <li>
                        <Button>{props.data.totalPages}</Button>
                    </li>
                </Box>
                <li>
                	{props.data.hasNextPage &&
                    <Link 
                    	to={`${props.path}?page=${props.data.nextPage}`}
                    	onClick={()=>props.handlePageChange(props.data.nextPage)}
                    	>
                    	<Button className='nextBtn twoBtnSemCss'>Next<img src='/images/last_link_arrow.svg' alt='' /></Button>
                    </Link>
                    }
                </li>
            </ul>
        </Box>
    </>
    )
}

export default Paginate;