import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, Dialog, Table, TableCell, TableRow, TableFooter, TableContainer, TableHead, TableBody, DialogContent, FormControl, Select, MenuItem } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import Service from 'services';
import { useForm } from "react-hook-form";
import { imageURL } from 'env';


const FundingView = (props) => {
  
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [expanded, setExpanded] = useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      {props.fundingData.length && props.fundingData.map((row, index) => {
        let accordianPanel = 'panel' + index;

        let noShares = 0;
        let faceValuePerShare = 0;
        let faceValue = 0;
        let premiumPerShare = 0;
        let premium = 0;
        let amountReceived = 0;

        return (
        <>
          <Box className="moreCinfo" key={index}>
            {row.date &&
            <Box className="dateBox"><span />{moment(row.date).format("Do MMM YYYY")}</Box>
            }
            <Box className={`moreCinfoinn ${props.fundingData.length==index+1 ? 'bBordernone' : ''}`}>
                <Accordion expanded={expanded === accordianPanel} onChange={handleChange(accordianPanel)} className='asAccording'>
                    <Box className="accHeader">
                        <AccordionSummary className='acrdn_sumry'>
                            <img src='/images/accorn_arrow.svg' alt='' />
                            <Typography aria-controls="panel1d-content" id="panel1d-header">{row.name}</Typography>
                        </AccordionSummary>
                        {props.actions &&
                        <>
                            <Box className="two_btn_pernt">
                                <Button className='edit_btn' onClick={()=>props.handleClickOpenModal(row)}>Edit</Button>
                                <Button className='delete_btn' onClick={()=>props.deleteFunding(row._id)}>Delete</Button>
                            </Box>

                            <Button className='defBtn' onClick={() => props.handleClickOpenInvestorsModal(row)}>
                                <AddIcon /> Connect Investors
                            </Button>
                        </>
                        }
                    </Box>
                    
                    {row.investment_ids.length>0 &&
                    <AccordionDetails>
                        <Box className="tablBox">
                            <TableContainer component={Paper} className='tabl_contnr'>
                                <Table sx={{ minWidth: 1400 }} aria-label="customized table" className='tabl_pernt_main'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"><span>Names of Shares Holder</span></TableCell>
                                            <TableCell align="center">No Of Shares</TableCell>
                                            <TableCell align="center">Face Value per Share</TableCell>
                                            <TableCell align="center">Face Value</TableCell>
                                            <TableCell align="center">Premium per Share</TableCell>
                                            <TableCell align="center">Premium</TableCell>
                                            <TableCell align="center">Total Amount Received</TableCell>
                                            {props.actions && <TableCell align="center">Action</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='tabl_body'>
                                        {row.investment_ids.map((investment, i) => {
                                        
                                            noShares = noShares + Helper.checkIntValue(investment.shares)
                                            faceValuePerShare = faceValuePerShare + Helper.checkIntValue(row.face_value_per_share)
                                            faceValue = faceValue + Helper.checkIntValue(investment.face_value)
                                            premiumPerShare = premiumPerShare + Helper.checkIntValue(row.premium_per_share)
                                            premium = premium + Helper.checkIntValue(investment.premium)
                                            amountReceived = amountReceived + Helper.checkIntValue(investment.amount_received)
                                          
                                            const investor = investment.investor_id;
                                            if(investor){
                                            let investorName = investor.first_name+' '+investor.last_name;

                                          return (
                                            <TableRow key={i}>
                                                <TableCell align="center" className='padng-17'>
                                                    <Box className="imgAndText">
                                                        <Link to={Path.investorGetByIdAdmin(investor._id)} className='rtr_link'>
                                                            {investor.image && <img width="65" alt={investorName} src={`${imageURL}investor/${investor.image}`} />}
                                                            {!investor.image && <span alt={investorName} className="image-text">{Helper.name2Letter(investorName)}</span>}
                                                            {investorName}
                                                        </Link>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" className='sem_cssPernt padng-17'>{investment.shares}</TableCell>
                                                <TableCell align="center" className='sem_cssPernt padng-17'>{row.face_value_per_share}</TableCell>
                                                <TableCell align="center" className='sem_cssPernt padng-17'>{investment.face_value}</TableCell>
                                                <TableCell align="center" className='sem_cssPernt padng-17'>{row.premium_per_share}</TableCell>
                                                <TableCell align="center" className='sem_cssPernt padng-17'>{investment.premium}</TableCell>
                                                <TableCell align="center" className='sem_cssPernt padng-17'>{investment.amount_received}</TableCell>
                                                {props.actions &&
                                                <TableCell align="center" className=''>
                                                    <Box className="two_btn_pernt">
                                                        <Button className='edit_btn' onClick={()=>props.handleClickOpenInvestorsModal(row, investment)}>Edit</Button>
                                                        <Button className='delete_btn' onClick={()=>props.deleteInvestment(investment._id)}>Delete</Button>
                                                    </Box>
                                                </TableCell>
                                                }
                                            </TableRow>
                                          )
                                        }
                                        })}

                                    </TableBody>
                                    <TableFooter className='tabl_fotr'>
                                        <TableRow>
                                            <TableCell align="center" className=''><Box className="btnType">Total</Box></TableCell>
                                            <TableCell align="center" className='sem_cssPernt'>{noShares}</TableCell>
                                            <TableCell align="center" className='sem_cssPernt'>{/*{faceValuePerShare}*/}</TableCell>
                                            <TableCell align="center" className='sem_cssPernt'>{/*{faceValue}*/}</TableCell>
                                            <TableCell align="center" className='sem_cssPernt'>{/*{premiumPerShare}*/}</TableCell>
                                            <TableCell align="center" className='sem_cssPernt'>{/*{premium}*/}</TableCell>
                                            <TableCell align="center" className=''>
                                                <Box className="flxCntr"><img src='/images/grren_rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt fw-700'>{Helper.toLocale(amountReceived)}</span></Box>
                                            </TableCell>
                                            <TableCell align="center" className=''></TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </AccordionDetails>
                    }
                </Accordion>
            </Box>
        </Box>
        </>
        )
      })}

    </>
  );
}


const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(FundingView);