import React, { useState, useEffect } from 'react'
import { Box, Grid, Button, Dialog, DialogContent, Autocomplete, TextField, Typography
} from '@mui/material'

import { connect } from 'react-redux';
import Service from 'services';
import * as Path from 'routes/paths';
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import * as Helper from '_helpers/CommonFunctions';

const FinancialModal = (props) => {
	const parms = useParams();
	const yearsRange = Helper.DateRange();

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const [financialYearEdit, setFinancialYearEdit] = useState();

	const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm();

	useEffect(() => {
      if(props.financialEditData?._id){

      	reset({
          from_year: props.financialEditData.from_year,
        })

        const getIndex = checkYearsRange(props.financialEditData);
        setFinancialYearEdit(yearsRange[getIndex])
      }
    }, [props.financialEditData]);


    const checkYearsRange = (financial) => {
        const foundIndex = yearsRange.findIndex(row => {
            return row.value == financial.from_year;
        });

        return foundIndex;
    }



	const closeModal = () => {
		setFinancialYearEdit();
		reset();
		props.closeModal();
	}

	const onSubmit = async (values) => {
  	setLoading(true);

    try{
    	values['company_id'] = parms.id;
	    values['to_year'] = values.from_year + 1;

	    if(props.financialEditData?._id){
        let apiPath = Path.financialEditApi(props.financialEditData._id);
        await props.dispatch(Service.patchRequest(apiPath, values)).then((res) => {
            setLoading(false);
            props.alert('Financial Year Updated');
            props.getFinancials();
            closeModal()
        });
      }
      else {
    		await props.dispatch(Service.postRequest(Path.financialCreate, values)).then((res) => {
            setLoading(false);
            props.alert('Financial Year Created');
            props.getFinancials();
            closeModal();
        });
    	}

		}
		catch(err) {
			console.log(err);
			setLoading(false);
		}
  };

	return (
		<Dialog
	        open={props.openModal}
	        onClose={closeModal}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	        className='dialog_main_pernt'
	      >
	        <Box className='headin_hh'>
	          <Typography component="h1">{props.textAddEdit} Financial Year</Typography>
	        </Box>
	        <DialogContent className='main_content'>
	          <form onSubmit={handleSubmit(onSubmit)}>
		          <Grid container spacing={2}>
              	<Grid item xs={12} md={12}>
                      <Autocomplete
                          required
                          value={financialYearEdit?.value ? financialYearEdit : null}
                          options={yearsRange}
                          getOptionLabel={(option) => {
                            return option.label
                          }}
                          onChange={(e, data) => {
                              if(data?.value){
                                  setFinancialYearEdit(data);
                                  setValue('from_year', data.value, { shouldDirty: true })
                              }
                          }}
                          renderInput={(params) => <TextField 
                            {...params}
                            placeholder="Financial Year" 
                            className='input_input' 
                            required/>
                          }
                        />
                  </Grid>
		          </Grid>
	            
	            <Box className="last_content">
	            	<Button type="submit" className='def_btn mt-4'>{props.textAddEdit} Financial Year</Button>
	            </Box>
	            
	          </form>
	        </DialogContent>
	    </Dialog>
	)

}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(FinancialModal);