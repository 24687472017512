import React, {useState} from 'react';
import { createTheme, ThemeProvider, Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthService from 'services';
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

const Login = (props) => {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });

  

  function handleChange(e) {
    const key = e.target.name;
    const value =
    e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setFormState(formState => ({
      ...formState,
      [key]: value
    }));
  }



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try{
        await props.dispatch(AuthService.login(Path.authlogin, formState)).then((res) => {
            setLoading(false);
            setSuccess(true);
            props.alert("Welcome back!");
        });
      }catch(err) {
        setLoading(false);
        if(err && err.message){
            props.alert(err.message);
        }
      }
  };

  return (
    <>
      {success==true &&
          <Redirect to={Path.dashboard} />
      }
      <Helmet title="Login" />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm" className='login-sec'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#fff',
              boxShadow: '0px 0px 23px -17px grey',
              borderRadius: '10px',
              padding: '25px 45px',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={formState.email}
                onChange={handleChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formState.password}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    </>
  );
}


const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Login);