import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Button, Dialog, DialogContent, FormControl } from '@mui/material'

import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import Service from 'services';
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';
import { useForm } from "react-hook-form";
import Select from 'react-select';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { imageURL } from 'env';
import * as Helper from '_helpers/CommonFunctions';

const InvestorCreateEdit = (props) => {

  const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm();

  const [loading, setLoading] = useState(false);

  ///file upload
  const [selectedImage, setSelectedImage] = React.useState();
  const [selectedImageShow, setSelectedImageShow] = React.useState();

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      setSelectedImageShow(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
    setSelectedImageShow();
  };

  const investors = [
      { value: 'Angel', label: <><img src='/images/angel_investor_ic.svg' alt='' /> Angel</> },
      { value: 'Fund', label: <><img src='/images/fund_instr_ic.svg' alt='' /> Fund</> },
      { value: 'Founder', label: <><img src='/images/angel_investor_ic.svg' alt='' /> Founder</> },
  ]


  const [typeEdit, setTypeEdit] = useState();

  useEffect(() => {
      if(props.editInvestor._id){
        reset({
          image: props.editInvestor.image,
          first_name: props.editInvestor.first_name,
          last_name: props.editInvestor.last_name,
          type: props.editInvestor.type,
          linkedin: props.editInvestor.linkedin,
          description: props.editInvestor.description
        })

        const typeIndex = checkInvestor(props.editInvestor.type);
        
        setTypeEdit(investors[typeIndex])

        let imgPath = `${imageURL}investor/${props.editInvestor.image}`;

        setSelectedImageShow(imgPath);
      }
  }, [props.editInvestor]);

  const closeModal = () => {
    props.handleCloseModal();
    reset({
        image: '',
        first_name: '',
        last_name: '',
        linkedin: '',
        description: '',
      })
    removeSelectedImage();
    setTypeEdit()
  }

  const checkInvestor = (investorType) => {
    const foundTypeIndex = investors.findIndex(investor => {
      return investor.value == investorType;
    });

    return foundTypeIndex;
  }

  const onSubmit = async (values) => {
    setLoading(true);

    //console.log(values)
    try{
        let name = values.first_name+' '+values.last_name;
        values['slug'] = Helper.slugify(name);

        if(selectedImage){
          var formData = new FormData();
            formData.append("file", selectedImage);
            formData.append("type", 'investor');
            formData.append("name", values.slug);

            let fileData = await props.dispatch(Service.uploadProfile(Path.uploadFile, formData));

            if(fileData?.data?.name){
              values['image'] = fileData.data.name;
            }
        }

        
        if(props.editInvestor._id){
          const apiPath = Path.investorGetById(props.editInvestor._id);
          await props.dispatch(Service.patchRequest(apiPath, values)).then((res) => {
              setLoading(false);            
              props.alert('Investor Updated');

              closeModal();
          });
        }
        else {
          await props.dispatch(Service.postRequest(Path.investorCreate, values)).then((res) => {
              setLoading(false);            
              props.alert('Investor Create');

              closeModal();
          });
        }
      }catch(err) {
        console.log(err);
        setLoading(false);
      }
  };


  

  let textAddEdit = props.editInvestor._id ? 'Edit' : 'Add';
  return (
      <Dialog
          open={props.openModal}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='dialog_main_pernt invstrs_pernt'
      >
          <Box className='headin_hh'>
              <Typography component="h1">{textAddEdit} Investor</Typography>
          </Box>
          <DialogContent className='main_content'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className="frist_three_itms ">
                  <Box>
                      <Box className="fileUploadbx">
                          <Box className="fileUploadbxInn">
                              <input
                                  accept="image/*"
                                  type="file"
                                  onChange={imageChange}
                              />

                              {selectedImageShow && (
                                  <div className='thumbBox'>
                                      <img
                                          src={selectedImageShow}
                                          alt="Thumb"
                                          className='thumbImage'
                                      />
                                      <button className="removeButton" onClick={removeSelectedImage}>
                                          Remove This Image
                                      </button>
                                  </div>
                              )}
                              <Box className="cmrox">
                                  <CameraAltIcon />
                                  Choose A File
                              </Box>
                          </Box>
                          <Typography>
                              Dimension Max
                              <span>200 x 200 pixels</span>
                          </Typography>
                      </Box>
                      <FormControl className='form_control '>
                          <label className='hed_labl mb-10'>First Name</label>
                          <input type='text' placeholder='First Name' className='input_input'
                            {...register("first_name", { required: true })} />
                          {errors.first_name?.type === 'required' && <span className="alert">First Name is required</span>}
                      </FormControl>
                  </Box>
                  <Box component="form">
                      <FormControl className='form_control '>
                          <label className='hed_labl mb-10'>Last Name</label>
                          <input type='text' placeholder='Last Name' className='input_input'
                            {...register("last_name", { required: true })} />
                          {errors.last_name?.type === 'required' && <span className="alert">Last Name is required</span>}
                      </FormControl>
                  </Box>
                  <Box className="">
                      <FormControl className='select_pernt form_control'>
                          <label className='hed_labl mb-10'>Investor Type</label>
                          <Box className="customSearch formSearch">
                              <Select value={typeEdit} options={investors} classNamePrefix="cmpnSelect" placeholder="Investor Type" 
                                //{...register("type", { required: true })}
                                onChange={(e) => {
                                  setTypeEdit(e);
                                  setValue('type', e.value, { shouldDirty: true });
                                }}
                                required
                                />
                              <img src="/images/selectArrow.svg" alt='' className='searchIcon' />
                                {/*{errors.type?.type === 'required' && <span className="alert">Type is required</span>}
                          */}
                          </Box>
                      </FormControl>
                  </Box>
              </Box>

              <Box className="frist_three_itms margn_prnt">
                  <Box component="form">
                      <FormControl className='form_control mb-10'>
                          <label className='hed_labl mb-10'>Social Media</label>
                          <input type='text' placeholder='LinkedIn' className='input_input' 
                            {...register("linkedin", { required: true })}/>
                            {errors.linkedin?.type === 'required' && <span className="alert">Linkedin is required</span>}
                      </FormControl>
                  </Box>
              </Box>
              <Box className="last_content">
                  <Box className="textarea_pernt">
                    <textarea className='text_aria' rows="3" cols="50" placeholder='Description'
                      {...register("description", { required: true })}></textarea>
                        {errors.description?.type === 'required' && <span className="alert">Description is required</span>}
                    <img src='/images/textarea_ic.svg' alt='' className='textarea_ic' />
                  </Box>
                  
                  <Button type="submit" className='def_btn'>{textAddEdit} Investor</Button>
              </Box>
            </form>
          </DialogContent>
      </Dialog>
  );
}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(InvestorCreateEdit);