import React, { useState, useEffect } from "react";
import { Box, Typography, Button } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Select from 'react-select';
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import Service from 'services';
import DataTable from '_loaders/DataTable';
import { imageURL } from 'env';

import InvestorList from './InvestorList';
import InvestorCreateEdit from './InvestorCreateEdit';
import Swal from 'sweetalert2'

const InvestorIndex = (props) => {
  const history = useHistory();
  let parmSearch = window.location.search;
  let params = new URLSearchParams(parmSearch);
  const [page, setPage] = useState(params.get('page') ? parseInt("" + params.get('page')) : 1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [filter, setFilter] = useState(false);
  const [investorsData, setInvestorsData] = useState([]);
  const [editInvestor, setEditInvestor] = useState({});
  const [filteredValue, setFilteredValue] = useState('');
  
  const [openModal, setOpenModal] = useState(false);
  
  const handleClickOpen = (id=null) => {
    if(id)
      getInvestorbyID(id);

    setOpenModal(true);
  };
  
  const handleCloseModal = () => {
    setOpenModal(false);
    setEditInvestor({});
    getInvestors();
  };  

  useEffect(() => {
      getInvestors();
  }, [page]);

  const handlePageChange = (p) => {
    setPage(p);
  };

  const handleKeyDownSearch = (event) => {
      if (event.key === 'Enter') {
          getInvestors();
      }
  };


  const getInvestors = async () => {
    setLoading(true);

      try{
          const apiPath = Path.investorIndex + `?search=${search}&limit=${limit}&page=${page}`;
        
          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setInvestorsData(res);
            setLoading(false);
            setSuccess(true);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
  };

  
  const getInvestorbyID = (id) => {
    const foundInvestor = investorsData.docs.find(investor => {
      return investor._id === id;
    });

    if(foundInvestor)
      setEditInvestor(foundInvestor);
  };


  const deleteInvestor = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        try{
          const apiPath = Path.investorGetById(id);
          props.dispatch(Service.deleteRequest(apiPath)).then((res) => {
              getInvestors();

              Swal.fire(
                'Deleted!',
                'Investor deleted.',
                'success'
              )
          });
        } catch(err) {
            console.log(err);
            setLoading(false);
            if(err && err.data && err.data.msg){
              props.swal("Oops!", err.data.msg, "error");
            }
        }
      }
    })
  };

  
  return (
    <>
      <Helmet title="List Investors" />
      <Box className="invsts_pageMain">
          <Box className="mainPage">
              <ul className='breadcrumb'>
                  <li>
                      <Link to={Path.dashboard}><HomeIcon /></Link>
                  </li>
                  <li>
                      <ChevronRightIcon />
                  </li>
                  <li>
                      <span>Investors</span>
                  </li>
              </ul>
              <Typography component="h3" className='defTitle'>List Of Investors</Typography>
              <Box className="topSearch">
                  <Box className="customSearch">
                      <Box className="iSearch">
                        <input type='text' 
                          placeholder='Search Investors'
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          onKeyDown={handleKeyDownSearch}
                          />
                      </Box>
                      <SearchIcon className='searchIcon' />
                  </Box>
                  <Button className='defBtn' onClick={handleClickOpen}>
                      <AddIcon /> Add Investor
                  </Button>
              </Box>
              <Box className="contentBox mb-40">
              
                  <InvestorList
                    investorsData={investorsData}
                    handleClickOpen={handleClickOpen}
                    deleteInvestor={deleteInvestor}
                    handlePageChange={handlePageChange}
                    />

              </Box>
          </Box>
      </Box>

      <InvestorCreateEdit
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        editInvestor={editInvestor}
        alert={props.alert}
        />
      
    </>
  );
}



const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(InvestorIndex);