import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from '@mui/material'

const White = props => (
    <Grid item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
        <Box className="boxPrntMain">
            <Box className='compnyLinkAj'>
                <Box className="imgSide">
                    <Skeleton variant="rounded" width={90} height={90} />
                    <Box className="sosilIcon">
                        <Box>
                            <Skeleton variant="circular" width={17} height={17} />
                        </Box>
                        <Box>
                            <Skeleton variant="circular" width={17} height={17} />
                        </Box>
                        <Box>
                            <Skeleton variant="circular" width={17} height={17} />
                        </Box>
                    </Box>
                </Box>
                <Box className="ritSide">
                    <Box className="btnTwoImg">
                        <Skeleton variant="rounded" width={40} height={18} sx={{ marginRight: '20px' }}/>
                        <Skeleton variant="text" width={18} height={30} sx={{ marginRight: '8px' }} />
                        <Skeleton variant="text" width={18} height={30} />
                    </Box>
                    
                        <Skeleton variant="text" height={40} sx={{ width: '100%', marginBottom: '29px' }} />
                    
                    <Box className="btnTwoImg">
                        <Skeleton variant="circular" width={20} height={17} sx={{ marginRight: '10px' }}/>
                        <Skeleton variant="rounded" width="100%" height={25} />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Grid>
)


export default White