import Skeleton from '@mui/material/Skeleton';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material'

const SummarySkeleton = props => (
    <Box className="mainTabPnl whitBgWhite tabs_Cptble">
        <Box className="bigImgSc">
            <Box className="only_imgInBig skeleCart flxCntr ">
                <Skeleton width={800} height={600} />
            </Box>
            <Box className="innrTblCont">
                <Box className="tablBox">
                    <TableContainer component={Paper} className='tabl_contnr hoveredTable'>
                        <Table sx={{ minWidth: 970 }} aria-label="customized table" className='tabl_pernt_main'>
                            <TableBody className='tabl_body'>
                                <TableRow>
                                    <TableCell colSpan={4} align="center" className=''>
                                        <Skeleton variant="rounded" width="100%" height={30} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} align="center" className=''>
                                        <Skeleton variant="rounded" width="100%" height={30} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} align="center" className=''>
                                        <Skeleton variant="rounded" width="100%" height={30} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} align="center" className=''>
                                        <Skeleton variant="rounded" width="100%" height={30} />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={4} align="center" className=''>
                                        <Skeleton variant="rounded" width="100%" height={30} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    </Box>
)


export default SummarySkeleton