import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { Box, Typography, Grid, Button, Tabs, Tab } from '@mui/material'
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { useParams, Link } from "react-router-dom";
import Service from 'services';
import * as Path from 'routes/paths';
import ViewLoader from '_loaders/ViewLoader';

import FundingView from 'components/_common/FundingView';
import CompanyFunding from './CompanyFunding';
import CompanyInvestment from './CompanyInvestment';

import SummaryFinancials from './details/SummaryFinancials';

import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import Swal from 'sweetalert2'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CompanyView = (props) => {

    const parms = useParams();
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [fundingEdit, setFundingEdit] = useState({});
    const [investorData, setInvestorData] = useState([]);
    const [investorOptions, setInvestorOptions] = useState([]);
    const [investorEdit, setInvestorEdit] = useState({});
    const [fundingRound, setFundingRound] = useState({});


    const [value, setValue] = useState(0);

    const handleOneChange = (event, newValue) => {
        setValue(newValue);
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const [openModal, setOpenModal] = useState(false);
    const handleClickOpenModal = (funding=null) => {
        if(funding)
          setFundingEdit(funding)

        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setFundingEdit();
    };

    const [openInvestorsModal, setOpenInvestorsModal] = useState(false);
  
    const handleClickOpenInvestorsModal = (funding, investment=null) => {
        if(investment)
          setInvestorEdit(investment)

        setOpenInvestorsModal(true);
        setFundingRound(funding);
    };

    const handleCloseInvestorsModal = () => {
        setOpenInvestorsModal(false);
        setFundingRound({});
        setInvestorEdit({});
    };


    useEffect(() => {
      getCompany();
      getInvestors();
    }, []);

    const getCompany = async () => {
      setLoading(true);

      try{
          const apiPath = `${Path.companyGetById(parms.id)}?populate=yes`;

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setCompanyData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };
    
    const getInvestors = async () => {
        setLoading(true);

        try {
            const apiPath = `${Path.investorIndex}?noLimit=yes&populate=no`;
            await props.dispatch(Service.getRequest(apiPath)).then((res) => {
                setInvestorData(res)
                optionsInvestors(res)
                setLoading(false);
            });
        } catch (err) {
            console.log(err);
            setLoading(false);
            if (err && err.data && err.data.msg) {
                //swal("Oops!", err.data.msg, "error");
            }
        }
    };

    function optionsInvestors(investorData){
        let optionArray = [];
        {investorData.length>0 && investorData.map((investor, index) => {
            let option = {};
            option['value'] = investor._id;
            option['label'] = investor.first_name+' '+investor.last_name;
            optionArray.push(option);
        })}
        setInvestorOptions(optionArray);
    }


  const deleteFunding = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        try{
          const apiPath = Path.fundingGetByIdApi(id);
          props.dispatch(Service.deleteRequest(apiPath)).then((res) => {
              getCompany();

              Swal.fire(
                'Deleted!',
                'Funding deleted.',
                'success'
              )
          });
        } catch(err) {
            console.log(err);
            setLoading(false);
            if(err && err.data && err.data.msg){
              props.swal("Oops!", err.data.msg, "error");
            }
        }
      }
    })
  };

  const deleteInvestment = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        try{
          const apiPath = Path.investmentGetById(id);
          props.dispatch(Service.deleteRequest(apiPath)).then((res) => {
              getCompany();

              Swal.fire(
                'Deleted!',
                'Investment deleted.',
                'success'
              )
          });
        } catch(err) {
            console.log(err);
            setLoading(false);
            if(err && err.data && err.data.msg){
              props.swal("Oops!", err.data.msg, "error");
            }
        }
      }
    })
  };



    return (
      <>
        <Box className="Copny_dtl_page">
          <Box className="mainPage pt_ziro">
              <ul className='breadcrumb'>
                  <li>
                      <Link to="/"><HomeIcon /></Link>
                  </li>
                  <li>
                      <ChevronRightIcon />
                  </li>
                  <li>
                      <Link to={Path.companyAdmin}>Companies</Link>
                  </li>
                  <li>
                      <ChevronRightIcon />
                  </li>
                  <li>
                      <span>Details Of Company</span>
                  </li>
              </ul>
              <Typography component="h3" className='defTitle'>Details Of Company <br className='d_none' /><span className='green_text'>{companyData.website}</span> </Typography>
              <Box className="fdig_runds_cont">
                  <Typography component="h6" className='fd_hed'>List Of Funding Rounds</Typography>
                  <Box className="bordBox">
                      <Box className="up_content">
                          <Box className="btn_type">
                              <img src='/images/accorn_arrow.svg' alt='' />
                              <span>{companyData.website}</span>
                          </Box>
                          <Typography component="h1">Profile</Typography>
                      </Box>
                      <Box className="duwn_cont">
                          <Grid container spacing={2}>
                              <Grid item xs={12} md={8}>
                                  <Box className="flex_box">
                                      <Box className="logoPenrt">
                                          {companyData.image && <img alt={companyData.legal_name} src={`${imageURL}company/${companyData.image}`} />}
                                          {!companyData.image && <span alt={companyData.legal_name} className="image-text">{Helper.name2Letter(companyData.legal_name)}</span>}
                                      </Box>
                                      <Box className="side_content">
                                          <Grid container spacing={2}>
                                              <Grid item xs={12} sm={6} lg={6} md={6}>
                                                  <Box className="first_grid_cont">
                                                      <Typography className='def_pera'>Legal Name</Typography>
                                                      <Typography className='def_pera fw_500 mt_pernt'>{companyData.legal_name}</Typography>
                                                      <Typography className='def_pera'>Business Type</Typography>
                                                      <Typography className='def_pera fw_500'>{companyData.business_type}</Typography>
                                                  </Box>
                                              </Grid>
                                              <Grid item xs={12} sm={6} lg={6} md={6}>
                                                  <Box className="Web_site">
                                                      <Typography className='def_pera'>Website</Typography>
                                                      <a href={companyData.website} target='_blank' className='simpl_link'>
                                                          {companyData.website}
                                                          <img src='/images/Pubcomp_ic.svg' alt='' />
                                                      </a>
                                                  </Box>
                                                  <Box className="Scil_Mdia">
                                                      <Typography className='def_pera'>Social Media</Typography>
                                                      <a href='/' target='_blank' className='sol_mdiy_link'>
                                                          <img src='/images/beaconinkedin_ic.svg' alt='' className='first_img' />
                                                          {companyData.linkedin}
                                                          <img src='/images/Pubcomp_ic_green.svg' alt='' className='last_img' />
                                                      </a>
                                                      <a href='/' target='_blank' className='sol_mdiy_link'>
                                                          <img src='/images/beacoinInstagram_ic.svg' alt='' className='first_img' />
                                                          {companyData.instagram}
                                                          <img src='/images/Pubcomp_ic_green.svg' alt='' className='last_img' />
                                                      </a>
                                                      <a href='/' target='_blank' className='sol_mdiy_link'>
                                                          <img src='/images/beacontwitter_ic.svg' alt='' className='first_img' />
                                                          {companyData.twitter}
                                                          <img src='/images/Pubcomp_ic_green.svg' alt='' className='last_img' />
                                                      </a>
                                                  </Box>
                                              </Grid>
                                          </Grid>
                                      </Box>
                                  </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                  <Box className="Dscrpin_cont">
                                      <Typography className='def_pera'>Description</Typography>
                                      <Box className="border_box">
                                          <Typography>{companyData.description}</Typography>
                                      </Box>
                                  </Box>
                              </Grid>
                          </Grid>
                      </Box>
                  </Box>
              </Box>



              <Box className="tabsContMain">
                <Box sx={{ width: '100%' }} className="aaaaa">
                    <Box className="tebsHed" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs className='tbHedInrr' value={value} onChange={handleOneChange} aria-label="basic tabs example">
                            <Tab className='tabsOnly flxCntr' label="Funding Rounds" icon={<Box className="tabImgBg"><img src="/images/funding_rounds_ic.svg" alt='' /></Box>} {...a11yProps(0)} />
                            <Tab className='tabsOnly flxCntr' label="Summary Financials" icon={<Box className="tabImgBg"><img src='/images/rupys_ic.svg' alt='' /></Box>} {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} className="mainTabPnl whitBgWhite">

                      <Box className="forBtnBox">
                          <Button className='defBtn' onClick={handleClickOpenModal}>
                              <AddIcon /> Add Funding Rounds
                          </Button>
                      </Box>

                      {companyData.funding_ids &&
                      <FundingView
                        fundingData={companyData.funding_ids}
                        handleClickOpenModal={handleClickOpenModal}
                        handleClickOpenInvestorsModal={handleClickOpenInvestorsModal}
                        alert={props.alert}
                        deleteFunding={deleteFunding}
                        deleteInvestment={deleteInvestment}
                        actions={true}
                        />
                      }

                    </TabPanel>
                    <TabPanel value={value} index={1} className="mainTabPnl whitBgWhite">
                        <SummaryFinancials 
                          companyData={companyData}
                          alert={props.alert}
                          />
                    </TabPanel>
                </Box>
            </Box>

          </Box>
        </Box>

        <CompanyFunding
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          alert={props.alert}
          companyData={companyData}
          getCompany={getCompany}
          fundingEdit={fundingEdit}
          />

        <CompanyInvestment
          openInvestorsModal={openInvestorsModal}
          handleCloseInvestorsModal={handleCloseInvestorsModal}
          alert={props.alert}
          investorOptions={investorOptions}
          //investorData={investorData}
          fundingRound={fundingRound}
          getCompany={getCompany}
          investorEdit={investorEdit}
          companyData={companyData}
          />
      </>
        
    );
}



const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(CompanyView);