import { Box, Grid, Typography, AvatarGroup, Avatar, Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton';

import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import Paginate from 'components/_common/Paginate';

export default function ListView(props) {
    return (
        <>
            <Box className="srolWidth whitBgWhite">
                <Box className="list_vwMain">
                    <Box className="scrolPernt">
                        
                        {props.companiesData.docs && props.companiesData.docs.map((row, index) => {
                        return (
                        <Box className="margnPernt" key={index}>
                            <Box className={'thisBoxPrnt brdr_Bt '+(index%2 ? '':'oneListBg')}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className='centrAllCont'>
                                        {row.image && <img className="image-40" alt={row.legal_name} src={`${imageURL}company/${row.image}`} />}
                                        {!row.image && <span alt={row.legal_name} className="image-text">{Helper.name2Letter(row.legal_name)}</span>}
                                    </Grid>
                                    <Grid item xs={2} className='centrAllCont'>
                                        <Box className="Txt_imgBx">
                                            <Link to={Path.companyGetById(row._id, row.slug)}>
                                                <Typography>{row.legal_name}</Typography>
                                            </Link>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className='centrAllCont'>
                                        <Box className="btnPertt">
                                            <Typography className='def_btncss bgrn_blu'>{row.business_type}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className='centrAllCont'>
                                        <Box className="bg_img">
                                            <a href={row.website} target="_blank">
                                                <img src='/images/arth_ic.svg' alt='' />
                                            </a>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} className='centrAllCont'>
                                        <Box className="sosilIcon">
                                            <Box className="bg_img">
                                                <a href={row.instagram} target="_blank">
                                                    <img src='/images/inst_ic.svg' alt='' />
                                                </a>
                                            </Box>
                                            <Box className="bg_img">
                                                <a href={row.twitter} target="_blank">
                                                    <img src='/images/twtr_ic.svg' alt='' />
                                                </a>
                                            </Box>
                                            <Box className="bg_img">
                                                <a href={row.linkedin} target="_blank">
                                                    <img src='/images/likdy_ic.svg' alt='' />
                                                </a>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} className='centrAllCont'>
                                        <Box className="">
                                            {row.investors.length > 0 &&
                                            <Box className="avtar_perntA2">
                                                <AvatarGroup total={row.investors.length} className='avtr_grup'>
                                                    {row.investors.map((investor, i) => {
                                                    let name = investor.first_name +' '+ investor.last_name;
                                                    return (
                                                        <Avatar className='avtr_pp' key={i} alt={name} src={`${imageURL}investor/${investor.image}`} />
                                                    )
                                                    })}
                                                </AvatarGroup>
                                            </Box>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className='centrAllCont'>
                                        <Box className="nAimg">
                                            <img src='/images/n_img.svg' alt='' />
                                            <img src='/images/a_img.svg' alt='' />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className='centrAllCont'>
                                        <Box className="">
                                            <Link to={Path.companyGetById(row._id, row.slug)} className='lastLink'>
                                                <img src='/images/last_link_arrow.svg' alt='' />
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        )
                        })}
                        
                    </Box>
                </Box>
            </Box>
            

            <Paginate
                data={props.companiesData}
                handlePageChange={props.handlePageChange}
                path={Path.companies}
                />


        </>
    )
}
