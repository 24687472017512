import React, { useEffect, useState } from "react";
import Helmet from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  IconButton,
  Button
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { Link } from "react-router-dom";
import * as Path from 'routes/paths';

//import Header from './Header';
import Sidebar from './Sidebar';
import 'assets/css/Admin.scss';

const drawerWidth = 245;

const Admin = (props) => {

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div className='mainSidebar'>
            <Link to={Path.dashboard} className="logo">
                <img src='/images/logo.svg' alt='' />
            </Link>

            <Sidebar />
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;
    const [collapsOpen, setCollapsOpen] = useState(false);
    
    const handleCollapsToggle = () => {
        setCollapsOpen(!collapsOpen);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        document.body.classList.add('admin-pages')
        document.body.classList.remove('front-pages')
        
        //scrollToTop();
    }, [])

    function alert(message) {
        toast(message);
    }

    return (
        <div className="">

            <ToastContainer />
            <Helmet titleTemplate={`%s | The Balance Sheet`} />

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                    width: { lg: `calc(100% - 290px)` },
                    ml: { lg: `290px` },
                    }}
                    className="mobileHeader"
                    >

                    <Toolbar>
                        <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Link to={Path.dashboard} className="logoV2">
                            <img src='/images/logo.svg' alt='' />
                        </Link>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { lg: "290px" }, flexShrink: { lg: 0 } }}
                    aria-label="mailbox folders"
                    className={collapsOpen ? "topNavbar hide" : "topNavbar"}
                    >
                    <Button className='cllpsBtn' onClick={handleCollapsToggle}><FirstPageIcon /></Button>

                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        className='mainDrawer'
                        >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        className='mainDrawer'
                        sx={{
                        display: { xs: 'none', lg: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                        >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { lg: `calc(100% - ${drawerWidth}px)` } }}
                    className='mainBox'
                    >
                    
                    {React.cloneElement(props.children, {alert})}

                </Box>
            </Box>
        </div>
    );
}


Admin.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Admin;

