import React, { useState } from 'react'
import {
    Box
} from '@mui/material';
import ChartData from './ChartData';

export default function CapitalGains(props) {

	
	

    return (
        <>
        {props.companyGainData.length>0 && props.companyGainData.map((company, index) => {
	        return (
		        <Box className="mainTabPnl whitBgWhite">
		    		<Box className="contentBox" sx={{ overflow:"hidden" }}>
		    			<ChartData
					        company={company}
					        />
		        	</Box>
		        </Box>
	        )
        })}
        </>
    )
}