import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from '@mui/material'
import { Link } from "react-router-dom";

import WhiteCard from './cards_home/White';
import GreenCard from './cards_home/Green';

const HomeCardSkeleton = props => (	
	<Box className="Cmpnis_cont">
	    <Box className="hed_brdr">
	        <Skeleton variant="text" sx={{ fontSize: '32px', width: '100%' }} />
	    </Box>
	    <Box className="gridCont">
	        <Grid container spacing={2}>
	            <WhiteCard />
	            <WhiteCard />
	            <WhiteCard />
	            <WhiteCard />

	            <WhiteCard />
	            <WhiteCard />
	            <WhiteCard />
	            <GreenCard />
	        </Grid>
	    </Box>
	</Box>
)


export default HomeCardSkeleton