import React, { useState, useEffect } from 'react'
import { Box, Grid, Button, Dialog, Autocomplete, TextField, DialogContent, FormControl, Typography
} from '@mui/material'

import { connect } from 'react-redux';
import Service from 'services';
import * as Path from 'routes/paths';
import { useForm } from "react-hook-form";

const ItemModal = (props) => {

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [itemTitleOptions, setItemTitleOptions] = useState([]);
	const [financialItemTitleSelected, setFinancialItemTitleSelected] = useState({});


	const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm();

	useEffect(() => {
    if(props.financialTitleData.length > 0){
    	financialItemTitleArray(props.financialTitleData);
    }
  }, [props.financialTitleData, props.openModal]);

	useEffect(() => {
    if(props.financialItemValueEditData?._id){

    	reset({
    		company_financial_title_id: props.financialItemEditData.company_financial_title_id,
    		financialItemTitleDataId: props.financialItemEditData.title_id._id,
        value: props.financialItemValueEditData.value,
      })

    	const getIndex = checkTitleOptions(props.financialItemEditData.title_id);
      setFinancialItemTitleSelected(itemTitleOptions[getIndex]);
    }
  }, [props.financialItemEditData, props.financialItemValueEditData, itemTitleOptions]);


	const checkTitleOptions = (data) => {
      const foundIndex = itemTitleOptions.findIndex(row => {
          return row.value == data._id;
      });

      return foundIndex;
  }

	const financialItemTitleArray = (titles) => {
  	let titleArray = [];

  	for(const row of titles){
  		if(row._id === props.financialRawTitleId){
		  	for(const item of row.financial_item_title_ids){
		  		let tArray = [];
		  			tArray['value'] = item._id;
		  			tArray['label'] = item.title;
		  			titleArray.push(tArray);
		  	}
		  }
	  }

  	setItemTitleOptions(titleArray);
  }

	const closeModal = () => {
			setLoading(false);
		  setFinancialItemTitleSelected({});
      reset({
          financialItemTitleDataId: '',
          company_financial_title_id: '',
          title: '',
          value: ''
      })
      props.getFinancials();
      props.getFinancialsTitle();
      props.setFinancialRawTitleId();
      props.closeModal();
  }

	const onSubmit = async (values) => {
    	setLoading(true);

	    try{

	    	/*if(values['value'] == 0){
	    		throw ('Value not equal to 0.');
	    	}*/

	    	let createValues = {};
	    	createValues['company_financial_title_id'] = props.financialTitleId;
	    	createValues['value'] = values['value'];
	    	
	    	if(values['title'] && values['title']!=''){
	    		let createValues1 = {};
	    		createValues1['title'] = values['title'];
	    		createValues1['financial_title_id'] = props.financialRawTitleId;

		    	await props.dispatch(Service.postRequest(Path.financialItemTitleCreate, createValues1)).then((res) => {
		            setLoading(false);
		            createValues['title_id'] = res._id;
		        });
		    }
		    else {
		    	createValues['title_id'] = values.financialItemTitleDataId;
		    }


	    	

	    	if(props.financialItemValueEditData?._id){
	        let apiPath = Path.financialItemEditApi(props.financialItemValueEditData._id);
	        await props.dispatch(Service.patchRequest(apiPath, createValues)).then((res) => {
	            props.alert('Financial Item Updated');
	            closeModal();
	        });
	      }
	      else {
		    	await props.dispatch(Service.postRequest(Path.financialCreateItem, createValues)).then((res) => {
		            props.alert('Financial Item Created');
		            closeModal();
		        });
		    }
		}
		catch(err) {
			console.log(err);
			props.alert(err)
			setLoading(false);
		}
  };

	return (
		<Dialog
	        open={props.openModal}
	        onClose={closeModal}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	        className='dialog_main_pernt'
	      >
	        <Box className='headin_hh'>
	          <Typography component="h1">{props.textAddEdit} Item</Typography>
	        </Box>
	        <DialogContent className='main_content'>
	          <form onSubmit={handleSubmit(onSubmit)}>
	          	<Grid container spacing={2}>
	            	<Grid item xs={12} md={12}>
	            				{itemTitleOptions.length>0 &&
                    	<>
                    		<label className='hed_labl mb-10'>Select Item Line</label>
                    		<Autocomplete
                          value={financialItemTitleSelected?.value ? financialItemTitleSelected : null}
                          options={itemTitleOptions}
                          getOptionLabel={(option) => {
                            return option.label
                          }}
                          onChange={(e, data) => {
                              if(data?.value){
                                  setFinancialItemTitleSelected(data);
                                  setValue('financialItemTitleDataId', data.value, { shouldDirty: true })
                                  setValue('title', '', { shouldDirty: true })
                              		
                              }
                          }}
                          renderInput={(params) => <TextField 
                            {...params}
                            placeholder="Select Item Line" 
                            className='input_input' 
                            required={itemTitleOptions.length>0 ? false : true}/>
                          }
                        />
                      </>
                    	}

	                    <FormControl className='form_control mt-10'>
                          <label className='hed_labl mb-10'>{itemTitleOptions.length>0 ? 'Or ' : ''}Create New Item Line</label>
                          <input type='text' placeholder='Item Name' className='input_input'
                            {...register("title", {
                            	required: itemTitleOptions.length>0 ? false : true,
                            	disabled: financialItemTitleSelected?.value ? true : false,
                            })}/>
                          {errors.title?.type === 'required' && <span className="alert">Item Name is required</span>}
                      	</FormControl>
	                </Grid>
	                <Grid item xs={12} md={12}>
	                    <FormControl className='form_control '>
                          <label className='hed_labl mb-10'>Value</label>
                          <input type='text' placeholder='Value' className='input_input'
                            {...register("value", { required: true })} />
                          {errors.value?.type === 'required' && <span className="alert">Value is required</span>}
                      	</FormControl>
	                </Grid>
	          	</Grid>
	            
	            <Box className="last_content">
	            	<Button type="submit" className='def_btn mt-4'>{props.textAddEdit} Item</Button>
	            </Box>
	            
	          </form>
	        </DialogContent>
	    </Dialog>
	)

}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ItemModal);