import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
    Box,
    Tab,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Collapse,
    AvatarGroup,
    Avatar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TableFooter,
    Paper
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import moment from 'moment';

export default function Portfolio(props) {

    const companyData = props.companyData;
    const [open, setOpen] = useState('');//tab0
    const [expanded, setExpanded] = useState('');//panel0
    const [accordianPanel, setAccordianPanel] = useState({});

    useEffect(() => {
      getAccordianPanel();
    }, [companyData]);

    const getAccordianPanel = () => {
        let panel = {};
        companyData.forEach((company, index) => {
            let toogle = 'tab' + index;
            let accordian = {};
            if(company.funding_ids.length>0){
                company.funding_ids.forEach((row, i) => {
                    let panelA = 'panel' + i;
                    accordian[panelA] = panelA;
                });
            }

            panel[toogle] = accordian;
        });

        setAccordianPanel(panel);
    };

    const handleChange = (tab, panel) => {
        setAccordianPanel(current => {
            const copy = {...current};

            if(copy[tab][panel]){
                delete copy[tab][panel];
            }
            else {
                copy[tab][panel] = panel;
            }
            return copy;
        });
    };

    const handleChangeTab = (toogle) => {
        setOpen(open == toogle ? '' : toogle)
        getAccordianPanel();
    };


    return (
        <>
    	<Box className="contentBox">
            <TableContainer component={Paper} className='tabl_contnr'>
                <Table aria-label="collapsible table" className='tabl_pernt_main tabl_pernt_mainV2'>
                	<TableBody className='tabl_body'>
                        <React.Fragment>
                            {companyData.length>0 && companyData.map((company, index) => {
                            let toogle = 'tab'+index;
                            return (
                            <>
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={index}>
                                <TableCell >
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleChangeTab(toogle)}
                                        className='accron_btn'
                                    >
                                        {open==toogle ? <Box className="pls_mns_ic_bg"><RemoveIcon className='remove_icon' /></Box> : <Box className=""><AddIcon className='add_icon' /></Box>}
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <Box className='centrAllCont'>
                                        <Box className="Txt_imgBx">
                                            {company.image && <Avatar alt={company.legal_name} src={`${imageURL}company/${company.image}`} />}
                                            {!company.image && <span alt={company.legal_name} className="image-text">{Helper.name2Letter(company.legal_name)}</span>}
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box>
                                        <Link to={Path.companyGetById(company._id, company.slug)} className="Txt_imgBx">
                                            <Typography>{company.legal_name}</Typography>
                                        </Link>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" className=''>
                                    <Box className='centrAllCont'>
                                        <Box className="btnPertt">
                                            <Typography className='def_btncss bgrn_blu'>{company.business_type}</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>

                                <TableCell align="center">
                                    <Box className='centrAllCont'>
                                        <Box className="bg_img">
                                            <a href={company.website} target='_blank' className='a_dFlxx' ><img src='/images/arth_ic.svg' alt='' /></a>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Box className='centrAllCont'>
                                        <Box className="sosilIcon">
                                            <a href={company.linkedin} target='_blank' className='a_dFlxx' ><Box className="bg_img"><img src='/images/likdy_ic.svg' alt='' /></Box></a>
                                            <a href={company.instagram} target='_blank' className='a_dFlxx' ><Box className="bg_img"><img src='/images/inst_ic.svg' alt='' /></Box></a>
                                            <a href={company.twitter} target='_blank' className='a_dFlxx' ><Box className="bg_img"><img src='/images/twtr_ic.svg' alt='' /></Box></a>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell align="center">
                                    <Box className='centrAllCont'>
                                        <Box className="nAimg">
                                            <img src='/images/n_img.svg' alt='' />
                                            <img src='/images/a_img.svg' alt='' />
                                        </Box>
                                    </Box>
                                </TableCell>
                            </TableRow>


                            {company.funding_ids.length>0 &&
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                                    <Collapse in={open==toogle} timeout="auto" unmountOnExit>
                                        <Box className="">
                                            
                                            {company.funding_ids.map((row, i) => {
                                            let panel = 'panel' + i;

                                            let noShares = 0;
                                            let faceValuePerShare = 0;
                                            let faceValue = 0;
                                            let premiumPerShare = 0;
                                            let premium = 0;
                                            let amountReceived = 0;

                                            return (
                                            <>

                                            {//row.investment_ids.length>0 &&
                                            <Box className="moreCinfo" key={i}>
                                                <Box className="dateBox"><img src='/images/clarity_date_ic.svg' alt='' className='clarity_date_ic' /> {moment(row.date).format("Do MMM YYYY")}</Box>
                                                <Box className={`moreCinfoinn ${company.funding_ids.length==i+1 ? 'aftrDnon' : ''}`}>
                                                    <Accordion expanded={(accordianPanel[toogle] && accordianPanel[toogle][panel]) ? panel===accordianPanel[toogle][panel] : false} onChange={()=>handleChange(toogle, panel)} className='asAccording'>
                                                        <Box className="accHeader">
                                                            <Box>
                                                                <AccordionSummary className='acrdn_sumry'>
                                                                    <img src='/images/accorn_arrow.svg' alt='' />
                                                                    <Typography aria-controls="panel1d-content" id="panel1d-header">{row.name}</Typography>
                                                                </AccordionSummary>
                                                            </Box>
                                                        </Box>
                                                        <AccordionDetails>
                                                            <Box className="tablBox">
                                                                <TableContainer component={Paper} className='tabl_contnr hoveredTable'>
                                                                    <Table sx={{ minWidth: 970 }} aria-label="customized table" className='tabl_pernt_main'>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell align="center">Name</TableCell>
                                                                                <TableCell align="center">No Of Shares</TableCell>
                                                                                <TableCell align="center">Face Value per Share</TableCell>
                                                                                <TableCell align="center">Premium per Share</TableCell>
                                                                                <TableCell align="center">Total Amount Received</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody className='tabl_body'>
                                                                        {row.investment_ids.map((investment, i) => {
                                                                        
                                                                            noShares = noShares + Helper.checkIntValue(investment.shares)
                                                                            faceValuePerShare = faceValuePerShare + Helper.checkIntValue(row.face_value_per_share)
                                                                            faceValue = faceValue + Helper.checkIntValue(investment.face_value)
                                                                            premiumPerShare = premiumPerShare + Helper.checkIntValue(row.premium_per_share)
                                                                            premium = premium + Helper.checkIntValue(investment.premium)
                                                                            amountReceived = amountReceived + Helper.checkIntValue(investment.amount_received)
                                                                            
                                                                            const investor = investment.investor_id;
                                                                            if(investor){
                                                                            let investorName = investor.first_name+' '+investor.last_name;

                                                                            return (
                                                                            <TableRow key={i}>
                                                                                <TableCell className='padng-17'>
                                                                                    <Box className="imgAndText">
                                                                                        {investor.image && <img className="image-40" alt={props.name} src={`${imageURL}investor/${investor.image}`} />}
                                                                                        {!investor.image && <span alt={investorName} className="image-text">{Helper.name2Letter(investorName)}</span>}
                                                                                        <Box className="mnTxtDbl">
                                                                                            <Typography className='grrenTxt'>
                                                                                                <Link to={Path.investorGetById(investor._id, investor.slug)}>
                                                                                                {investorName}
                                                                                                </Link>
                                                                                            </Typography>
                                                                                            <Typography className={`btnTypetxt ${Helper.investorType(investor.type)}`}>{investor.type}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell align="center" className=' padng-17'><span className='sem_cssPernt'>{Helper.toLocale(investment.shares)}</span></TableCell>
                                                                                <TableCell align="center" className=' padng-17'>
                                                                                    <Box className="flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt'>{Helper.toLocale(row.face_value_per_share)}</span></Box>
                                                                                </TableCell>
                                                                                <TableCell align="center" className='sem_cssPernt padng-17'>
                                                                                    <Box className="flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt'>{Helper.toLocale(row.premium_per_share)}</span></Box>
                                                                                </TableCell>                                                                               
                                                                                <TableCell align="center" className='sem_cssPernt padng-17'>
                                                                                    <Box className="flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt'>{Helper.toLocale(investment.amount_received)}</span></Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            )
                                                                            }
                                                                        })}
                                                                        </TableBody>
                                                                        
                                                                        {row.investment_ids.length>0 &&
                                                                        <TableFooter className='tabl_fotr'>
                                                                            <TableRow>
                                                                                <TableCell align="center" className=''><Box className="btnType">Total</Box></TableCell>
                                                                                <TableCell align="center" className='sem_cssPernt'>{Helper.toLocale(noShares)}</TableCell>
                                                                                <TableCell align="center" className='sem_cssPernt'></TableCell>
                                                                                <TableCell align="center" className='sem_cssPernt'></TableCell>
                                                                                <TableCell align="center" className=''>
                                                                                    <Box className="flxCntr"><img src='/images/grren_rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt fw-700'>{Helper.toLocale(amountReceived)}</span></Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableFooter>
                                                                        }
                                                                    </Table>
                                                                </TableContainer>
                                                            </Box>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Box>
                                            </Box>
                                            }
                                            </>
                                            )
                                            })}
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                            }
                            </>
                            )
                            })}
                        </React.Fragment>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        </>
    )
}