export const home = '/';
export const login = '/admin/login';

//dashboard
export const dashboard = '/admin';

//company
export const companies = '/companies';
export const companyAdmin = '/admin/company';
export const companyAdminView = '/admin/company/:id';
export const companyGetByIdAdmin = (id) => (`/admin/company/${id}`);
export const companyIndex = '/company';
export const companyView = '/company/:id/:name/:tab?';
export const companyGetByIds = '/company/multiple';
export const companyGetByInvestor = '/company/multiple/investor';
export const companyCreate = '/company/create';
export const companyGetById = (id, slug=null, tab=null) => {
	if(tab)
		return `/company/${id}/${slug}/${tab}`;

	if(slug)
		return `/company/${id}/${slug}`;
	
	return `/company/${id}`;
}

//funding round
export const fundingCreateApi = '/funding/create';
export const fundingGetByIdApi = (id) => (`/funding/${id}`);
export const fundingGetByCompanyApi = '/funding/company';

//investment
export const investmentCreate = '/investment/create';
export const investmentGetById = (id) => (`/investment/${id}`);

//investor
export const investors = '/investors';
export const investorAdmin = '/admin/investor';
export const investorAdminView = '/admin/investor/:id';
export const investorView = '/investor/:id/:name/:tab?';
export const investorIndex = '/investor';
export const investorCreate = '/investor/create';
export const investorGetByIdAdmin = (id) => (`/admin/investor/${id}`);

export const investorGetById = (id, slug=null, tab=null) => {
	if(tab)
		return `/investor/${id}/${slug}/${tab}`;

	if(slug)
		return `/investor/${id}/${slug}`;

	return `/investor/${id}`;
}



//company financials
export const financialCreate = '/financial/create';
export const financialEditApi = (id) => (`/financial/${id}/edit`);
export const financialGetByCompanyIdApi = (company) => (`/financial/company/${company}`);
export const financialDeleteApi = (id) => (`/financial/${id}/delete`);

export const financialTitleAll = '/financial/financial_title/all';
export const financialTitleCreate = '/financial/financial_title/create';

export const financialItemTitleAll = '/financial/financial_item_title/all';
export const financialItemTitleCreate = '/financial/financial_item_title/create';


export const financialCreateTitle = '/financial/title/create';
export const financialTitleEditApi = (id) => (`/financial/title/${id}/edit`);
export const financialTitleDeleteApi = (id) => (`/financial/title/${id}/delete`);

export const financialCreateItem = '/financial/item/create';
export const financialItemEditApi = (id) => (`/financial/item/${id}/edit`);
export const financialItemDeleteApi = (id) => (`/financial/item/${id}/delete`);




//products
export const products = '/products';


//for api's
//Auth
export const authlogin = '/auth/login';

//File Request
export const uploadFile = '/upload-file';
