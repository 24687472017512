import { Box, Button, Grid, Typography, AvatarGroup, Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import InvestorCardSkeleton from '_loaders/InvestorCardSkeleton';

export default function GridView(props) {
    return (
        <>
        <Box className="gridViweMain whitBgWhite invsec">
            <Grid container spacing={2}>
                {props.investorsData.docs && props.investorsData.docs.map((row, index) => {
                let name = row.first_name +' '+ row.last_name;
                return (
                <Grid key={index} item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
                    <Box className="boxPrntMain">
                        <Link to={Path.investorGetById(row._id, row.slug)} className='compnyLinkAj'>
                            <Box className="imgSide">
                                {row.image && <img className="image-90" alt={name} src={`${imageURL}investor/${row.image}`} />}
                                {!row.image && <span alt={name} className="image-text large">{Helper.name2Letter(name)}</span>}
                                <Box className="sosilIcon">
                                    <Box>
                                        <Box className="bg_img"><img src='/images/likdy_ic.svg' alt='' /></Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="ritSide">
                                <Box className="btnTwoImg">
                                    <Box>
                                        <Typography className={`btnTypetxt ${Helper.investorType(row.type)}`}>{row.type}</Typography>
                                    </Box>
                                    <Box className="nAimg">
                                        <img src='/images/n_img.svg' alt='' />
                                        <img src='/images/a_img.svg' alt='' />
                                    </Box>
                                </Box>
                                <Typography component="h2">
                                    {name}
                                </Typography>

                                {row.companies.length > 0 &&
                                <Box className="btnTwoImg">
                                    <Box className="sinthBgbox">
                                        {row.companies.map((company, i) => {
                                            if(i==0){
                                                return (
                                                <Box key={i}>
                                                    {company.image && <img className="image-20" alt={company.legal_name} src={`${imageURL}company/${company.image}`} />}
                                                    {!company.image && <span alt={company.legal_name} className="image-text small">{Helper.name2Letter(company.legal_name)}</span>}
                                                    <Typography className='morInText'>
                                                        {row.companies.length>1 ? (row.companies.length-1)+'+ more investment' : ''}
                                                    </Typography>
                                                </Box>
                                                )
                                            }
                                        })}
                                    </Box>
                                </Box>
                                }

                            </Box>
                        </Link>
                    </Box>
                </Grid>
                )
                })}

                {props.loadMore &&
                <InvestorCardSkeleton card={true} />
                }
            </Grid>

            {!props.loadMore && props.investorsData.hasNextPage &&
            <Box className="lastBtn">
                <Button className='lodMorBtn' onClick={()=>props.handleLoadMore()}>
                    <span>Load More</span>
                    <img src='/images/load_more_ic.svg' alt='' className='load_more_ic' />
                    <img src='/images/load_more_ic_white.svg' alt='' className='load_more_ic_white' />
                </Button>
            </Box>
            }

        </Box>
        </>
    )
}