import axios from 'axios';
import store from './store';
import * as actions from './store/actions';
import { baseURL } from 'env';

const access_token = localStorage.getItem('access_token');

axios.defaults.baseURL = baseURL;

axios.defaults.headers.common.auth_key = access_token;

axios.defaults.headers.post['Content-Type'] ='application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// Create instance
axios.create();
//const axiosInstance = axios.create();

// Axios wrapper to handle error
//const axiosWrapper = apiCall => apiCall.then(res => res.data).catch(err => Promise.reject(err));

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(actions.authLogout());
    }
    return Promise.reject(error);
  },
);

export default axios;
