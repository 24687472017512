import { Box, Button, Grid, Typography, AvatarGroup, Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import * as Path from 'routes/paths';
import * as Helper from '_helpers/CommonFunctions';
import { imageURL } from 'env';
import Paginate from 'components/_common/Paginate';

export default function ListVew(props) {
    return (
        <>
            <Box className="srolWidth whitBgWhite">
                <Box className="list_vwMain">
                    <Box className="scrolPernt">
                        
                        {props.investorsData.docs && props.investorsData.docs.map((row, index) => {
                        let name = row.first_name +' '+ row.last_name;
                        return (
                        <Box className="margnPernt" key={index}>
                            <Box className={'thisBoxPrnt brdr_Bt '+(index%2 ? '':'oneListBg')}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} className='centrAllCont'>
                                       {row.image && <Avatar className="image" alt={name} src={`${imageURL}investor/${row.image}`} />}
                                        {!row.image && <span alt={name} className="image-text">{Helper.name2Letter(name)}</span>}
                                    </Grid>
                                    <Grid item xs={2} className='centrAllFlex'>
                                        <Box className="Txt_imgBx">
                                            <Link to={Path.investorGetById(row._id, row.slug)}>
                                                <Typography>{name}</Typography>
                                            </Link>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} className='centrAllCont'>
                                        <Box className="btnPertt">
                                            <Typography className={`def_btncss ${Helper.investorType(row.type)}`}>{row.type}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className='centrAllCont'>
                                        <Box className="bg_img">
                                            <a href={row.linkedin} target="_blank">
                                                <img src='/images/likdy_ic.svg' alt='' />
                                            </a>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={2} className='centrAllCont'>
                                        {row.companies.length > 0 &&
                                        <Box className="btnTwoImg">
                                            {row.companies &&
                                            <Box className="sinthBgbox">
                                                {row.companies.map((company, i) => {
                                                if(i==0){
                                                    return (
                                                    <>
                                                        {company.image && <img className="image-40" alt={company.legal_name} src={`${imageURL}company/${company.image}`} />}
                                                        {!company.image && <span alt={company.legal_name} className="image-text">{Helper.name2Letter(company.legal_name)}</span>}
                                                        <Typography className='morInText'>{row.companies.length>1 ? (row.companies.length-1)+'+ more investment' : ''}</Typography>
                                                    </>
                                                    )
                                                }
                                                })}
                                            </Box>
                                            }
                                        </Box>
                                        }
                                    </Grid>
                                    <Grid item xs={2} className='centrAllCont'>
                                        <Box className="nAimg">
                                            <img src='/images/n_img.svg' alt='' />
                                            <img src='/images/a_img.svg' alt='' />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={1} className='centrAllCont'>
                                        <Box className="">
                                            <Link to={Path.investorGetById(row._id, row.slug)} className='lastLink'>
                                                <img src='/images/last_link_arrow.svg' alt='' />
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        )
                        })}
                        
                    </Box>
                </Box>
            </Box>
            

            <Paginate
                data={props.investorsData}
                handlePageChange={props.handlePageChange}
                path={Path.investors}
                />
        </>
    )
}