
export const shareType = () => {

	let dataArray = [
			{ value:'Equity', label:'Equity' },
			{ value:'Preference', label:'Preference' },
			{ value:'Debentures', label:'Debentures' },
			{ value:'ESOP', label:'ESOP' }
		];
	
	return dataArray;
}