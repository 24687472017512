import React, { useState, useEffect } from 'react'
import { Box, Grid, Button, Dialog, DialogContent, Typography,
Accordion,
AccordionSummary,
AccordionDetails,
TableFooter,
TableContainer,
Table,
TableHead,
TableRow,
TableCell,
TableBody,
Paper
   } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Helper from '_helpers/CommonFunctions';
import { Link, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import Service from 'services';
import * as Path from 'routes/paths';

import FinancialModal from './modals/FinancialModal';
import TitleModal from './modals/TitleModal';
import ItemModal from './modals/ItemModal';
import Swal from 'sweetalert2'

  
const SummaryFinancials = (props) => {
	const parms = useParams();

	const [openFinancialModal, setOpenFinancialModal] = useState(false);
	const [openTitleModal, setOpenTitleModal] = useState(false);
	const [openItemModal, setOpenItemModal] = useState(false);
	
	const [financialEditData, setFinancialEditData] = useState({});
	const [financialTitleEditData, setFinancialTitleEditData] = useState({});
	const [financialItemEditData, setFinancialItemEditData] = useState({});
	const [financialItemValueEditData, setFinancialItemValueEditData] = useState({});

	const [financialId, setFinancialId] = useState();
	const [financialTitleId, setFinancialTitleId] = useState();
	const [financialRawTitleId, setFinancialRawTitleId] = useState();

	const [textAddEdit, setTextAddEdit] = useState('Add');
	const [loading, setLoading] = useState(false);

	//...Financial
	const handleClickOpenFinancialModal = (data=null) => {
		setFinancialEditData({});
		if(data){
			setFinancialEditData(data);
			setTextAddEdit('Edit');
		}
    setOpenFinancialModal(true);
  };

  const closeFinancialModal = () => {
		setFinancialEditData({});
  	setTextAddEdit('Add');
		setOpenFinancialModal(false);
	}

	//...Title
	const handleClickOpenTitleModal = (id, data=null) => {
		setFinancialId(id);
		setFinancialTitleEditData({});
		if(data){
			setFinancialTitleEditData(data);
			setTextAddEdit('Edit');
		}
    setOpenTitleModal(true);
  };

  const closeTitleModal = () => {
		setFinancialTitleEditData({});
  	setTextAddEdit('Add');
		setOpenTitleModal(false);
	}


	//...Item
	const handleClickOpenItemModal = (id, title_id, item=null, value=null) => {
		setFinancialRawTitleId(title_id);
		setFinancialTitleId(id);
		setFinancialItemEditData({});
		if(item && value){
			setFinancialItemEditData(item);
			setFinancialItemValueEditData(value);
			setTextAddEdit('Edit');
		}
    setOpenItemModal(true);
  };

  const closeItemModal = () => {
		setFinancialItemEditData({});
		setFinancialItemValueEditData({});
  	setTextAddEdit('Add');
		setOpenItemModal(false);
	}


  	const [expandedTab, setExpandedTab] = useState('panel0');
  	const [financialData, setFinancialData] = useState([]);
  	const [financialTitleData, setFinancialTitleData] = useState([]);
  	const [financialItemTitleData, setFinancialItemTitleData] = useState([]);


	  const handleChangeTab = (panel) => (event, newExpanded) => {
	    setExpandedTab(newExpanded ? panel : false);
	  };

	  useEffect(() => {
      getFinancials();
      getFinancialsTitle();
    }, []);

	  const getFinancials = async () => {
      setLoading(true);

      try{
          const apiPath = Path.financialGetByCompanyIdApi(parms.id);

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setFinancialData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };


    const getFinancialsTitle = async () => {
      setLoading(true);

      try{
          const apiPath = Path.financialTitleAll;

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setFinancialTitleData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };

    const getFinancialsItemTitle = async () => {
      setLoading(true);

      try{
          const apiPath = Path.financialItemTitleAll;

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setFinancialItemTitleData(res)
            setLoading(false);
          });
      } catch(err) {
          console.log(err);
          setLoading(false);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };


		const deleteFinancial = (id) => {
    	const apiPath = Path.financialDeleteApi(id);
    	const message = 'Financial Year deleted.';

    	deleteModal(apiPath, message);
		};

		const deleteFinancialTitle = (id) => {
    	const apiPath = Path.financialTitleDeleteApi(id);
    	const message = 'Financial Title deleted.';

    	deleteModal(apiPath, message);
		};

		const deleteFinancialItem = (id) => {
    	const apiPath = Path.financialItemDeleteApi(id);
    	const message = 'Financial Item deleted.';

    	deleteModal(apiPath, message);
		};


		const deleteModal = (apiPath, message) => {
    	
		  Swal.fire({
		    title: 'Are you sure?',
		    text: "You won't be able to revert this!",
		    icon: 'warning',
		    showCancelButton: true,
		    confirmButtonColor: '#3085d6',
		    cancelButtonColor: '#d33',
		    confirmButtonText: 'Yes, delete it!'
		  }).then((result) => {
		    if (result.isConfirmed) {
		      try{
		        props.dispatch(Service.deleteRequest(apiPath)).then((res) => {
		          getFinancials();
		        	Swal.fire(
                'Deleted!',
                message,
                'success'
              )
		        });
		      } catch(err) {
		          console.log(err);
		          if(err && err.data && err.data.msg){
		            Swal.fire("Oops!", err.data.msg, "error");
		          }
		      }
		    }
		  })
		};


  


    return (
       	<>
    			<Box className="forBtnBox">
						<Button className='defBtn' onClick={()=>handleClickOpenFinancialModal()}>
							<AddIcon /> Add Financial Year
						</Button>
        	</Box>


        	{financialData.length>0 && financialData.map((row, index) => {
        		
						return (
						<>
		        	<Box className="moreCinfo" key={index}>
		            <Box className="dateBox"><span />
		            	<b className="pointer" onClick={()=>handleClickOpenFinancialModal(row)}>
		            	FY {row.from_year}-{row.to_year.slice(-2)}
		            	</b>
		            	<spam className="pointer">
		            	<DeleteIcon onClick={()=>deleteFinancial(row._id)}/>
		            	</spam>
		            </Box>
		            
								<Box className="date-title">
		            	<Button className='defBtn' onClick={()=>handleClickOpenTitleModal(row._id)}>
										<AddIcon /> Add Title
									</Button>
		            </Box>


		            <Box className={`moreCinfoinn ${financialData.length==index+1 ? 'bBordernone' : ''}`}>
								{row.company_financial_title_ids.length>0 && row.company_financial_title_ids.map((data, i) => {
		        		let accordianPanel = 'panel' + index + i;
		        		let noValues = 0;

		        		return (
								<>
		              <Accordion expanded={expandedTab === accordianPanel} onChange={handleChangeTab(accordianPanel)} className='asAccording' key={i}>
		                <Box className="accHeader">
		                  <AccordionSummary className='acrdn_sumry'>
		                    <img src='/images/accorn_arrow.svg' alt='' />
		                    <Typography aria-controls="panel1d-content" id="panel1d-header">{data.title_id.title}</Typography>
		                  </AccordionSummary>

		                  <Box className="two_btn_pernt">
                          <Button className='edit_btn' onClick={()=>handleClickOpenTitleModal(row._id, data)}>Edit</Button>
                          <Button className='delete_btn' onClick={()=>deleteFinancialTitle(data._id)}>Delete</Button>
                      </Box>
		                  <Button className='defBtn' onClick={() => handleClickOpenItemModal(data._id, data.title_id._id)}>
                          <AddIcon /> Add Item
                      </Button>
		                </Box>
		                {data.company_financial_item_ids.length>0 &&
		                <AccordionDetails>
		                  <Box className="tablBox">
		                    <TableContainer component={Paper} className='tabl_contnr'>
		                      <Table sx={{ minWidth: 350 }} aria-label="customized table" className='tabl_pernt_main'>
		                        <TableHead>
		                          <TableRow>
		                            <TableCell align="center">Item</TableCell>
		                            <TableCell align="center">Value</TableCell>
		                            <TableCell align="center">Action</TableCell>
		                          </TableRow>
		                        </TableHead>
		                        <TableBody className='tabl_body'>
		                          
		                          {data.company_financial_item_ids.length>0 && data.company_financial_item_ids.map((item, j) => {
		                          
		                          if(item.company_financial_item_value_ids.length>0){
		                          	return (
		                          		<>
			                          	{item.company_financial_item_value_ids.map((itemValue, k) => {
												        		noValues = noValues + Helper.checkIntValue(itemValue.value)

												        		return (
																		<>
					                          <TableRow key={k}>
					                            <TableCell align="center" className='sem_cssPernt padng-17'>{item.title_id.title}</TableCell>
					                            <TableCell align="center" className='sem_cssPernt padng-17'>
			                                    <Box className="flxCntr"><img src='/images/rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt'>{Helper.toLocale(itemValue.value)}</span></Box>
			                                </TableCell> 
			                                <TableCell align="center" className=''>
			                                    <Box className="two_btn_pernt">
			                                        <Button className='edit_btn' onClick={()=>handleClickOpenItemModal(data._id, data.title_id._id, item, itemValue)}>Edit</Button>
			                                        <Button className='delete_btn' onClick={()=>deleteFinancialItem(itemValue._id)}>Delete</Button>
			                                    </Box>
			                                </TableCell>
					                          </TableRow>
					                          </>
															      )
													      	})}
		                          		</>
			                          	)
			                          }
											      	})}
		                        </TableBody>
		                        <TableFooter className='tabl_fotr'>
		                          <TableRow>
		                            <TableCell align="center" className='footr_bgPernt'><Box className="btnType">Total</Box></TableCell>
		                            <TableCell align="center" className='footr_bgPernt'>
                                    <Box className="flxCntr"><img src='/images/grren_rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt fw-700'>{Helper.toLocale(noValues)}</span></Box>
                                </TableCell>
                                <TableCell align="center" className='footr_bgPernt'></TableCell>
		                          </TableRow>
		                        </TableFooter>
		                      </Table>
		                    </TableContainer>
		                  </Box>
		                </AccordionDetails>
		              	}
		              </Accordion>
			            </>
						      )
				      	})}
		            </Box>

		          </Box>
		        </>
		        )
      		})}


					<FinancialModal
						alert={props.alert}
						textAddEdit={textAddEdit}
						openModal={openFinancialModal}
						closeModal={closeFinancialModal}
						getFinancials={getFinancials}
						financialEditData={financialEditData}
						/>

					<TitleModal
						alert={props.alert}
						textAddEdit={textAddEdit}
						openModal={openTitleModal}
						closeModal={closeTitleModal}
						getFinancials={getFinancials}
						financialId={financialId}
						financialTitleEditData={financialTitleEditData}
						financialTitleData={financialTitleData}
						getFinancialsTitle={getFinancialsTitle}
						/>

					<ItemModal
						alert={props.alert}
						textAddEdit={textAddEdit}
						openModal={openItemModal}
						closeModal={closeItemModal}
						getFinancials={getFinancials}
						financialTitleId={financialTitleId}
						financialItemEditData={financialItemEditData}
						financialItemValueEditData={financialItemValueEditData}
						financialTitleData={financialTitleData}
						getFinancialsTitle={getFinancialsTitle}
						financialRawTitleId={financialRawTitleId}
						setFinancialRawTitleId={setFinancialRawTitleId}
						/>

        </>
    )
}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SummaryFinancials);