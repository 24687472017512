import Skeleton from '@mui/material/Skeleton';
import { Box, Grid } from '@mui/material'
import { Link } from "react-router-dom";

import WhiteCard from './investor/White';

const CardSkeleton = props => {
    return (
        <>
            <WhiteCard />
            <WhiteCard />
            <WhiteCard />
            <WhiteCard />

            <WhiteCard />
            <WhiteCard />
            <WhiteCard />
            <WhiteCard />

            <WhiteCard />
            <WhiteCard />
            <WhiteCard />
            <WhiteCard />
        </>
    )
}

const InvestorCardSkeleton = props => {

    if(props.card){
        return (
            <CardSkeleton />
        )
    }

    return (
    <Box className="gridViweMain whitBgWhite">
        <Grid container spacing={2}>
            <CardSkeleton />
        </Grid>
    </Box>
    )
}


export default InvestorCardSkeleton