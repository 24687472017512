import React from 'react';
import { Link } from "react-router-dom";
import * as Path from 'routes/paths';
import Helmet from 'react-helmet';

const NotFound = () => (
  <>
    <Helmet>
      <title>Page not found | The Balance Sheet</title>
      <meta name="description" content="The Balance Sheet is India’s largest startup data platform on a mission to build & serve India’s tech, startup & internet economy." />
    </Helmet>
    <div className="notFound">
      <div className="text-center">
        <h1>404</h1>
        <p>Page not found.</p>
        <p><Link to={Path.home}>Go Back</Link></p>
      </div>
    </div>
  </>
);

export default NotFound;
