import React, { useState, useEffect } from 'react'
import { Box, Grid, Button, Dialog, DialogContent, Typography,
Accordion,
AccordionSummary,
AccordionDetails,
TableFooter,
TableContainer,
Table,
TableHead,
TableRow,
TableCell,
TableBody,
Paper
   } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Helper from '_helpers/CommonFunctions';
import { Link, useParams } from 'react-router-dom';

import { connect } from 'react-redux';
import Service from 'services';
import * as Path from 'routes/paths';
import GChart from 'components/_common/GChart';
import colors from 'assets/colors';
import SummarySkeleton from '_loaders/company/SummarySkeleton';
 
export const options = {
  colors: colors,
  responsive: true,
  chartArea: { width: "60%" },
  isStacked: true,
  hAxis: {
    minValue: 0,
    title: "Financial Year",
    titleTextStyle: {italic: false}
  },
  vAxis: {
  },
};

const SummaryFinancials = (props) => {

		const parms = useParams();
		const [loading, setLoading] = useState(true);
  	const [financialData, setFinancialData] = useState([]);
  	const [financialTitleData, setFinancialTitleData] = useState([]);
  	const [financialItemTitleData, setFinancialItemTitleData] = useState([]);
  	const [financialYears, setFinancialYears] = useState([]);
  	const [summaryData, setSummaryData] = useState([]);

	  useEffect(() => {
	  	setLoading(true);
      getFinancialsTitle();
      getFinancialsItemTitle();
      getFinancials();
    }, []);

    useEffect(() => {
      makingData();
    }, [financialData, financialTitleData, financialItemTitleData]);

	  const getFinancials = async () => {
      try{
          const apiPath = Path.financialGetByCompanyIdApi(parms.id);

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setFinancialData(res)
          });
      } catch(err) {
          console.log(err);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };


    const getFinancialsTitle = async () => {
      try{
          const apiPath = Path.financialTitleAll;

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setFinancialTitleData(res)
          });
      } catch(err) {
          console.log(err);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };

    const getFinancialsItemTitle = async () => {
      try{
          const apiPath = Path.financialItemTitleAll;

          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setFinancialItemTitleData(res)
          });
      } catch(err) {
          console.log(err);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
    };



		const makingData = async () => {
			let dataArray = [];
			let yearsArray = [];
			
			const desiredData = {};

				financialData.forEach((item) => {
				  item.company_financial_title_ids.forEach((title) => {
				    const titleName = title.title_id.title;
				    if (!desiredData[titleName]) {
				      desiredData[titleName] = {};
				    }

				    title.company_financial_item_ids.forEach((financialItem) => {
				      const itemName = financialItem.title_id.title;
				      
				      const year = `${item.from_year}-${item.to_year.slice(-2)}`;
				      yearsArray.push(year);

				      let itemValueSum = Helper.checkIntValue(0);


				      if(financialItem.company_financial_item_value_ids.length > 0){
					      financialItem.company_financial_item_value_ids.forEach((itemValue) => {
					      	itemValueSum = itemValueSum + Helper.checkIntValue(itemValue.value);
					      });

				      	if (!desiredData[titleName][itemName]) {
					        desiredData[titleName][itemName] = {};
					      }
					      if (!desiredData[titleName][itemName][year]) {
					        desiredData[titleName][itemName][year] = {};
					      }

				      	//...
				      	desiredData[titleName][itemName][year] = itemValueSum;
				    	}

				    });
				  });
				});


				//...
			let uniqueYearsArray = yearsArray.filter(function(elem, index, self) {
				return index === self.indexOf(elem);
			});

			setSummaryData(desiredData);
			setFinancialYears(uniqueYearsArray);
	  	

	  	setTimeout(() => {
	  			setLoading(false);
      }, 1500);
		}

    return (
       	<>

       	{loading && <SummarySkeleton type={props.value} />}

       	{!loading &&
       	<>
	       	{(financialData.length>0 && financialTitleData.length>0) && financialTitleData.map((data, index) => {
	       		if(summaryData[data.title]){
	       			let sTitle = summaryData[data.title];
	       			let years = [];

	       			//mapsData
	       			let mapsDataArray = [];
							let mapTitleArray = [];
								mapTitleArray[0] = "Title";						

							let mi=1;
	       			financialItemTitleData.forEach((ItemTitle) => {
	       				if(sTitle[ItemTitle.title]){
	       					let iTitle = sTitle[ItemTitle.title];
	       					//maps title
	       					mapTitleArray[mi] = ItemTitle.title;
	       					
	       					financialYears.forEach((year) => {
	       						
	       						if(iTitle[year] || iTitle[year]==0){
	       							years.push(year);
	       						}
	       					});
	       					mi++;
	              }
	       			});
	       			mapsDataArray.push(mapTitleArray);

	       			//unique years
	       			let uniqueYears = years.filter(function(elem, index, self) {
								return index === self.indexOf(elem);
							});
							uniqueYears = [...uniqueYears].sort();

//console.log('uniqueYears', uniqueYears)


							//map values data
							let mapValuesArray = [];
	       			uniqueYears.forEach((year, index) => {
	       				let yArray = [];
	       				yArray[0] = 'FY '+year;

	       				let mi1=1;
		       			financialItemTitleData.forEach((ItemTitle) => {
		       				if(sTitle[ItemTitle.title]){
		       					let iTitle = sTitle[ItemTitle.title];
		       					let itemCost = Helper.checkIntValue(0);

	       						if(iTitle[year]){
	       							itemCost = iTitle[year];
	       						}

	       						yArray[mi1] = itemCost;
	       						mi1++;
		       					
		              }
		       			});
		       			mapsDataArray.push(yArray);
	       			});
	       			

	       			if(mapsDataArray.length > 1){
		       		return (
		       			<Box className="mainTabPnl whitBgWhite" key={index}>
		       				<h2>{data.title}</h2>

		       				{mapsDataArray.length>0 &&
									<GChart
										chartType="ColumnChart"
										chartData={mapsDataArray}
										chartOptions={options}
										chartWidth="100%"
										chartHeight="600px"
										/>
									}

									<Box className="">
	                	<Box className="tablBox">
				       				<TableContainer component={Paper} className='tabl_contnr hoveredTable'>
					            	<Table sx={{ minWidth: 500 }} aria-label="customized table" className='tabl_pernt_main'>
					                <TableHead>
					                    <TableRow>
					                        <TableCell align="center" sx={{ minWidth: 200 }}>Financial Years</TableCell>
					                       	{uniqueYears.length>0 && uniqueYears.map((row, i) => {
					                        	return (
					                        		<TableCell key={i} align="center">FY {row}</TableCell>
					                        	)
					      									})}
					                    </TableRow>
					                </TableHead>
					                <TableBody className='tabl_body'>
					                	
			                      {financialItemTitleData.length>0 && financialItemTitleData.map((ItemTitle, i) => {
			                      	if(sTitle[ItemTitle.title]){
			                      		let iTitle = sTitle[ItemTitle.title];

			                      	return (
			                        	<>
			                        	<TableRow key={i}>
			                    				<TableCell align="center">{ItemTitle.title}</TableCell>
			                    				
			                  					{uniqueYears.length>0 && uniqueYears.map((year, j) => {
			                  						let iYear = iTitle[year];

					                        	return (
					                        		<TableCell align="center" className='sem_cssPernt padng-17' key={j}>
					                        			{iYear ?
						                      				<div className="last-price flxCntr">
						                      					<img src='/images/rupys_ic.svg' alt='' className='rupys_ic' />
						                      					{Helper.toLocale(iYear)}
						                      				</div>
						                      				:
						                      				<div>-</div>
						                      			}
					                      			</TableCell>
			                      				)
					                        
				      										})}

			                      		</TableRow>
			                      		</>
			                      	)
			                      	}
			    									})}
					                    
					                </TableBody>

					                <TableFooter className='tabl_fotr'>
			                      <TableRow>
			                          <TableCell align="center" className=''><Box className="btnType">Total</Box></TableCell>
			                          {uniqueYears.length>0 && uniqueYears.map((year, j) => {
			                          	let itemCost = Helper.checkIntValue(0);
			                          	financialItemTitleData.forEach((ItemTitle) => {
											       				if(sTitle[ItemTitle.title]){
											       					let iTitle = sTitle[ItemTitle.title];
										       						if(iTitle[year]){
										       							itemCost = itemCost + Helper.checkIntValue(iTitle[year]);
										       						}
											              }
											       			});

					                        return (

				                        	<>
				                        		<TableCell align="center" className=''>
	                                     <Box className="flxCntr"><img src='/images/grren_rupys_ic.svg' alt='' className='rupys_ic' /><span className='sem_cssPernt fw-700'>{Helper.toLocale(itemCost)}</span></Box>
	                                  </TableCell>
				                          </>
					                      	)
					    									})}

			                      </TableRow>
			                  </TableFooter>

						            </Table>
						        	</TableContainer>

		       					</Box>
		       				</Box>
		       			</Box>
		       		)
						}
	       		}
	       	})}
					
	       	
		      {financialData.length==0 &&
		      	<Box className="mainTabPnl whitBgWhite tabs_Cptble">
			      	<Box className="flxCntr coming-soon">
		              Data Coming Soon
		          </Box>
	          </Box>
	        }

	        </>
      	}

        </>
    )
}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SummaryFinancials);