import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Pagination, Dialog, DialogContent, FormControl } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Select from 'react-select'

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Service from 'services';
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';
import { useForm } from "react-hook-form";
import { imageURL } from 'env';
import * as Helper from '_helpers/CommonFunctions';

const CompaniesCreateEdit = (props) => {

  const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  ///file upload
  const [selectedImage, setSelectedImage] = React.useState();
  const [selectedImageShow, setSelectedImageShow] = React.useState();

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      setSelectedImageShow(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
    setSelectedImageShow();
  };


  useEffect(() => {
      if(props.editCompany._id){
        reset({
          image: props.editCompany.image,
          legal_name: props.editCompany.legal_name,
          business_type: props.editCompany.business_type,
          website: props.editCompany.website,
          linkedin: props.editCompany.linkedin,
          twitter: props.editCompany.twitter,
          instagram: props.editCompany.instagram,
          description: props.editCompany.description
        })

        let imgPath = `${imageURL}company/${props.editCompany.image}`;

        setSelectedImageShow(imgPath);
      }
  }, [props.editCompany]);

  const closeModal = () => {
    props.handleCloseModal();
    reset({
        image: '',
        legal_name: '',
        business_type: '',
        website: '',
        linkedin: '',
        twitter: '',
        instagram: '',
        description: '',
      })
      removeSelectedImage();
  }


  const onSubmit = async (values) => {
    setLoading(true);

    try{
        values['slug'] = Helper.slugify(values.legal_name);

        if(selectedImage){
          var formData = new FormData();
            formData.append("file", selectedImage);
            formData.append("type", 'company');
            formData.append("name", values.slug);

            let fileData = await props.dispatch(Service.uploadProfile(Path.uploadFile, formData));

            if(fileData?.data?.name){
              values['image'] = fileData.data.name;
            }
        }

        if(props.editCompany._id){
          const apiPath = Path.companyGetById(props.editCompany._id);
          await props.dispatch(Service.patchRequest(apiPath, values)).then((res) => {
              setLoading(false);
              props.handleCloseModal();
              props.getCompanies();
              reset();
              removeSelectedImage();
              props.alert('Company Updated');
          });
        }
        else {
          await props.dispatch(Service.postRequest(Path.companyCreate, values)).then((res) => {
              setLoading(false);
              props.handleCloseModal();
              props.getCompanies();
              reset();
              removeSelectedImage();
              props.alert('Company Create');
          });
        }
      }catch(err) {
        console.log(err);
        setLoading(false);
      }
  };

  let textAddEdit = props.editCompany._id ? 'Edit' : 'Add';

  return (
    <>
      <Dialog
        open={props.openModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='dialog_main_pernt'
      >
        <Box className='headin_hh'>
          <Typography component="h1">{textAddEdit} Company</Typography>
        </Box>
        <DialogContent className='main_content'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className="frist_three_itms ">
              <Box>
                <Box className="fileUploadbx">
                  <Box className="fileUploadbxInn">
                    <input
                      accept="image/*"
                      type="file"
                      onChange={imageChange}
                    />

                    {selectedImageShow && (
                      <div className='thumbBox'>
                        <img
                          src={selectedImageShow}
                          alt="Thumb"
                          className='thumbImage'
                        />
                        <button className="removeButton" onClick={removeSelectedImage}>
                          Remove This Image
                        </button>
                      </div>
                    )}
                    <Box className="cmrox">
                      <CameraAltIcon />
                      Choose A File
                    </Box>
                  </Box>
                  <Typography>
                    Dimension Max
                    <span>200 x 200 pixels</span>
                  </Typography>

                </Box>
                <FormControl className='form_control '>
                  <label className='hed_labl mb-10'>Legal Name</label>
                  <input type='text' placeholder='Legal Name' className='input_input' 
                    {...register("legal_name", { required: true })} />
                    {errors.legal_name?.type === 'required' && <span className="alert">Legal Name is required</span>}
                </FormControl>
              </Box>
              <Box component="form">
                <FormControl className='form_control '>
                  <label className='hed_labl mb-10'>Business Type</label>
                  <input type='text' placeholder='Business Type' className='input_input'
                    {...register("business_type", { required: true })} />
                    {errors.business_type?.type === 'required' && <span className="alert">Business Type is required</span>}
                </FormControl>
              </Box>
              <Box>
                <FormControl className='form_control '>
                  <label className='hed_labl mb-10'>Website</label>
                  <input type='text' placeholder='Website' className='input_input'
                    {...register("website", { required: true })} />
                    {errors.website?.type === 'required' && <span className="alert">Website is required</span>}
                </FormControl>
              </Box>
            </Box>
            <Box className="frist_three_itms margn_prnt">
              <Box>
                <FormControl className='form_control mb-10'>
                  <label className='hed_labl mb-10'>Social Media</label>
                  <input type='text' placeholder='LinkedIn' className='input_input'
                    {...register("linkedin", { required: true })} />
                    {errors.linkedin?.type === 'required' && <span className="alert">LinkedIn is required</span>}
                </FormControl>
              </Box>
              <Box>
                <FormControl className='form_control mb-10'>
                  <input type='text' placeholder='Twitter' className='input_input'
                    {...register("twitter", { required: true })} />
                    {errors.twitter?.type === 'required' && <span className="alert">Twitter is required</span>}
                </FormControl>
              </Box>
              <Box>
                <FormControl className='form_control mb-10'>
                  <input type='text' placeholder='Instagram' className='input_input'
                    {...register("instagram", { required: true })} />
                    {errors.instagram?.type === 'required' && <span className="alert">Instagram is required</span>}
                </FormControl>
              </Box>
            </Box>
            <Box className="last_content">
              <Box className="textarea_pernt">
                <textarea className='text_aria' rows="3" cols="50" placeholder='Description'
                  {...register("description", { required: true })}></textarea>
                    {errors.description?.type === 'required' && <span className="alert">Description is required</span>}
                <img src='/images/textarea_ic.svg' alt='' className='textarea_ic' />
              </Box>
              <Button type="submit" className='def_btn'>{textAddEdit} COMPANY</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CompaniesCreateEdit);