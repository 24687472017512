import React from "react";
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from "react-router-dom";
import {
    Collapse,
    Button
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LogoutIcon from '@mui/icons-material/Logout';

import * as Path from 'routes/paths';
import * as actions from 'store/actions';

const Sidebar = (props) => {
    const history = useHistory();

	const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    async function handleLogout() {
        await props.dispatch(actions.authLogout());
        history.push(Path.login);
    };

    return (
        <>
    		<ul className='MainMenu'>
                <li>
                    <Link to={Path.dashboard} className={splitLocation[2] === "" ? "active" : ""}><HomeIcon /> <span>Home</span></Link>
                </li>
                <li>
                    <Link to={Path.companyAdmin} className={splitLocation[2] === "company" ? "active" : ""}><MeetingRoomIcon /> <span>Companies</span></Link>
                </li>
                <li>
                    <Link to={Path.investorAdmin} className={splitLocation[2] === "investor" ? "active" : ""}><PeopleAltIcon />  <span>Investors</span></Link>
                </li>

                <li>
                    <a className="link" onClick={handleLogout}><LogoutIcon /> Logout</a>
                </li>
            </ul>
        </>
    );
}

const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Sidebar);