import React, { useState, useEffect } from "react";
import { Box, FormControl, Button, Container, Dialog, DialogContent, Typography, Grid } from '@mui/material'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Select from 'react-select'

import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';
import Service from 'services';
import * as Helper from '_helpers/CommonFunctions';
import CompanyCardSkeleton from '_loaders/CompanyCardSkeleton';
import ListSkeleton from '_loaders/ListSkeleton';
import { imageURL } from 'env';

import GridView from './GridView';
import ListView from './ListView';

const CompanyIndex = (props) => {
  
  const history = useHistory();
  let parmSearch = window.location.search;
  let params = new URLSearchParams(parmSearch);

  const [viewType, setViewType] = useState('grid');
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(params.get('page') ? parseInt("" + params.get('page')) : 1)
  const [limit, setLimit] = useState(12)
  const [search, setSearch] = useState('')

  /////
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
      setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };
  ///
  const [openTwo, setOpenTwo] = useState(false);
  const handleClickOpenTwo = () => {
      setOpenTwo(true);
      setOpen(false);
  };
  const handleCloseTwo = () => {
      setOpenTwo(false);
      setOpen(true);
  };
  ///
  const [openThree, setOpenThree] = useState(false);
  const handleClickOpenThree = () => {
      setOpenThree(true);
      setOpen(false);
  };
  const handleCloseThree = () => {
      setOpenThree(false);
      setOpen(true);
  };

  const [loading, setLoading] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);

  useEffect(() => {
      getCompanies();
  }, [page, viewType]);

  const handleKeyDownSearch = (event) => {
      if (event.key === 'Enter') {
          getCompanies();
      }
  };  

  const handlePageChange = (p) => {
    setPage(p);
  };

  const handleLoadMore = () => {
    setLoadMore(true);
    setPage(page+1);
  };

  const handleChangeView = (type) => {
    history.push('?page=1')
    setLoadMore(false);
    setCompaniesData([]);
    setPage(1)
    setViewType(type);
    setSearch('');

    setLimit(12);
    if(type=='list'){
      setLimit(10);
    }
  };


  const getCompanies = async () => {
    setLoading(true);

    try{
        const apiPath = Path.companyIndex + `?search=${search}&limit=${limit}&page=${page}`;
        await props.dispatch(Service.getRequest(apiPath)).then((res) => {
          
          if(loadMore){
            let newCompanyArray = companiesData;
            if(newCompanyArray.docs){
              let mergeData = [...newCompanyArray.docs, ...res.docs];
              
              let createCompanyObject = res;
                  createCompanyObject.docs = mergeData;

              setCompaniesData(createCompanyObject)
            }
            else {
              setCompaniesData(res)
            }
          }
          else {
            setCompaniesData(res)
          }

          setLoading(false);
          setLoadMore(false);
        });
    } catch(err) {
        console.log(err);
        setLoading(false);
        if(err && err.data && err.data.msg){
          //props.alert("Oops!", err.data.msg, "error");
        }
    }
  };



  return (
    <>
      <Helmet>
        <title>List Of Startups | Most Active Startups in India | The Balance Sheet</title>
        <meta name="description" content="Discover the most active startups in India." />
      </Helmet>
      
      <Box className="">
          <ul className='breadcrumb'>
              <li>
                  <Link to={Path.home}><HomeIcon /></Link>
              </li>
              <li>
                  <ChevronRightIcon />
              </li>
              <li>
                  <span>List Of Companies</span>
              </li>
              <li className='brdrBx_pernt'>
                  <Box className="border_box" />
              </li>
          </ul>
      </Box>

      <Box className="fourItems">
          <Box className="customSearch formSearch">
              <Box className="iSearch">
              <input type='text' 
                placeholder='Search Companies'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleKeyDownSearch}
                />
              </Box>
              <img src="/images/srch_icon.svg" alt='' className='searchIcon' />
          </Box>
          <Box className="lastThreeBtn">
              <Button className={viewType=='grid' ? "grid_wi_btn mrgn_rght" : "list_btn mrgn_rght"} onClick={()=>handleChangeView('grid')}>
                  {viewType=='grid' ?
                      <img src='/images/grid_wi_ic.svg' alt='' className='' />
                      :
                      <img src='/images/gridDark.svg' alt='' className='' />
                  }
              </Button>
              <Button className={viewType=='list' ? "grid_wi_btn mrgn_rght" : "list_btn mrgn_rght"} onClick={()=>handleChangeView('list')}>
                  {viewType=='list' ?
                      <img src='/images/listGreen.svg' alt='' className='' />
                      :
                      <img src='/images/list_wi_ic.svg' alt='' className='' />
                  }
              </Button>
          </Box>
      </Box>
      
      {/*Grid View*/}
      {(loading && !loadMore && viewType=='grid') && <CompanyCardSkeleton />}
      {(companiesData.docs && viewType=='grid') &&
        <GridView
          companiesData={companiesData}
          handleLoadMore={handleLoadMore}
          loadMore={loadMore}
          />
      }
      

      {/*List View*/}
      {(loading && viewType=='list') && <ListSkeleton />}
      {(!loading && viewType=='list') &&
        <ListView
          companiesData={companiesData}
          handlePageChange={handlePageChange}
          />
      }

          
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='defModal filterModal'
      >
          <DialogContent>
              <Typography component="h3">Add Filter</Typography>
              <Grid container spacing={1} className='filterInn'>
                  <Grid item xs={6} md={6}>
                      <Button onClick={handleClickOpenTwo}>
                          <img src='/images/typeIcon.svg' alt='' />
                          <span>Type</span>
                      </Button>
                  </Grid>
                  <Grid item xs={6} md={6}>
                      <Button onClick={handleClickOpenThree}>
                          <img src='/images/tagIcon.svg' alt='' />
                          <span>Tag</span>
                      </Button>
                  </Grid>
              </Grid>
          </DialogContent>
      </Dialog>
      <Dialog
          open={openTwo}
          onClose={handleCloseTwo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='defModal filterModalTwo'
      >
          <DialogContent>
              <Typography component="h3"><img src='/images/close.svg' alt='' onClick={handleCloseTwo} /> Add Filter</Typography>
              <Typography component="h4">
                  Type
                  <Button>Clear All</Button>
              </Typography>
              <ul className='checkList'>
                  <li>
                      <Box className="csmCheck">
                          <input type='checkbox' id="checkOne" />
                          <label htmlFor='checkOne'>Fintech</label>
                      </Box>
                  </li>
                  <li>
                      <Box className="csmCheck">
                          <input type='checkbox' id="checkTwo" />
                          <label htmlFor='checkTwo'>D2C</label>
                      </Box>
                  </li>
                  <li>
                      <Box className="csmCheck">
                          <input type='checkbox' id="checkThree" />
                          <label htmlFor='checkThree'>SaaS</label>
                      </Box>
                  </li>
                  <li>
                      <Box className="csmCheck">
                          <input type='checkbox' id="checkFour" />
                          <label htmlFor='checkFour'>SaaS</label>
                      </Box>
                  </li>
                  <li>
                      <Box className="csmCheck clearBtn">
                          <input type='checkbox' id="checkFive" />
                          <label htmlFor='checkFive'>Select All</label>
                      </Box>
                  </li>
              </ul>
          </DialogContent>
      </Dialog>
      <Dialog
          open={openThree}
          onClose={handleCloseThree}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='defModal filterModalTwo'
      >
          <DialogContent>
              <Typography component="h3"><img src='/images/close.svg' alt='' onClick={handleCloseThree} /> Add Filter</Typography>
              <Typography component="h4">
                  Tag
                  <Button>Clear All</Button>
              </Typography>
              <ul className='checkList'>
                  <li>
                      <Box className="csmCheck">
                          <input type='checkbox' id="checkOne" />
                          <label htmlFor='checkOne'>New</label>
                      </Box>
                  </li>
                  <li>
                      <Box className="csmCheck">
                          <input type='checkbox' id="checkTwo" />
                          <label htmlFor='checkTwo'>Active</label>
                      </Box>
                  </li>
                  <li>
                      <Box className="csmCheck clearBtn">
                          <input type='checkbox' id="checkFive" />
                          <label htmlFor='checkFive'>Select All</label>
                      </Box>
                  </li>
              </ul>
          </DialogContent>
      </Dialog>
    </>
  );
}

const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CompanyIndex);