import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, AvatarGroup, Avatar, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton';
import { Link } from "react-router-dom";

import { connect } from 'react-redux';
import Service from 'services';
import * as Path from 'routes/paths';

import * as Helper from '_helpers/CommonFunctions';
import LatestFromFeedSc from './LatestFromFeedSc';
import HomeCardSkeleton from '_loaders/HomeCardSkeleton';
import { imageURL } from 'env';
import Helmet from 'react-helmet';

const Home = (props) => {

  const [loadingCompany, setLoadingCompany] = useState(false);
  const [loadingInvestor, setLoadingInvestor] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [investorsData, setInvestorsData] = useState([]);

  const homeLimit = 7;
  const limitImageNumbers = 4;

  useEffect(() => {
      getCompanies();
      getInvestors();
  }, []);


  const getCompanies = async () => {
    setLoadingCompany(true);

    try{
        const apiPath = `${Path.companyIndex}?homeLimit=${homeLimit}&companies=${limitImageNumbers}`;
        await props.dispatch(Service.getRequest(apiPath)).then((res) => {
          setCompaniesData(res)
          setLoadingCompany(false);
        });
    } catch(err) {
        console.log(err);
        setLoadingCompany(false);
        if(err && err.data && err.data.msg){
          //props.alert("Oops!", err.data.msg, "error");
        }
    }
  };

  const getInvestors = async () => {
    setLoadingInvestor(true);
      try{
          const apiPath = `${Path.investorIndex}?homeLimit=${homeLimit}&investors=${limitImageNumbers}`;
          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            setInvestorsData(res)
            setLoadingInvestor(false);
          });
      } catch(err) {
          console.log(err);
          setLoadingInvestor(false);
          if(err && err.data && err.data.msg){
              //props.alert("Oops!", err.data.msg, "error");
          }
      }
  };

  return (
    <>
      <Helmet>
        <title>The Balance Sheet | India’s Leading Startup Data Platform</title>
        <meta name="description" content="The Balance Sheet is India’s largest startup data platform on a mission to build & serve India’s tech, startup & internet economy." />
      </Helmet>
      
      <Box className="blns_shtBg">
        <iframe src="https://thebalancesheet.substack.com/embed" 
          width="100%" 
          height="320" 
          style={{ border:'1px solid #EEE', background:'white' }} 
          frameBorder="0" 
          scrolling="no"
          />
      </Box>

      {loadingCompany && <HomeCardSkeleton />}
      {(!loadingCompany && companiesData.data) &&
      <Box className="Cmpnis_cont">
          <Box className="hed_brdr">
              <Box className="brdrBox" ></Box>
              <Typography component="h1">Companies</Typography>
          </Box>
          <Box className="gridCont">
              <Grid container spacing={2}>
                  
                  {companiesData.data && companiesData.data.map((company, index) => {
                  return (
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt' key={index}>
                      <Box className="whiteBgBox">
                          <Link to={Path.companyGetById(company._id, company.slug)} className="innrCont_flx">
                              <Box className="imgPent">
                                  {company.image && <img className="image-90" alt={company.legal_name} src={`${imageURL}company/${company.image}`} />}
                                  {!company.image && <span alt={company.legal_name} className="image-text large">{Helper.name2Letter(company.legal_name)}</span>}
                              </Box>
                              <Box className="rightContnt">
                                  <Box className="NAimg">
                                      <img src='/images/n_img.svg' alt='' />
                                      <img src='/images/a_img.svg' alt='' />
                                  </Box>
                                  <Typography className='uberText'>
                                      {company.legal_name}
                                  </Typography>

                                  {company.investors.length > 0 &&
                                  <Box className="avtar_perntA2">
                                      <AvatarGroup total={company.investors.length} className='avtr_grup'>
                                          {company.investors.length > 0 && company.investors.map((investor, i) => {
                                          let name = investor.first_name +' '+ investor.last_name;
                                          return (
                                              <Avatar className='avtr_pp' key={i} alt={name} src={`${imageURL}investor/${investor.image}`} />
                                          )
                                          })}
                                      </AvatarGroup>
                                  </Box>
                                  }

                              </Box>
                          </Link>
                      </Box>
                  </Grid>
                  )
                  })}
                  
                  {companiesData.companyImages.length>0 &&
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
                      <Box className="grndtBox">
                        <Link to={Path.companies}>
                          <Typography className='text150'>
                              +{companiesData.count}
                              <span>More Companies</span>
                          </Typography>
                          <Box className="blrBox">
                              <Box>
                                  {companiesData.companyImages.length>0 &&
                                  <Box className="avtar_perntA2">
                                      {companiesData.companyImages.map((data, ii) => {
                                      return (
                                      <AvatarGroup className='avtr_grup' key={ii}>
                                          <Avatar className='avtr_pp' alt={data.legal_name} src={`${imageURL}company/${data.image}`} />
                                      </AvatarGroup>
                                      )
                                      })}
                                  </Box>
                                  }
                              </Box>
                              <Box className="linkPrnt">
                                  <Box className='Dtls_link'>
                                      <span>Details </span>
                                      <img src='/images/datls_ic.svg' alt='' />
                                  </Box>
                              </Box>
                          </Box>
                        </Link>
                      </Box>
                  </Grid>
                  }
              </Grid>
          </Box>
      </Box>
      }
      
      
      {loadingInvestor && <HomeCardSkeleton />}
      {(!loadingInvestor && investorsData.data) &&
      <Box className="Cmpnis_cont">
          <Box className="hed_brdr">
              <Box className="brdrBox" ></Box>
              <Typography component="h1">Investors</Typography>
          </Box>
          <Box className="gridCont">
              <Grid container spacing={2}>
                  {investorsData.data && investorsData.data.map((investor, j) => {
                  let name = investor.first_name +' '+ investor.last_name;
                  return (
                  <Grid item key={j} xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
                      <Box className="whiteBgBox">
                          <Link to={Path.investorGetById(investor._id, investor.slug)} className="innrCont_flx">
                              <Box className="imgPent">
                                  {investor.image && <img className="image-90" alt={name} src={`${imageURL}investor/${investor.image}`} />}
                                  {!investor.image && <span alt={name} className="image-text large">{Helper.name2Letter(name)}</span>}
                             </Box>
                              <Box className="rightContnt">
                                  <Box className="NAimg">
                                      <img src='/images/n_img.svg' alt='' />
                                      <img src='/images/a_img.svg' alt='' />
                                  </Box>
                                  <Typography className='uberText'>
                                      {name}
                                  </Typography>

                                  
                                  {investor.companies.length > 0 && investor.companies.map((company, k) => {
                                    if(k==0){
                                      return (
                                        <Box className="sinthBgbox" key={k}>
                                          {company.image && <Avatar alt={company.legal_name} src={`${imageURL}company/${company.image}`} />}
                                          {!company.image && <span alt={company.legal_name} className="image-text">{Helper.name2Letter(company.legal_name)}</span>}
                                          <Typography className='morInText'>{investor.companies.length>1 ? (investor.companies.length-1)+'+ more investment' : ''}</Typography>
                                        </Box>
                                      )
                                    }
                                  })}
                                  
                              </Box>
                          </Link>
                      </Box>
                  </Grid>
                  )
                  })}
                  
                  {investorsData.investorImages.length>0 &&
                  <Grid item xs={12} sm={12} md={6} lg={3} xl={3} xxl={3} className='heghtPernt'>
                      <Box className="grndtBox">
                        <Link to={Path.investors}>
                          <Typography className='text150'>
                              +{investorsData.count}
                              <span>More Investors</span>
                          </Typography>
                          <Box className="blrBox">
                              <Box>
                                  <Box className="avtar_perntA2">
                                      {investorsData.investorImages.map((data, l) => {
                                      const invName = data.first_name +' '+ data.last_name;
                                      return (
                                      <AvatarGroup className='avtr_grup' key={l}>
                                          <Avatar className='avtr_pp' alt={invName} src={`${imageURL}investor/${data.image}`} />
                                      </AvatarGroup>
                                      )
                                      })}
                                  </Box>
                              </Box>
                              <Box className="linkPrnt">
                                  <Box className='Dtls_link'>
                                      <span>Details </span>
                                      <img src='/images/datls_ic.svg' alt='' />
                                  </Box>
                              </Box>
                          </Box>
                        </Link>
                      </Box>
                  </Grid>
                }

              </Grid>
          </Box>
      </Box>
      }
      
      {/*<LatestFromFeedSc />*/}
    </>
  );
}

const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Home);