import React, { useState, useEffect } from 'react';
import { Box, Autocomplete, TextField, CircularProgress, Grid, MenuItem, Button, Typography, Pagination, Dialog, DialogContent, FormControl } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Select from 'react-select'

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Service from 'services';
import Helmet from 'react-helmet';
import * as Path from 'routes/paths';
import { useForm } from "react-hook-form";
import * as Helper from '_helpers/CommonFunctions';

const CompanyInvestment = (props) => {

  const { register, setValue, getValues, reset, handleSubmit, formState: { errors } } = useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [investorValue, setInvestorValue] = useState();
  const [investorValueOption, setInvestorValueOption] = useState({});
  
    useEffect(() => {
      if(props.investorEdit?._id){
        reset({
          company_id: props.investorEdit.company_id,
          investor_id: props.investorEdit.investor_id,
          funding_id: props.investorEdit.funding_id,
          shares: props.investorEdit.shares,
          face_value: props.investorEdit.face_value,
          premium: props.investorEdit.premium,
          amount_received: props.investorEdit.amount_received,
        })

        setInvestorValue(props.investorEdit.investor_id)

        const investorIndex = checkInvestor(props.investorEdit.investor_id);
        
        
        setInvestorValueOption(props.investorOptions[investorIndex])
      }
    }, [props.investorEdit]);


    const checkInvestor = (investor) => {
        const foundIndex = props.investorOptions.findIndex(row => {
            return row.value == investor._id;
        });

        return foundIndex;
    }


    const closeModal = () => {
        props.handleCloseInvestorsModal();
        reset({
            company_id: '',
            investor_id: '',
            funding_id: '',
            shares: '',
            face_value: '',
            premium: '',
            amount_received: '',
        })
        setInvestorValue()
        setInvestorValueOption({})
    }

  const onSubmit = async (values) => {
	    setLoading(true);
	    try {          
	        values['funding_id'] = props.fundingRound._id;
            values['investor_id'] = investorValue;
	        values['company_id'] = props.companyData._id;

            if(props.investorEdit?._id){
    	        let apiPath = Path.investmentGetById(props.investorEdit._id);
    	        await props.dispatch(Service.patchRequest(apiPath, values)).then((res) => {
    	            setLoading(false);
    	            props.alert('Funding Round Updated');
    	            props.getCompany();
                    closeModal()
    	        });
            }
            else {
                await props.dispatch(Service.postRequest(Path.investmentCreate, values)).then((res) => {
                    setLoading(false);
                    props.alert('Funding Round Created');
                    props.getCompany();
                    closeModal()
                });
            }

	    } catch (err) {
	        console.log(err);
	        setLoading(false);
	    }
	};


    
    let textAddEdit = props.investorEdit?._id ? 'Edit' : 'Add';

  return (
    <>
      <Dialog
        open={props.openInvestorsModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='dialog_main_pernt'
      >
        <Box className='headin_hh'>
          <Typography component="h1">{textAddEdit} Funding Rounds</Typography>
        </Box>
        <DialogContent className='main_content'>
          <form onSubmit={handleSubmit(onSubmit)}>
	    	<Box className="cnct_instrs" >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            required
                            value={investorValueOption?.value ? investorValueOption : null}
                            options={props.investorOptions}
                            getOptionLabel={(option) => {
                              return option.label
                            }}
                            onChange={(e, data) => {
                                //console.log(data)
                                if(data?.value){
                                    setInvestorValue(data.value);
                                    setInvestorValueOption(data);
                                    //setValue('investor_id', data.value, { shouldDirty: true })
                                }
                            }}
                            renderInput={(params) => <TextField 
                              {...params}
                              placeholder="Name of Shares Holder" 
                              className='input_input' 
                              required/>
                            }
                          />
		                  
                          {/*{errors.investor_id?.type === 'required' && <span className="alert">Shares Holder is required</span>}
                            */}
                    </Grid>
                    <Grid item xs={12} md={12} className='paddng_none'>
                        <Box>
                            <FormControl className='form_control mb-10'>
                                <input type='number' placeholder='No Of Shares' className='input_input' 
                                {...register("shares", { required: true })}
                                onChange={(e) => {
                                    let sValue = e.target.value ?? 0;

                                    let faceValue = sValue * Helper.checkIntValue(props.fundingRound.face_value_per_share);
                                    let premium = sValue * Helper.checkIntValue(props.fundingRound.premium_per_share);

                                    setValue('face_value', faceValue);
                                    setValue('premium', premium);
                                    setValue('amount_received', faceValue + premium);
                                }}
                                />
                            </FormControl>
                            {errors.shares?.type === 'required' && <span className="alert">No of Shares is required</span>}
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={6} className='paddng_none'>
                        <Box>
                            <FormControl className='form_control mb-10'>
                                <input type='text' placeholder='Face Value' className='input_input' 
                                {...register("face_value", { required: true })} disabled/>
                            </FormControl>
                            {errors.face_value?.type === 'required' && <span className="alert">Face Value is required</span>}
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} md={6} className='paddng_none'>
                        <Box>
                            <FormControl className='form_control mb-10'>
                                <input type='text' placeholder='Premium' className='input_input' 
                                	{...register("premium", { required: true })} disabled/>
                            </FormControl>
                            {errors.premium?.type === 'required' && <span className="alert">Premium is required</span>}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12} className='paddng_none'>
                        <Box>
                            <FormControl className='form_control margn_prnt '>
                                <input type='text' placeholder='Total Amount Received' className='input_input' 
                                	{...register("amount_received", { required: true })} disabled/>
                            </FormControl>
                            {errors.amount_received?.type === 'required' && <span className="alert">Total Amount Received is required</span>}
                        </Box>
                    </Grid>
                </Grid>
                <Box className="last_content">
                    <Button type="submit" className='def_btn' disabled={loading ? true : false}>
                    {loading ?
	                    <CircularProgress color="inherit" disableShrink /> 
	                    :
	                    'CONNECT INVESTORS'
	                }
                    </Button>
                </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(CompanyInvestment);