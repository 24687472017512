import { Chart } from "react-google-charts";

const GChart = (props) => {

	return (
    <>
    	<Chart
			chartType={props.chartType}
			width={props.chartWidth}
			height={props.chartHeight}
			data={props.chartData}
			options={props.chartOptions}
			/*formatters={[{
				type: 'NumberFormat',
                column: 1, 
                options: {
                	prefix: 'Rs ',
                }
            }]}*/
			/>
    </>
    )
}

export default GChart;