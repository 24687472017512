import { combineReducers } from 'redux';
import Auth from './Auth';
import persistStore from './persistStore';
import { loadingBarReducer } from 'react-redux-loading-bar';

const RootReducer = combineReducers({
		Auth,
		persistStore,
		loadingBar: loadingBarReducer,
	});
export default RootReducer;
