import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material'
import Select from 'react-select';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Service from 'services';
import { imageURL } from 'env';
import * as Path from 'routes/paths';
import { Redirect } from "react-router-dom";

const Dashboard = (props) => {

  const [goTo, setGoTo] = useState();
  const [optionsData, setOptionsData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [investorsData, setInvestorsData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      getCompanies();
      getInvestors();
  }, [search]);

  useEffect(() => {
      setOptionsData([...companiesData, ...investorsData]);
      setLoading(false);
  }, [companiesData, investorsData]);


  const getCompanies = async () => {
    try{
        setLoading(true);
        setCompaniesData([]);
        const apiPath = Path.companyIndex + `?search=${search}&limit=${limit}&populate=no&page=${page}`;
        await props.dispatch(Service.getRequest(apiPath)).then((res) => {
          //...
          let compOptions = new Array();
          for(const company of res){
            let nArray = new Object();
            nArray['_id'] = company._id;
            nArray['value'] = company.legal_name;
            nArray['type'] = 'company';
            nArray['label'] = <><img src={`${imageURL}company/${company.image}`} alt={company.legal_name} />{company.legal_name}</>;
            
            compOptions.push(nArray);
          }

          setCompaniesData(compOptions)
        });
    } catch(err) {
        console.log(err);
        if(err && err.data && err.data.msg){
          //props.swal("Oops!", err.data.msg, "error");
        }
    }
  };

  const getInvestors = async () => {
      try{
          setLoading(true);
          setInvestorsData([]);
          const apiPath = Path.investorIndex + `?search=${search}&limit=${limit}&populate=no&page=${page}`;
          await props.dispatch(Service.getRequest(apiPath)).then((res) => {
            let invOptions = new Array();
            for(const investor of res){
              let name = investor.first_name +' '+ investor.last_name;

              let nArray = new Object();
              nArray['_id'] = investor._id;
              nArray['value'] = name;
              nArray['type'] = 'investor';
              nArray['label'] = <><img src={`${imageURL}investor/${investor.image}`} alt={name} />{name}</>;
              
              invOptions.push(nArray);
            }

            setInvestorsData(invOptions)
          });
      } catch(err) {
          console.log(err);
          if(err && err.data && err.data.msg){
              //swal("Oops!", err.data.msg, "error");
          }
      }
  };

  const handleChange = (event) => {
    let redirectUrl = '';
    if(event.type=='company'){
      redirectUrl = Path.companyGetByIdAdmin(event._id)
    }
    if(event.type=='investor'){
      redirectUrl = Path.investorGetByIdAdmin(event._id)
    }

    setGoTo(redirectUrl);
  };

  return (
    <>
      {goTo &&
        <Redirect to={goTo} />
      }

      <Helmet title="Dashboard" />
      <Box className="homePrnt">
        <Box className="homeCircle">
          <Typography component="h3">Search Companies or Investors</Typography>
          <Box className="customSearch">            
            <Select classNamePrefix="cmpnSelect" placeholder="Search Companies or Investors" 
            options={optionsData}
            value={search}
            onChange={handleChange}
            onInputChange = {(text)  =>
              (
                setSearch(text)
              )}

            />
          </Box>
        </Box>
      </Box>
    </>
  );
}


const mapStateToPros = (state) => {
    return{
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(Dashboard);