import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from "react-router-dom";

import { Box, Container } from '@mui/material'

import Header from './Includes/Header';
import Footer from './Includes/Footer';
import 'assets/css/App.scss';


const App = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        document.body.classList.add('front-pages')
        document.body.classList.remove('admin-pages')
        scrollToTop();
    }, [])

    function alert(message) {
        toast(message);
    }

    let containerClass = 'containerCstm';
    let containerWidth = '1260px';
    if(splitLocation[1]=='companies' || splitLocation[1]=='investors'){
        containerClass = 'containerCstmA2';
        containerWidth = '1280px';
    }

    return (
        <div className="App">

            <ToastContainer />
            
            <Box className="bodyBgPerntnt">

            	<Header />
                <Box className="">
                	<Container maxWidth={containerWidth} className={containerClass}>
                        {React.cloneElement(props.children, {alert, scrollToTop})}
                    </Container>
                </Box>
                <Footer />

            </Box>
        </div>
    );
}


export default App;

