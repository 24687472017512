import React, { useState, useEffect } from 'react'
import { Box, Grid, Button, Dialog, DialogContent, FormControl, Typography, Autocomplete, TextField
} from '@mui/material'

import { connect } from 'react-redux';
import Service from 'services';
import * as Path from 'routes/paths';
import { useForm } from "react-hook-form";

const TitleModal = (props) => {

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [titleOptions, setTitleOptions] = useState([]);
	const [financialTitleSelected, setFinancialTitleSelected] = useState({});


	const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm();

	useEffect(() => {
    if(props.financialTitleData.length > 0){
    	financialTitleArray(props.financialTitleData);
    }
  }, [props.financialTitleData]);


	useEffect(() => {
    if(props.financialTitleEditData?._id){

    	reset({
        title: props.financialTitleEditData.title,
        financialTitleDataId: props.financialTitleEditData.title_id._id
      })
      
      const getIndex = checkTitleOptions(props.financialTitleEditData.title_id);

      setFinancialTitleSelected(titleOptions[getIndex]);
    }
  }, [props.financialTitleEditData]);

	const closeModal = () => {
		  setFinancialTitleSelected({});
      reset({
          company_financial_id: '',
          financialTitleDataId: '',
          title: ''
      })
      props.closeModal();
		  props.getFinancials();
		  props.getFinancialsTitle();

  }


	const onSubmit = async (values) => {
    	setLoading(true);

	    try{
	    	
	    	let createValues = {};
	    	createValues['company_financial_id'] = props.financialId;

	    	if(values['title'] && values['title']!=''){
	    		let createValues1 = {};
	    		createValues1['title'] = values['title'];

		    	await props.dispatch(Service.postRequest(Path.financialTitleCreate, createValues1)).then((res) => {
		            setLoading(false);
		            createValues['title_id'] = res._id;
		        });
		    }
		    else {
		    	createValues['title_id'] = values.financialTitleDataId;
		    }

	    	if(props.financialTitleEditData?._id){
	        let apiPath = Path.financialTitleEditApi(props.financialTitleEditData._id);
	        await props.dispatch(Service.patchRequest(apiPath, createValues)).then((res) => {
	            setLoading(false);
	            props.alert('Financial Title Updated');
	            closeModal()
	        });
	      }
	      else {
		    	await props.dispatch(Service.postRequest(Path.financialCreateTitle, createValues)).then((res) => {
		            setLoading(false);
		            props.alert('Financial Title Created');
		            closeModal();
		        });
		    }
		}
		catch(err) {
			console.log(err);
			setLoading(false);
		}
  };


  const financialTitleArray = (titles) => {
  	let titleArray = [];
  	for(const row of titles){
  		let tArray = [];
  			tArray['value'] = row._id;
  			tArray['label'] = row.title;
  			titleArray.push(tArray);
  	}

  	setTitleOptions(titleArray);
  }


  const checkTitleOptions = (data) => {
      const foundIndex = titleOptions.findIndex(row => {
          return row.value == data._id;
      });

      return foundIndex;
  }


	return (
		<Dialog
	        open={props.openModal}
	        onClose={closeModal}
	        aria-labelledby="alert-dialog-title"
	        aria-describedby="alert-dialog-description"
	        className='dialog_main_pernt'
	      >
	        <Box className='headin_hh'>
	          <Typography component="h1">{props.textAddEdit} Title</Typography>
	        </Box>
	        <DialogContent className='main_content'>
	          <form onSubmit={handleSubmit(onSubmit)}>
	          	<Grid container spacing={2}>
	            	<Grid item xs={12} md={12}>
	                    
                    	{titleOptions.length>0 &&
                    	<>
                    		<label className='hed_labl mb-10'>Select Title</label>
                    		<Autocomplete
                          value={financialTitleSelected?.value ? financialTitleSelected : null}
                          options={titleOptions}
                          getOptionLabel={(option) => {
                            return option.label
                          }}
                          onChange={(e, data) => {
                              if(data?.value){
                                  setFinancialTitleSelected(data);
                                  setValue('financialTitleDataId', data.value, { shouldDirty: true })
                                  setValue('title', '', { shouldDirty: true })
                              		
                              }
                          }}
                          renderInput={(params) => <TextField 
                            {...params}
                            placeholder="Select Title" 
                            className='input_input' 
                            required={titleOptions.length>0 ? false : true}/>
                          }
                        />
                      </>
                    	}

                    	<FormControl className='form_control mt-10'>
                          <label className='hed_labl mb-10'>{titleOptions.length>0 ? 'Or ' : ''}Create New Title</label>
                          <input type='text' placeholder='Title Name' className='input_input'
                            {...register("title", {
                            	required: titleOptions.length>0 ? false : true,
                            	disabled: financialTitleSelected?.value ? true : false,
                            })}/>
                          {errors.title?.type === 'required' && <span className="alert">Title Name is required</span>}
                      </FormControl>

	                </Grid>
	          	</Grid>
	            
	            <Box className="last_content">
	            	<Button type="submit" className='def_btn mt-4'>{props.textAddEdit} Title</Button>
	            </Box>
	            
	          </form>
	        </DialogContent>
	    </Dialog>
	)

}


const mapStateToPros = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth,
    }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(TitleModal);