import { Box, Button, Container } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import * as Path from 'routes/paths';

export default function Header() {
  return (
    <>
      <Box className="header">
        <Container maxWidth="1260px" className='containerCstm'>
          <Box className="hedrInnr">
            <Box className="logo_prnt">
              <Link to={Path.home}>
                <img src='/logo.svg' alt='' />
              </Link>
            </Box>
            <Box>
              <Link to={Path.login}>
                <Button className='loginBtn'>Login</Button>
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}
