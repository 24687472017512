import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
      <Box className="footer">
        <Container maxWidth="1260px" className='containerCstm'>
          <Box className="fotrBg">
            <Box className="wihtsmal">
              <Box className="allItmFlx">
                
                  <Box className="">
                    <Box className="TandImgFlx">
                      <img src='/images/loction_ic.svg' alt='' height={34} width={34} />
                      <Typography>Brigade IRV, Whitefield, Bangalore 560066</Typography>
                    </Box>
                    <Box className="TandImgFlx">
                      <img src='/images/gmail_ic.svg' alt='' height={34} width={34} />
                      <Typography>hello@balancesheet.cc</Typography>
                    </Box>
                  </Box>
                  <Box className="cntrGrid">
                    <Link to="/">
                      <img src='/images/footr_logo.svg' alt='' className='footr_logo' />
                    </Link>
                    <Box className="sosl_medya">
                      {/*<a href='/' className='ssl_medLink'>
                        <img src='/images/inst_ic.svg' alt='' />
                      </a>*/}
                      <a target="_blank" href='https://twitter.com/tbs_daily' className='ssl_medLink'>
                        <img src='/images/twtr_ic.svg' alt='' />
                      </a>
                      <a target="_blank" href='https://www.linkedin.com/company/thebalancesheet' className='ssl_medLink'>
                        <img src='/images/likdy_ic.svg' alt='' />
                      </a>
                    </Box>
                  </Box>
             
                  <Box className="lastGrid">
                    <Box className="TandImgFlx">
                      <img src='/images/cc_ic.svg' alt='' height={34} width={34} />
                      <Typography>@ 2023 The Balance Sheet</Typography>
                    </Box>
                  </Box>
                
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  )
}
