//import { lazy } from 'react';
import * as Path from './paths';

//--------------------Admin----------------------//
import Dashboard from "components/Admin/Dashboard";

//...Company
import CompanyIndex from "components/Pages/Company/CompanyIndex";
import CompanyView from "components/Pages/Company/CompanyView";
import CompanyAdmin from "components/Admin/Company/CompanyIndex";
import CompanyAdminView from "components/Admin/Company/CompanyView";

//...Investor
import InvestorIndex from "components/Pages/Investor/InvestorIndex";
import InvestorView from "components/Pages/Investor/InvestorView";
import InvestorAdmin from "components/Admin/Investor/InvestorIndex";
import InvestorAdminView from "components/Admin/Investor/InvestorView";

//--------------------Pages----------------------//
import HomePage from "components/Pages/Home";


//products
import Products from "components/Pages/Products/ProductsIndex";



//-----------------------auth--------------------//
import Login from "components/Auth/Login";

//---------------Page not found--------------//
import NotFound from 'components/NotFound';

const routes = [
	//home
	{ path: Path.home, exact: true, auth: false, component: HomePage },

	//company
	{ path: Path.companies, exact: true, auth: false, component: CompanyIndex },
	{ path: Path.companyView, exact: true, auth: false, component: CompanyView },

	//investor
	{ path: Path.investors, exact: true, auth: false, component: InvestorIndex },
	{ path: Path.investorView, exact: true, auth: false, component: InvestorView },

	//products
	{ path: Path.products, exact: true, auth: false, component: Products },

	//admin
	//dashboard
	{ path: Path.dashboard, exact: true, auth: true, component: Dashboard },

	//company
	{ path: Path.companyAdmin, exact: true, auth: true, component: CompanyAdmin },
	{ path: Path.companyAdminView, exact: true, auth: true, component: CompanyAdminView },

	//investor
	{ path: Path.investorAdmin, exact: true, auth: true, component: InvestorAdmin },
	{ path: Path.investorAdminView, exact: true, auth: true, component: InvestorAdminView },

	//auth
	{ path: Path.login, exact: true, auth: false, fallback: true, component: Login },
	

	//page not found
	{ path: '*', component: NotFound },

	
];

export default routes;
