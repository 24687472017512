import React from "react";
import Helmet from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';


const Auth = (props) => {

	function alert(message) {
      toast(message);
  }

  return (
	  	<>
			<main>
			<ToastContainer />
       			<div class="container">
					<Helmet titleTemplate={`%s | The Balance Sheet`} />
					
					{React.cloneElement(props.children, {alert})}

				</div>
			</main>

		</>
  );
}

export default Auth;

