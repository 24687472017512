import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import PublicRoute from './Public';
import PrivateRoute from './Private';
import AuthRoute from './Auth';
import allRoutes from './routes';
import * as Path from './paths';

//...............................................
function routesData({authentication, user}) {
    return (
        <>
            <BrowserRouter>
                <Switch>
                    {/*<Route
                        exact
                        path="/"
                        render={() => {
                            return (
                                authentication===true ?
                                    <Redirect to={Path.dashboard} /> :
                                    <Redirect to={Path.login} />
                            )
                        }}
                    />*/}

                    {allRoutes.map((routePath) => {
                            if (routePath.fallback) {
                                return <AuthRoute key={routePath.path} {...routePath} />;
                            }
                            if (routePath.auth) {
                                return <PrivateRoute key={routePath.path} {...routePath} />;
                            }
                            //if (!routePath.auth)  {
                                return <PublicRoute key={routePath.path} {...routePath} />;
                            //}
                        })}

                    
                </Switch>
            </BrowserRouter>


        </>
    );
}


const mapStateToPros = (state) => {
    return {
        authentication: state.Auth.isAuthenticated,
        user: state.Auth.user
    }
};
  
function mapDispatchToProps(dispatch) {
    return { dispatch };
}

export default connect(
    mapStateToPros,
    mapDispatchToProps
)(routesData);