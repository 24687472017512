import Http from 'Http';
import * as action from 'store/actions';
import { toast } from 'react-toastify';

//login
export function login(api, credentials) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(api, credentials)
      .then((res) => {
        dispatch(action.authLogin(res.data));
        return resolve(res.data);
      })
      .catch((err) => {
        const { status, message } = err?.response?.data?.error;
        const res = {
          status,
          message,
        };
        return reject(res);
      });
  });
}



//GET Request
export function getRequest(api) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.get(api)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, message } = err?.response?.data?.error;
        const res = {
          status,
          message,
        };
        toast(message);
        return reject(res);
      });
  });
}

//POST Request
export function postRequest(api, values) {
  return (dispatch) => new Promise((resolve, reject) => {
      Http.post(api, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, message } = err?.response?.data?.error;
        const res = {
          status,
          message,
        };
        toast(message);
        return reject(res);
      });
  });
}

//PATCH Request
export function patchRequest(api, values) {
  return (dispatch) => new Promise((resolve, reject) => {
      Http.patch(api, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, message } = err?.response?.data?.error;
        const res = {
          status,
          message,
        };
        toast(message);
        return reject(res);
      });
  });
}

//DELETE Request
export function deleteRequest(api, values) {
  return (dispatch) => new Promise((resolve, reject) => {
      Http.delete(api, values)
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, message } = err?.response?.data?.error;
        const res = {
          status,
          message,
        };
        toast(message);
        return reject(res);
      });
  });
}


//File Upload Request
export function uploadProfile(api, values) {
  return (dispatch) => new Promise((resolve, reject) => {
    Http.post(api, values, { headers: { "Content-Type": "multipart/form-data" } })
      .then((res) => resolve(res.data))
      .catch((err) => {
        const { status, message } = err?.response?.data?.error;
        const res = {
          status,
          message,
        };
        toast(message);
        return reject(res);
      });
  });
}